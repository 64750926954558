<template>
  <!--  -->
  <DraggableMenu
    id="pallet-list"
    :isOpen="palletStore.isMenuOpen"
    :isMinimized="palletStore.minimized"
    :title="'Favorit List'"
    @toggleMinimize="palletStore.toggleMinimize()"
  >
    <div :class="{ 'body-wrapper': true, minimized: palletStore.minimized }">
      <div class="input-wrapper">
        <input
          type="text"
          class="search-input"
          v-model="searchOnPallets"
          placeholder="Search"
        />
        <button
          class="increase"
          data-bs-toggle="modal"
          data-bs-target="#addPalleteModal"
          @click="palletStore.isOpen(false)"
        >
          +
        </button>
      </div>
      <VueDraggableNext
        :list="palletStore.itemsList"
        :disabled="searchOnPallets"
        class="list-group"
        ghost-class="ghost"
        :move="checkMove"
        @start="dragging = true"
        @end="onEnd"
      >
        <div
          :class="{
            'favorit-item': true,
            active: palletStore?.activeElement?.id === item.id,
          }"
          v-for="item in filteredItems"
          @click="handleClick(item)"
          :key="item.name"
        >
          {{ item.name }}

          <button class="delete" @click="deletePalletHandler(item)">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
        </div>
      </VueDraggableNext>
    </div>
  </DraggableMenu>

  <AddPalleteModal />
</template>

<script setup>
import { usePallet } from "@/pinia/pallet";
import AddPalleteModal from "@/components/database/pallete/add-pallete-modal.vue";
import { computed, onMounted, ref } from "vue";
import {
  getPallets,
  deletePallet,
  changePalletOrder,
} from "@/services/database";
import { VueDraggableNext } from "vue-draggable-next";
import { toast } from "vue3-toastify";
import DraggableMenu from "@/components/shared/dragable-menu.vue";

const dragging = ref(false);
const searchOnPallets = ref("");
const palletStore = usePallet();
const payload = ref({});
const checkMove = (e) => {
  const palletId = e.draggedContext.element.id;
  const newOrder = +e.draggedContext.futureIndex + 1;
  payload.value = { palletId, newOrder };
};

const onEnd = (e) => {
  changePalletOrder(payload.value)
    .then((res) => {
      dragging.value = true;
      console.log(res);
    })
    .catch((err) => {
      console.error(err);
    });
};

onMounted(() => {
  getPallets().then((res) => {
    palletStore.setItemsList(res);
  });
});

const handleClick = (item) => {
  if (palletStore.activeElement?.id === item.id) {
    palletStore.setActiveElement(null);
  } else {
    palletStore.setActiveElement(item);
  }
};

const deletePalletHandler = (item) => {
  deletePallet(item.id).then(() => {
    getPallets().then((res) => {
      palletStore.setItemsList(res);
      toast.success("Pallet deleted successfully");
    });
  });
};

const filteredItems = computed(() => {
  return searchOnPallets.value
    ? palletStore.itemsList.filter((item) =>
        item.name.toLowerCase().includes(searchOnPallets.value.toLowerCase())
      )
    : palletStore.itemsList;
});
</script>

<style scoped lang="scss">
.body-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  &.minimized {
  }
}
.favorit-item {
  padding: 12px;
  border-bottom: 1px solid #6b7280;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #f3f4f6;
  }

  &:last-child {
    border-bottom: none;
  }

  &.active {
    background-color: #f3f4f6;
  }
  .delete {
    background-color: transparent;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 14px;
    padding: 0;

    & i {
      margin: 0;
    }
  }
}
.increase {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: transparent;
  color: black;
}
.increase:hover {
  background-color: #ccc;
  color: white;
  border-color: #007bff;
}

.input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & input {
    width: 90%;
    padding: 2px 8px;
  }
}
</style>
