import initTransform from "../Viewing.Extension.Transform/Viewing.Extension.Transform";

export function changeModelsPosition(viewer, position) {
  const models = viewer?.impl.modelQueue().getModels();
  models.forEach((model) => {
    const transform = new THREE.Matrix4().makeTranslation(
      position.x,
      position.y,
      position.z
    );
    viewer?.impl.setPlacementTransform(model, transform);
    viewer?.impl.invalidate(true, true, true);
  });
}

export const getModelScale = (viewer, index) => {
  const models = viewer?.getAllModels();
  if (models.length > 0) {
    const model = models[index - 1];
    const matrix = model.getPlacementTransform();
    const scale = new THREE.Vector3();
    matrix.decompose(new THREE.Vector3(), new THREE.Quaternion(), scale);
    return { x: scale.x, y: scale.y, z: scale.z };
  } else {
    console.log("No models loaded.");
  }
};

export const getModelRotation = (viewer, modelId, dbId) => {
  const models = viewer?.impl.modelQueue().getModels();
  const model = models[modelId - 1];
  const instanceTree = model.getData().instanceTree;
  const fragList = model.getFragmentList();

  let rotation = { x: 0, y: 0, z: 0 };

  instanceTree.enumNodeFragments(dbId, (fragId) => {
    const matrix = new THREE.Matrix4();
    fragList.getWorldMatrix(fragId, matrix);

    const pos = new THREE.Vector3();
    const quat = new THREE.Quaternion();
    const scale = new THREE.Vector3();
    matrix.decompose(pos, quat, scale);

    const euler = new THREE.Euler();
    euler.setFromQuaternion(quat, "XYZ");

    // Convert radians to degrees
    rotation = {
      x: THREE.Math.radToDeg(euler.x),
      y: THREE.Math.radToDeg(euler.y),
      z: THREE.Math.radToDeg(euler.z),
    };
  });

  return rotation;
};

function getModifiedWorldBoundingBox(fragIds, fragList) {
  var fragbBox = new THREE.Box3();
  var nodebBox = new THREE.Box3();

  fragIds.forEach(function (fragId) {
    fragList.getWorldBounds(fragId, fragbBox);
    nodebBox.union(fragbBox);
  });

  return nodebBox;
}

function getWorldPosition(viewer, modelId, dbId) {
  const model = viewer?.getAllModels()[modelId - 1];
  const fragIdsArray = [];
  const fragCount = model.getFragmentList().fragments.fragId2dbId.length;

  for (var fragId = 0; fragId < fragCount; ++fragId) {
    fragIdsArray.push(fragId);
  }

  var bBox = getModifiedWorldBoundingBox(fragIdsArray, model.getFragmentList());

  const xCor = bBox.center().x + viewer?.model.getData().globalOffset.x;

  const yCor = bBox.center().y + viewer?.model.getData().globalOffset.y;

  const zCor = bBox.center().z + viewer?.model.getData().globalOffset.z;

  console.log("FINAL CORDS CORRECT", xCor, yCor, zCor);
  const finalDragObject = {
    x: xCor,
    y: yCor,
    z: zCor,
  };
  return finalDragObject;
}

export const getModelPositions = (viewer, modelId, dbId) => {
  return {
    scale: getModelScale(viewer, modelId),
    rotation: getModelRotation(viewer, modelId, dbId),
    position: getWorldPosition(viewer, modelId),
  };
};

export function scaleSpecificModel(viewer, scaleX, scaleY, scaleZ, index) {
  const models = viewer?.impl.modelQueue().getModels();
  const model = models[index - 1];

  const rotationMatrix = new THREE.Matrix4().makeRotationFromEuler(
    new THREE.Euler(0, 0, THREE.Math.degToRad(0))
  );
  const scalingMatrix = new THREE.Matrix4().makeScale(scaleX, scaleY, scaleZ);

  // Combine the rotation and scaling matrices
  const transformMatrix = new THREE.Matrix4().multiplyMatrices(
    rotationMatrix,
    scalingMatrix
  );

  if (model) {
    viewer?.impl.setPlacementTransform(model, transformMatrix);
    // Refresh the viewer
    viewer?.impl.invalidate(true, true, true);
  } else {
    console.error(`Model with URN: ${urn} not found`);
  }
}

function moveModel(viewer, modelId, pParam) {
  const models = viewer?.impl.modelQueue().getModels();
  const model = models.find((m) => m.id == modelId);

  if (model) {
    const matrix = model.getPlacementTransform();
    const position = new THREE.Vector3();
    const quaternion = new THREE.Quaternion();
    const scale = new THREE.Vector3();
    matrix.decompose(position, quaternion, scale);
    position = pParam;
    const newMatrix = new THREE.Matrix4().compose(position, quaternion, scale);
    model.setPlacementTransform(newMatrix);
    viewer?.impl.invalidate(true, true, true);
  } else {
    console.error(`Model with ID ${modelId} not found.`);
  }
}

export const deactivateAll = (viewer) => {
  viewer?.toolController?.activateTool("orbit");
  const extentions = [
    "Autodesk.Measure",
    "Viewing.Extension.Transform",
    "Viewing.Extension.MultiTransform",
  ];
  const allLoadedExtentions = viewer?.getLoadedExtensions();
  extentions.map((extention) => {
    if (allLoadedExtentions[extention])
      allLoadedExtentions[extention].deactivate();
  });
};

export function addViewable(viewer, urn, xform, offset, scale) {
  return new Promise(function (resolve, reject) {
    function onDocumentLoadSuccess(doc) {
      const viewable = doc.getRoot().getDefaultGeometry();
      const options = {
        keepCurrentModels: true,
      };

      // set the rotation to {x: 0, y: 0, z: -63.35011009733824}
      const rotationMatrix = new THREE.Matrix4().makeRotationFromEuler(
        new THREE.Euler(0, 0, THREE.Math.degToRad(0))
      );

      // Create the scaling matrix
      const scalingMatrix = new THREE.Matrix4().makeScale(scale, scale, scale);

      // Combine the rotation and scaling matrices
      const transformMatrix = new THREE.Matrix4().multiplyMatrices(
        rotationMatrix,
        scalingMatrix
      );

      if (xform) {
        xform.multiply(transformMatrix);
      } else {
        options.placementTransform = transformMatrix;
      }

      if (xform) {
        options.placementTransform = xform;
      }
      if (offset) {
        options.globalOffset = offset;
      }
      viewer
        .loadDocumentNode(doc, viewable, options)
        .then(resolve)
        .catch(reject);
    }
    function onDocumentLoadFailure(code) {
      reject(`Could not load document (${code}).`);
    }
    Autodesk.Viewing.Document.load(
      "urn:" + urn,
      onDocumentLoadSuccess,
      onDocumentLoadFailure
    );
  });
}

export function addViewableWithTransformMatrix(
  viewer,
  urn,
  transformMatrix,
  offset
) {
  return new Promise(function (resolve, reject) {
    function onDocumentLoadSuccess(doc) {
      const viewable = doc.getRoot().getDefaultGeometry();
      const options = {
        keepCurrentModels: true,
        placementTransform: "mat4",
      };

      options.placementTransform = transformMatrix;

      if (offset) {
        options.globalOffset = offset;
      }
      viewer
        .loadDocumentNode(doc, viewable, options)
        .then(resolve)
        .catch(reject);
    }
    function onDocumentLoadFailure(code) {
      reject(`Could not load document (${code}).`);
    }
    Autodesk.Viewing.Document.load(
      "urn:" + urn,
      onDocumentLoadSuccess,
      onDocumentLoadFailure
    );
  });
}

export function hideBar(viewer) {
  let toolbar = viewer?.toolbar;
  if (toolbar && toolbar.container) toolbar.container.style.display = "none";
}

export const onPanOrOrbit = (viewer, panOrOrbit) => {
  if (panOrOrbit) {
    viewer?.toolController?.activateTool("pan");
    return false;
  } else {
    viewer?.toolController?.activateTool("orbit");
    return true;
  }
};

export function selectAll(event, viewer, indices) {
  setTimeout(() => {
    if (
      event.selections.length == 1 &&
      event.selections[0].dbIdArray.length == 1
    ) {
      let allSelections = [];
      const models = viewer
        .getAllModels()
        .filter((_, index) => indices.includes(index));
      models.forEach((m) => {
        const instanceTree = m.getData().instanceTree;
        const rootId = instanceTree.getRootId();
        const dbIds = [rootId];
        allSelections.push({ ids: dbIds, model: m });
      });
      viewer?.impl.selector.setAggregateSelection(allSelections);
    }
  }, 10);
}

export const onMove = (viewer, moveMode) => {
  initTransform();
  deactivateAll(viewer);
  viewer.loadExtension("Viewing.Extension.Transform");
  viewer.toolController?.deactivateTool("Viewing.Rotate.Tool");
  if (moveMode == "model") {
    viewer.toolController?.deactivateTool("Viewing.Transform.PartsTool");
    viewer.toolController?.activateTool("Viewing.Transform.Tool");
  } else {
    viewer.toolController?.deactivateTool("Viewing.Transform.Tool");
    viewer.toolController?.activateTool("Viewing.Transform.PartsTool");
  }
};

export function onRotation(viewer) {
  initTransform();
  deactivateAll(viewer);
  viewer.loadExtension("Viewing.Extension.Transform");
  viewer.toolController?.deactivateTool("Viewing.Transform.Tool");
  viewer.toolController?.deactivateTool("Viewing.Transform.PartsTool");
  viewer.toolController?.activateTool("Viewing.Rotate.Tool");
}

export const onAngle = (viewer) => {
  deactivateAll(viewer);
  var angleExtension = viewer?.getExtension("Autodesk.Measure");
  angleExtension.activate("angle");
  angleExtension.setFreeMeasureMode(true);
};

export const onLine = (viewer) => {
  deactivateAll(viewer);
  var measureExtension = viewer?.getExtension("Autodesk.Measure");
  measureExtension.activate();
  measureExtension.setFreeMeasureMode(true);
};

export const onFocus = (viewer, modelId) => {
  const model = viewer?.getAllModels()[modelId - 1];
  const frags = model.getFragmentList().fragments.fragId2dbId;
  viewer?.fitToView(frags, model);
};

export function onScale(viewer, value, operation, index) {
  console.log("onScale index", index);
  const { x, y, z } = getModelScale(viewer, index);
  scaleSpecificModel(
    viewer,
    operation == "+" ? x + value : x - value < 0.01 ? 0.01 : x - value,
    operation == "+" ? y + value : y - value < 0.01 ? 0.01 : y - value,
    operation == "+" ? z + value : z - value < 0.01 ? 0.01 : z - value,
    index
  );
}
