import { defineStore } from "pinia";
import { ref } from "vue";
import {
  getAllCompanies,
  getAllRoles,
  getCompanyById,
} from "@/services/company";

export const useCompanyStore = defineStore("company-store", () => {
  const companies = ref([]);
  const userRoles = ref([]);
  const isLoading = ref(true);
  const fetchCompanies = () => {
    getAllCompanies()
      .then((res) => {
        companies.value = res;
        isLoading.value = false;
      })
      .catch((err) => {
        console.log(err);
        isLoading.value = false;
      });
  };
  const fetchCompany = (id) => {
    getCompanyById(id)
      .then((res) => {
        companies.value = [res];
        isLoading.value = false;
      })
      .catch((err) => {
        console.log(err);
        isLoading.value = false;
      });
  };

  const fetchRoles = () => {
    getAllRoles()
      .then((res) => {
        userRoles.value = res;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return {
    companies,
    isLoading,
    userRoles,
    fetchCompanies,
    fetchRoles,
    fetchCompany,
  };
});
