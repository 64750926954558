<template>
  <div
    class="modal fade"
    id="changeCompanyStateModal"
    tabindex="-1"
    aria-labelledby="changeCompanyStateModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            {{ props.isActive ? "Deactivate" : "Activate" }} Company
          </h1>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="modal-body">
          <h2 class="confirmation">
            Are you sure you want to
            {{ props.isActive ? "deactivate" : "Activate" }} (
            {{ props.companyData.name }} ) company?
          </h2>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary delete-button"
            @click="changeCompanyState"
          >
            <span v-if="!isLoading">{{
              props.isActive ? "Deactivate" : "Activate"
            }}</span>
            <output v-if="isLoading" class="spinner-border spinner-border-sm">
              <span class="visually-hidden">Loading...</span>
            </output>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCompanyStore } from "@/pinia/companyStore";
import { activateCompany, deactivateCompany } from "@/services/company";
import { ref } from "vue";
import { toast } from "vue3-toastify";

const props = defineProps(["companyData", "isActive"]);
const companyStore = useCompanyStore();
const isLoading = ref(false);
const changeCompanyState = () => {
  if (props.isActive) {
    deactivateHundler();
  } else {
    activateHundler();
  }
};

const activateHundler = () => {
  isLoading.value = true;
  const modal = document.getElementById("changeCompanyStateModal");
  activateCompany(props.companyData.id)
    .then(() => {
      companyStore.fetchCompanies();
      isLoading.value = false;
      modal.classList.remove("show");
      toast.success("Company activated");
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something went wrong");
    });
};

const deactivateHundler = () => {
  isLoading.value = true;
  const modal = document.getElementById("changeCompanyStateModal");
  deactivateCompany(props.companyData.id)
    .then(() => {
      companyStore.fetchCompanies();
      isLoading.value = false;
      modal.classList.remove("show");
      toast.success("Company deactivated");
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something went wrong");
    });
};
</script>

<style scoped>
.confirmation {
  font-size: 16px;
  margin: 0;
  line-height: 20px;
}
.close {
  color: #000 !important;
  background-color: transparent;
  font-size: 16px;
  margin-left: auto;
}
.delete-button {
  width: 130px;
}
</style>
