<template>
  <div
    class="modal fade"
    id="deleteUserModal"
    tabindex="-1"
    aria-labelledby="deleteUserModal"
    aria-hidden="true"
  >
    <!--  -->
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="exampleModalLabel">
            Delete User from {{ props.updatedCompany.name }}
          </h1>
          <button
            type="button"
            class="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
        <div class="modal-body">
          <h2 class="confirmation">
            Are you sure you want to delete
            {{ `${props.updateUser.firstName} ${props.updateUser.lastName}` }},
            this action cannot be reversed
          </h2>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary delete-button"
            @click="deleteUserHandler"
          >
            <span v-if="!isLoading">Delete</span>
            <output v-if="isLoading" class="spinner-border spinner-border-sm">
              <span class="visually-hidden">Loading...</span>
            </output>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useCompanyStore } from "@/pinia/companyStore";
import { deleteUser } from "@/services/company";
import { ref } from "vue";
import { toast } from "vue3-toastify";

const props = defineProps(["updatedCompany", "updateUser", "isCompanyDetails"]);
const companyStore = useCompanyStore();
const isLoading = ref(false);
const deleteUserHandler = () => {
  isLoading.value = true;
  const modal = document.getElementById("deleteUserModal");

  deleteUser(props.updateUser.id)
    .then(() => {
      if (props.isCompanyDetails)
        companyStore.fetchCompany(props.updatedCompany.id);
      else companyStore.fetchCompanies();
      isLoading.value = false;
      modal.classList.remove("show");
      toast.success("User deleted");
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something went wrong");
    });
};
</script>

<style scoped>
.confirmation {
  font-size: 16px;
  margin: 0;
  line-height: 20px;
}
.close {
  color: #000  !important;
  background-color: transparent;
  font-size: 16px;
  margin-left: auto;
}
.delete-button {
  width: 105px;
}
</style>
