import axios from "axios";

export const resendEmail = (email) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/ResendEmail?email=${email}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
export const deactivateCompany = (companyId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/DeActivateCompany?companyId=${companyId}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
export const activateCompany = (companyId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/ActivateCompany?companyId=${companyId}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
export const addUserToCompany = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/AddUserToCompany",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const updateUser = (data, userId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/UpdateUser`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllRoles = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllRoles`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const deleteUser = (userId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "delete",
      url: `https://estimating.candrelec.com/api/DeleteUser?id=${userId}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
export const updateCompnayOwner = (companyId, ownerID) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/UpdateCompanyOwner?companyId=${companyId}&ownerId=${ownerID}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const updateCompany = (companyId, data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/UpdateCompany?id=${companyId}`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
export const createCompanyWithOwner = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/CreateCompanyWithOwnerUser?Aut",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getCompanyById = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetCompanyById?id=${id}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllCompanies = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllCompanies`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
