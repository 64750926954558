<template>
    <div class="projects-container">
        <div class="projects-sider">
            <div class="project-sider-items">
                Active
            </div>
            <div class="project-sider-items">
                On-Hold
            </div>
            <div class="project-sider-items">
                Archive
            </div>

            <div class="project-options">
                <div class="project-sider-items">
                    Create New
                </div>
                <div class="project-sider-items">
                    Open
                </div>
                <div class="project-sider-items">
                    Delete
                </div>

                <div class="project-sider-items details">
                    Details
                </div>

                <div class="project-sider-items">
                    Set Active
                </div>

                <div class="project-sider-items">
                    Set On-hold
                </div>

                <div class="project-sider-items">
                    Set Archive
                </div>


                <div class="project-sider-items settings">
                    Settings
                </div>
            </div>
        </div>

        <div class="projects-table">
            <h3 class="mb-3">
                Settings <span class="f-14">(Drag to change order)</span>
            </h3>

            <div class="settings-container">
                <div class="row">
                    <div class="col-md-6">
                        <h4 class="mb-3">
                            Shown
                        </h4>
                        <div class="settings-list">
                            <draggable v-model="shownItems">
                                <template v-slot:item="{ item }">
                                    <!-- example -->
                                    <p @contextmenu="onContextMenu($event)">
                                        {{ item.title }}
                                    </p>
                                    <!-- or your own template -->
                                </template>
                            </draggable>
                        </div>
                    </div>

                    <div class="col-md-6">
                        <h4 class="mb-3">
                            Not Shown
                        </h4>
                        <div class="settings-list">
                            <draggable v-model="notShownItems">
                                <template v-slot:item="{ item }">
                                    <p>
                                        {{ item.title }}
                                    </p>
                                </template>
                            </draggable>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import { ref, watch, inject, computed, onBeforeMount } from "vue"
import Draggable from "vue3-draggable";
import ContextMenu from '@imengyu/vue3-context-menu'

const shownItems = ref([
    {
        id: 1,
        title: ' Job Name'
    },
    {
        id: 2,
        title: ' Job Address'
    },
    {
        id: 3,
        title: ' Job Number'
    },
    {
        id: 4,
        title: ' Job Description'
    }
])

const notShownItems = ref([
{
        id: 5,
        title: 'Bidding Contact Name'
    },
    {
        id: 6,
        title: 'Requesting Contact'
    },
    {
        id: 7,
        title: 'Bidding Business Name'
    },
])

const onContextMenu = (e) => {
  e.preventDefault();
  ContextMenu.showContextMenu({
    x: e.x,
    y: e.y,
    items: [
      { 
        label: "Delete", 
        onClick: () => {
          console.log("You click a menu item");
        }
      }
    ]
  });
}

</script>
<style lang="scss">
.projects-sider {
    position: fixed;
    width: 200px;
    background: #fff;
    height: 100vh;
    border-left: 1px solid $border-color;
    padding: 24px;
}

.project-sider-items {
    font-size: 16px;
    color: rgba(82, 82, 108, 0.8);
    text-align: left;
    padding: 4px 0;
    cursor: pointer;

    &.details {
        margin: 15px 0;
    }

    &.settings {
        position: absolute;
        bottom: 100px;
        padding-bottom: 10px;
    }
}

.project-options {
    margin-top: 100px;
}

.projects-container {
    margin-left: -15px;
    display: flex;

    tr,
    td {
        font-size: 14px;
    }
}

.settings-container,
.settings-list {
    background: #fff;
    box-shadow: 0 0 5px #ccc;
    padding: 20px;
    margin-top: 30px;
    border-radius: 8px;

    p {
        border: 1px solid #ccc;
        padding: 5px 10px;
        border-radius: 4px;
        background: #EAEAEA;
        cursor: pointer;
    }
}</style>