<template>
  <div class="row">
    <div class="col-md-3 form-group mb-2">
      <label class="col-form-label"
        >Category <sup class="required" v-if="!props.ignoreErrors"> * </sup>

        <span class="required" v-if="formErrors.CategoryId"
          >(required)</span
        ></label
      >
      <div class="d-flex">
        <select
          @change="handleClickCategory($event.target.value)"
          class="form-select"
          :disabled="props.categoriesData.length === 0"
          :value="props.selectedData.CategoryId"
        >
          <option
            :value="category.id"
            v-for="(category, indx) in props.categoriesData"
            :selected="indx === 0"
          >
            {{ category.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-md-3 form-group mb-2">
      <label class="col-form-label"
        >System

        <sup class="required" v-if="!props.ignoreErrors"> * </sup>
        <span class="required" v-if="formErrors.SystemId">(required)</span>
      </label>
      <div class="d-flex">
        <select
          @change="handleClickSystem($event.target.value)"
          class="form-select"
          :disabled="selectedSystemsList.length === 0"
          :value="props.selectedData.SystemId"
        >
          <option :value="system.id" v-for="system in selectedSystemsList">
            {{ system.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-md-3 form-group mb-2">
      <label class="col-form-label"
        >Sub System

        <sup class="required" v-if="!props.ignoreErrors"> * </sup>
        <span class="required" v-if="formErrors.SubSystemId">(required)</span>
      </label>
      <div class="d-flex">
        <select
          @change="handleClickSubsystem($event.target.value)"
          class="form-select"
          :disabled="selectedSubSystemsList.length === 0"
          :value="props.selectedData.SubSystemId"
        >
          <option
            :value="subSystem.id"
            v-for="subSystem in selectedSubSystemsList"
          >
            {{ subSystem.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-md-3 form-group mb-2">
      <label class="col-form-label"
        >Style
        <sup class="required" v-if="!props.ignoreErrors"> * </sup>
        <span class="required" v-if="formErrors.StyleId">(required)</span>
      </label>
      <div class="d-flex">
        <select
          @change="handleClickStyle($event.target.value)"
          class="form-select"
          :disabled="selectedStyleList.length === 0"
          :value="props.selectedData.StyleId"
        >
          <option :value="style.id" v-for="style in selectedStyleList">
            {{ style.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import {
  getSystemsByCategoryId,
  getStylesBySubSystemId,
  getSubSystemsBySystemId,
} from "@/services/database";
import { useCategoriesStylesStore } from "@/pinia/categories";

const props = defineProps([
  "categoriesData",
  "manufacturerData",
  "selectedData",
  "errors",
  "submitCount",
  "ignoreErrors",
  "staticSelection",
  "initialSelectedData",
]);
const emit = defineEmits(["onSelect", "fetchAllCategories"]); //keys (category, system, subSystem, style)
const isLoading = ref(false);

const selectedSystemsList = ref([]);
const selectedSubSystemsList = ref([]);
const selectedStyleList = ref([]);
const isSystemDisabled = ref(true);
const isSubSystemDisabled = ref(true);
const isStyleDisabled = ref(true);
const formErrors = ref({});
const categoryStore = useCategoriesStylesStore();

watch(
  () => props.errors,
  (newValue) => {
    if (props.submitCount > 0) {
      formErrors.value = newValue;
    }
  }
);

watch(
  () => props.selectedData,
  (newValue) => {
    if (!newValue.CategoryId) {
      isSystemDisabled.value = true;
    }
    if (!newValue.SystemId) {
      isSubSystemDisabled.value = true;
    }
    if (!newValue.SubSystemId) {
      isStyleDisabled.value = true;
    }
  }
);

const handleClickCategory = (id) => {
  emit("onSelect", {
    CategoryId: id,
    SystemId: null,
    SubSystemId: null,
    StyleId: null,
  });

  getSystemsByIdHandler(id);
};

const getSystemsByIdHandler = (id, isNewItem) => {
  selectedSystemsList.value = [];
  selectedSubSystemsList.value = [];
  selectedStyleList.value = [];

  isLoading.value = true;
  getSystemsByCategoryId(id)
    .then((res) => {
      isSystemDisabled.value = false;
      isLoading.value = false;
      selectedSystemsList.value = res;
    })
    .catch((err) => {
      isLoading.value = false;
      console.error(err);
    });
};

const handleClickSystem = (id) => {
  emit("onSelect", { SystemId: id, SubSystemId: null, StyleId: null });
  getSubSystemsBySystemIdHandler(id);
};

const getSubSystemsBySystemIdHandler = (id, isNewItem) => {
  selectedStyleList.value = [];

  isLoading.value = true;
  getSubSystemsBySystemId(id)
    .then((res) => {
      isLoading.value = false;
      isSubSystemDisabled.value = false;
      selectedSubSystemsList.value = res;
    })
    .catch((err) => {
      isLoading.value = false;
      console.error(err);
    });
};

const handleClickSubsystem = (id) => {
  emit("onSelect", { SubSystemId: id, StyleId: null });
  getStylesBySubSystemIdHandler(id);
};

const getStylesBySubSystemIdHandler = (id, isNewItem) => {
  isLoading.value = true;

  getStylesBySubSystemId(id)
    .then((res) => {
      isLoading.value = false;
      isStyleDisabled.value = false;
      selectedStyleList.value = res;
    })
    .catch((err) => {
      isLoading.value = false;
      console.error(err);
    });
};

const handleClickStyle = (id) => {
  emit("onSelect", { StyleId: id });
};

watch(
  () => props.initialSelectedData,
  (newValue) => {
    emit("onSelect", {
      CategoryId: newValue.CategoryId,
      SystemId: newValue.SystemId,
      SubSystemId: newValue.SubSystemId,
      StyleId: newValue.StyleId,
    });
    if (newValue.CategoryId) {
      getSystemsByIdHandler(newValue.CategoryId);
    }
    if (newValue.SystemId) {
      getSubSystemsBySystemIdHandler(newValue.SystemId);
    }
    if (newValue.SubSystemId) {
      getStylesBySubSystemIdHandler(newValue.SubSystemId);
    }
  }
);
</script>

<style scoped lang="css">
.add {
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.required {
  color: red;
}
</style>
