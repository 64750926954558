<template>
  <!--  -->
  <viewer-control
    :viewer="viewer"
    :isGroup="props.isGroup"
    :activeTools="activeTools"
    :isSearchOpen="searchMenu"
    :isScaleOpen="scaleMenu"
    :saveStatus="'disabled'"
    @toggleScaleMenu="toggleScaleMenu"
    @toggleSearchMenu="toggleSearchMenu"
  />
  <div style="width: 100vw; height: 100vh; position: relative">
    <div
      @drop="onDrop"
      @dragenter.prevent
      @dragover.prevent
      class="position-relative"
      id="forgeViewer"
      style="width: 100%; height: 100%"
    >
      <pallet />
      <SearchMenu
        :isMenuOpen="searchMenu"
        :viewer="viewer"
        :allProjectModelsList="allProjectModelsList"
        :draggedPositionToSend="draggedPositionToSend"
        @setSelectedPartToDrag="setSelectedPartToDrag"
      />
      <ScaleTakeOff
        :isMenuOpen="scaleMenu"
        :viewer="viewer"
        :elements="props.elements"
        :bundles="props.bundles"
        :groups="props.groups"
      />

      <MoveSettings :viewer="viewer" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onBeforeUnmount, computed } from "vue";
import ViewerControl from "@/components/database/viewer-control";
import Pallet from "@/components/database/pallete/list.vue";

import { addViewable } from "@/components/database/utils";
import { usePallet } from "@/pinia/pallet";
import SearchMenu from "@/components/takeoff/search-menu.vue";
import ScaleTakeOff from "@/components/takeoff/scale-take-off.vue";
import { useSearchMenu } from "@/pinia/searchStore";
import MoveSettings from "@/components/takeoff/move-settings.vue";
import { useMoveStore } from "@/pinia/moveStore";

const defaultUrn =
  "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6d2lwLmRtLnByb2QvMjc4YTAwNmUtMzVhMC00Yjk0LTllNjMtNGMxNjZiMWE2MTViLmR3Zng=";

const props = defineProps({
  viewerRef: Object,
  URNs: Array,
  isBundle: Boolean,
  isGroup: Boolean,
  isElement: Boolean,
  viewerContainerId: String,
  elementsCount: Number,
  elements: Array,
  bundles: Array,
  groups: Array,
});

const activeTools = [
  "line",
  "angle",
  "rotate",
  "move",
  "panOrOrbit",
  "palleteMenu",
  "showHideMenu",
  "searchMenu",
  "scaleMenu",
  "save",
];

const emit = defineEmits(["updateViewer", "removeUrn", "addModelToViewer"]);

const searchMenu = ref(false);
const scaleMenu = ref(false);

const moveStore = useMoveStore();
const toggleSearchMenu = () => {
  searchMenu.value = !searchMenu.value;
};

const toggleScaleMenu = () => {
  scaleMenu.value = !scaleMenu.value;
};

const onDrop = (event) => {
  console.log("drop event", event);

  let data = event.dataTransfer.getData("id");
  let item = event.dataTransfer.getData("item");

  if (item) console.log("items", JSON.parse(item));
  let dragClientX = event.dataTransfer.getData("ClientX");
  let dragClientY = event.dataTransfer.getData("ClientY");

  if (data) {
    const menu = document.getElementById(data);
    const menuStyleTopValue = +menu.style.top.split("px")[0];
    const menuStyleRightValue = +menu.style.right.split("px")[0];

    if (menu) {
      menu.style.top = event.clientY - dragClientY + menuStyleTopValue + "px";
      menu.style.right =
        menuStyleRightValue - (event.clientX - dragClientX) + "px";
    }
    event.dataTransfer.clearData();
  }
};
const favoriteStore = usePallet();
const searchStore = useSearchMenu();
const isModelsLoaded = ref(false);
let viewer;
const options = {
  env: "AutodeskProduction",
  accessToken: localStorage.getItem("access_token"),
};

Autodesk.Viewing.Initializer(options, function onInitialized() {
  let viewerDiv = document.getElementById("forgeViewer");
  viewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv);
  viewer.start();
  addViewable(
    viewer,
    "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6d2lwLmRtLnByb2QvMjc4YTAwNmUtMzVhMC00Yjk0LTllNjMtNGMxNjZiMWE2MTViLmR3Zng=",
    null,
    new THREE.Vector3(0, 0, 0),
    0.1
  );

  viewer.addEventListener("extensionLoaded", async function () {
    emit("updateViewer", viewer);
    window.viewer = viewer;
    // hideBar(viewer);
  });

  viewer.container.addEventListener("click", function (ev) {
    const res = viewer.clientToWorld(ev.offsetX, ev.offsetY, true);
    const isPallete = favoriteStore.isMenuOpen && favoriteStore.activeElement;
    const isSearch = searchStore.isMenuOpen && searchStore.activeElement;
    const activeElement = isPallete
      ? favoriteStore.activeElement
      : searchStore.activeElement;

    if ((isPallete || isSearch) && res) {
      console.log("rendered", activeElement);

      emit("addModelToViewer", {
        ...activeElement,
        objectXPosition: res.point.x,
        objectYPosition: res.point.y,
        objectZPosition: res.point.z,
      });
    }
  });

  viewer.addEventListener("modelAdded", (event) => {
    setTimeout(() => {
      viewer.resize();
      isModelsLoaded.value = true;
    }, 1000);
  });

  const getSelectedMember = (event) => {
    const selectedModel = event.selections[0].model;
    const allElmenets = props.elements;
    const allBundles = props.bundles;
    const allGroups = props.groups;
    let selectedMember = null;

    // search in elements
    selectedMember = allElmenets.find((el) => el.uniqueId === selectedModel.id);
    if (selectedMember) return selectedMember;

    // search in bundles
    allBundles.forEach((bundle) => {
      if (bundle.budleElements.find((el) => el.uniqueId === selectedModel.id)) {
        selectedMember = bundle;
      }
    });
    if (selectedMember) return selectedMember;

    // search in groups
    allGroups.forEach((group) => {
      group.groupBundles.forEach((bundle) => {
        if (
          bundle.budleElements.find((el) => el.uniqueId === selectedModel.id)
        ) {
          selectedMember = group;
        }
      });
    });
    if (selectedMember) return selectedMember;
  };

  const selectElement = (element) => {
    const selectedModel = viewer
      .getAllModels()
      .find((model) => model.id === element.uniqueId);
    const instanceTree = selectedModel.getData().instanceTree;
    const rootId = instanceTree.getRootId();
    const dbIds = [rootId, 2];
    return [{ ids: dbIds, model: selectedModel }];
  };

  const selectBundle = (bundle) => {
    let allSelections = [];
    bundle.budleElements.map((element) => {
      const selectedModelObject = selectElement(element);
      allSelections = [...allSelections, ...selectedModelObject];
    });

    return allSelections;
  };

  const selectGroup = (group) => {
    let allSelections = [];
    group.groupBundles.map((bundle) => {
      const allSelectionsBundleObject = selectBundle(bundle);
      allSelections = [...allSelections, ...allSelectionsBundleObject];
    });
    return allSelections;
  };

  viewer.addEventListener(
    Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
    (event) => {
      // prevent cases (no selection, more than 1 selection, more than 1 element in selection)
      if (
        !event.selections.length ||
        event.selections.length > 1 ||
        event.selections[0].dbIdArray.length > 1 ||
        (moveStore.moveMode !== "model" && moveStore.isMenuOpen)
      )
        return;
      const selectedMember = getSelectedMember(event);
      let allSelections = [];
      console.log("selectedMember", selectedMember);

      switch (selectedMember.type) {
        case "Element": {
          const elementObject = selectElement(selectedMember);
          allSelections = elementObject;
          break;
        }
        case "Bundle": {
          const allSelectionsBundleObject = selectBundle(selectedMember);
          allSelections = allSelectionsBundleObject;
          break;
        }
        case "Group": {
          const allSelectionsGroupObject = selectGroup(selectedMember);
          allSelections = allSelectionsGroupObject;
          break;
        }
      }

      setTimeout(() => {
        viewer.impl.selector.setAggregateSelection(allSelections);
      }, 10);
    }
  );
});

onBeforeUnmount(() => {
  viewer.unloadExtension("Viewing.Extension.Transform");
  window.viewer = undefined;
  emit("updateViewer", undefined);
});

const shouldLoadScale = computed(() => {
  return props.URNs.length > 0 && isModelsLoaded.value;
});

const groupURNs = computed(() => {
  return props.URNs.map((x) => {
    return x.elements.map((ele) => {
      return { ...ele, elementsIndices: x.elementsIndices };
    });
  }).flat();
});

const renderElement = (elementObj) => {
  addViewable(
    viewer,
    elementObj.translatedUrn ||
      elementObj.dwfxObjectTranslatedUrn ||
      defaultUrn,
    null,
    new THREE.Vector3(
      +elementObj.objectXPosition,
      +elementObj.objectYPosition,
      +elementObj.objectZPosition
    ),
    elementObj.objectXScale || 1
  );
};
// watch in two values, props.allModels and viewer

const renderBundle = (bundleElement) => {
  // render new bundle case
  bundleElement.budleElements.map((element) => {
    renderElement(element);
  });
};

const renderGroup = (group) => {
  group.groupBundles.map((bundle) => {
    renderBundle(bundle);
  });
};

watch(
  () => props.elements,
  (newValue) => {
    let lastElement = newValue[newValue.length - 1];
    renderElement(lastElement);
  }
);
watch(
  () => props.bundles,
  (newValue) => {
    let lastBundle = newValue[newValue.length - 1];
    renderBundle(lastBundle);
  }
);
watch(
  () => props.groups,
  (newValue) => {
    let lastGroup = newValue[newValue.length - 1];
    renderGroup(lastGroup);
  }
);
</script>
