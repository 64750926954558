<template>
  <div
    class="modal fade"
    id="filterModal"
    tabindex="-1"
    aria-labelledby="filterModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="filterModal">
            {{
              searchFilterStore.searchType == "element"
                ? searchFilterStore.searchType == "part"
                  ? "Parts"
                  : "Elements"
                : "Bundles"
            }}
            filter
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <categories-systems
            :staticSelection="true"
            :hide-add-buttons="true"
            :categoriesData="props.categoriesData"
            :selectedData="systemSelectedData"
            :ignore-errors="true"
            @onSelect="
              (newSelectedData) =>
                (systemSelectedData = {
                  ...systemSelectedData,
                  ...newSelectedData,
                })
            "
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="resetHandler"
            data-bs-dismiss="modal"
          >
            Reset
          </button>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            @click="addFiltersHandler"
          >
            Add filters
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CategoriesSystems from "@/components/database/shared/categories-systems.vue";
import { useSearchFilterStore } from "@/pinia/searchFilterStore";
import { ref } from "vue";
const props = defineProps(["categoriesData"]);
const searchFilterStore = useSearchFilterStore();
const systemSelectedData = ref({});

const addFiltersHandler = () => {
  searchFilterStore.setSelectedFilters(systemSelectedData.value);
};
const resetHandler = () => {
  systemSelectedData.value = {};
  searchFilterStore.setSelectedFilters({});
};
</script>
