<template>
    <div v-if="isLoading" class="overlay-loader"></div>
    <div class="d-flex justify-content-between align-items-center mb-2 me-3">
      <h4 class="mb-0">Labor Division</h4>
      <button
        @click="openAddLaborDivision"
        class="btn btn-primary"
        style="margin-left: auto"
      >
        Add new Labor Division
      </button>
    </div>
  
    <div v-if="!createLaborDivisionModal" class="mt-4">
      <ag-grid-vue
        :loading="false"
        :defaultColDef="defaultColDef"
        :pagination="false"
        :paginationPageSize="10"
        :paginationPageSizeSelector="[10, 20, 30]"
        :rowData="allLaborDivision"
        :columnDefs="colDefs"
        style="height: 600px; width: 100%"
        @grid-ready="gridReady"
        class="ag-theme-quartz"
      >
      </ag-grid-vue>
    </div>
  
    <div v-else class="mt-4">
      <div class="">
        <div class="">
          <h5>Add new Labor Division</h5>
        </div>
        <div class="">
          <div class="row">
            <div class="col-md-6 form-group mb-3">
              <label class="col-form-label">Name</label>
              <div class="form-input">
                <input v-model="laborName" class="form-control" />
              </div>
            </div>  
          </div>
        </div>
        <div class="d-flex justify-content-end mb-4 mt-4">
          <button
            v-if="isUpdate"
            class="btn btn-secondary me-3"
            @click="handleDeleteLaborDivision"
          >
            Delete
          </button>
          <button
          class="btn btn-secondary me-3" @click="closeModal">
            Close
          </button>
          <button
          v-if="isUpdate"
          class="btn btn-primary" @click="handleUpdateLaborDivision()">
           Update
          </button>
          <button 
          v-else
          class="btn btn-primary" @click="handleSaveLaborDivision()">
            Save changes
          </button>
  
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, watch } from "vue";
  import {
    createLaborDivision,
    getAllLaborDivision,
    deleteLaborDivision,
    updateLaborDivision,
  } from "@/services/database";
  
  import { toast } from "vue3-toastify";
  import "vue3-toastify/dist/index.css";
  
  import Multiselect from "vue-multiselect";
  import "vue-multiselect/dist/vue-multiselect.css";

  import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
  import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
  import { AgGridVue } from "ag-grid-vue3"; // Vue Data Grid Component
  
  const createLaborDivisionModal = ref(false);
  
  const laborName = ref("");
  
  const isLoading = ref(false);
  const isUpdate = ref(false);
  const allLaborDivision = ref([]);
  const selectedLaborDivision = ref("");
  
  const colDefs = ref([
    { field: "name", flex: 3 },
    {
      field: "actions",
      headerName: "Action",
      cellRenderer: (params) => {
        // Create a unique ID for the button
        const buttonId = `btn-lab-${params.node.id}`;
        // Create the button with the unique ID
        const button = `<div class="pe-2 clickable align-self-center font-16 blue-link underline" id="${buttonId}">View</div>`;
        // Use a timeout to ensure the button is in the DOM before adding the event listener
        setTimeout(() => {
          document.getElementById(buttonId)?.addEventListener("click", () => {
            viewLabor(params.data);
          });
        }, 0);
        return button;
      },
    },
  ]);
    
  onMounted(() => {
    fetchAllLaborDivision();
  });


  const clearValues = () => {
    laborName.value = ""
  }
  
  const handleSaveLaborDivision = () => {
    isLoading.value = true;
    const laborPayload = {
      name: laborName.value,
    };
    createLaborDivision(laborPayload)
      .then((res) => {
        fetchAllLaborDivision()
        isLoading.value = false;
        closeModal();
        toast.success("Labor Division Created");
      })
      .catch((err) => {
        isLoading.value = false;
        toast.error("Something Went Wrong");
        console.error(err);
      });
  };
  
  const handleUpdateLaborDivision = () => {
    isLoading.value = true;
    const laborPayload = {
      name: laborName.value,
    };
    updateLaborDivision(laborPayload, selectedLaborDivision.value)
      .then((res) => {
        fetchAllLaborDivision();
        isLoading.value = false;
        closeModal();
        clearValues()
        toast.success("Labor Division Updated");
      })
      .catch((err) => {
        isLoading.value = false;
        toast.error("Something Went Wrong");
        console.error(err);
      });
  };
  
  const handleDeleteLaborDivision = () => {
    isLoading.value = true;
    deleteLaborDivision(selectedLaborDivision.value)
      .then((res) => {
        fetchAllLaborDivision()
        isLoading.value = false;
        closeModal();
      })
      .catch((err) => {
        isLoading.value = false;
        console.error(err);
      });
  };
  
  const fetchAllLaborDivision = () => {
    isLoading.value = true;
    getAllLaborDivision()
      .then((res) => {
        allLaborDivision.value = res;
        isLoading.value = false;
      })
      .catch((err) => {
        isLoading.value = false;
        console.error(err);
      });
  };
  
  const viewLabor = (param) => {
    selectedLaborDivision.value = param.id
    isUpdate.value = true;
    laborName.value = param.name;
    createLaborDivisionModal.value = true;
  };

  
  const closeModal = () => {
    isUpdate.value = false;
    createLaborDivisionModal.value = false;
  };
  
  const openAddLaborDivision = () => {
    createLaborDivisionModal.value = true;
  };
  </script>
  