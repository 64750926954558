<template>
  <form @submit="onSubmit" id="create-bundle-form">
    <div class="row">
      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Name<sup class="required"> * </sup>
          <span class="required" v-if="errors.name">(required)</span></label
        >
        <div class="form-input">
          <input
            v-model="newBundleName"
            v-bind="nameAttrs"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Size
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.size">(required)</span></label
        >
        <div class="form-input">
          <input
            v-model="newBundleSize"
            v-bind="sizeAttrs"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Inventory Number
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.inventoryNumber">(required)</span>
        </label>
        <input
          v-model="newBundleInventoryNumber"
          v-bind="inventoryNumberAttrs"
          class="form-control"
        />
      </div>

      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Part Owner Type
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.ownerType">(required)</span>
        </label>
        <select
          v-model="newBundlePartOwnerTypeId"
          v-bind="ownerTypeAttrs"
          class="form-select"
        >
          <option :value="owner.id" v-for="owner in props.ownerTypeData">
            {{ owner.name }}
          </option>
        </select>
      </div>
    </div>

    <categories-systems
      :hideAddButtons="true"
      :staticSelection="true"
      :categoriesData="props.categoriesData"
      :selectedData="systemSelectedData"
      @onSelect="
        (newSelectedData) =>
          (systemSelectedData = { ...systemSelectedData, ...newSelectedData })
      "
      :errors="errors"
      :submitCount="submitCount"
    />

    <div class="row">
      <!-- <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Object File Location Url
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.fileUrl">(required)</span>
        </label>

        <input
          v-model="newBundleObjectFileLocationUrl"
          v-bind="fileUrlAttrs"
          class="form-control"
          style="height: 55px"
        />
      </div> -->

      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Description
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.description">(required)</span>
        </label>
        <div class="form-input">
          <textarea
            v-model="newBundleDescription"
            class="form-control"
            v-bind="descriptionAttrs"
            style="height: 55px"
          ></textarea>
        </div>
      </div>

      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label">
          Notes
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.notes">(required)</span>
        </label>
        <div class="form-input">
          <textarea
            v-model="newBundleNotes"
            v-bind="notesAttrs"
            class="form-control"
            style="height: 55px"
          ></textarea>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import CategoriesSystems from "@/components/database/shared/categories-systems.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import "vue-multiselect/dist/vue-multiselect.css";

import { useForm } from "vee-validate";
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase";

const props = defineProps(["ownerTypeData", "categoriesData"]);

const emit = defineEmits(["onSubmit"]);

// extract submit to submit the form from a button

const newBundleImages = ref([]);
const bundleRFA = ref([]);
const newBundleElements = ref([]);
const systemSelectedData = ref({});

const {
  errors,
  handleSubmit,
  defineField,
  submitCount,
  setFieldError,
  resetForm,
  setFieldValue,
} = useForm({
  validationSchema: {
    name: (value) => (!value ? "field is required" : true),
    size: (value) => (!value ? "field is required" : true),
    inventoryNumber: (value) => (!value ? "field is required" : true),
    ownerType: (value) => (!value ? "field is required" : true),
    description: (value) => (!value ? "field is required" : true),
    notes: (value) => (!value ? "field is required" : true),
    CategoryId: (value) =>
      !systemSelectedData.value.CategoryId ? "field is required" : true,
    SystemId: (value) =>
      !systemSelectedData.value.SystemId ? "field is required" : true,
    SubSystemId: (value) =>
      !systemSelectedData.value.SubSystemId ? "field is required" : true,
    StyleId: (value) =>
      !systemSelectedData.value.StyleId ? "field is required" : true,
    // fileUrl: (value) => (!value ? "field is required" : true),
  },
});

const [newBundleName, nameAttrs] = defineField("name");

const [newBundleSize, sizeAttrs] = defineField("size");
const [newBundleInventoryNumber, inventoryNumberAttrs] =
  defineField("inventoryNumber");
const [newBundleDescription, descriptionAttrs] = defineField("description");
const [newBundleNotes, notesAttrs] = defineField("notes");
// const [newBundleObjectFileLocationUrl, fileUrlAttrs] = defineField("fileUrl");
const [newBundlePartOwnerTypeId, ownerTypeAttrs] = defineField("ownerType");

watch(systemSelectedData, (newValue) => {
  console.log("newValue", newValue);

  setFieldError(
    "CategoryId",
    newValue.CategoryId ? undefined : "field is required"
  );
  setFieldError(
    "SystemId",
    newValue.SystemId ? undefined : "field is required"
  );
  setFieldError(
    "SubSystemId",
    newValue.SubSystemId ? undefined : "field is required"
  );
  setFieldError("StyleId", newValue.StyleId ? undefined : "field is required");
});

const isLoading = ref(false);

const dbStore = useUpdateDatabaseComponentsStore();

const resetFormHandler = () => {
  resetForm();
  newBundleImages.value = [];
  bundleRFA.value = [];
  systemSelectedData.value = {};
  newBundleElements.value = [];
};

const handleCreateBundle = () => {
  const modal = document.getElementById("updateDBModal");
  isLoading.value = true;
  const bundleFormData = new FormData();
  const { CategoryId, SystemId, SubSystemId, StyleId } =
    systemSelectedData.value;

  bundleFormData.append("Name", newBundleName.value);
  bundleFormData.append("Description", newBundleDescription.value);
  bundleFormData.append("Size", newBundleSize.value);
  bundleFormData.append(
    "VersionNumber",
    dbStore.selectedDataToBeUpdated.versionNumber
  );
  // bundleFormData.append("MainBundleId", dbStore.selectedDataToBeUpdated.id);
  bundleFormData.append("BundleVersionId", dbStore.selectedDataToBeUpdated.id);
  bundleFormData.append("Notes", newBundleNotes.value);
  bundleFormData.append("InventoryNumber", newBundleInventoryNumber.value);
  bundleFormData.append("PartOwnerTypeId", newBundlePartOwnerTypeId.value);
  bundleFormData.append("CategoryId", CategoryId);
  bundleFormData.append("SystemId", SystemId);
  bundleFormData.append("SubSystemId", SubSystemId);
  bundleFormData.append("StyleId", StyleId);

  emit("onSubmit", bundleFormData);
  dbStore.setTargetData({});
  modal.classList.remove("show");
  resetFormHandler();
  // bundleFormData.append(
  //   "ObjectFileLocationUrl",
  //   newBundleObjectFileLocationUrl.value
  // );

  // for (var x = 0; x < newBundleImages.value.length; x++) {
  //   bundleFormData.append("BundleSymbolPictures", newBundleImages.value[x]);
  //   console.log("newBundleImages.value[x]", newBundleImages.value[x]);
  // }

  // for (var x = 0; x < getElementsIds.length; x++) {
  //   bundleFormData.append("ElementsIds", getElementsIds[x]);
  // }
};

const setInitialValue = () => {
  setFieldValue("name", dbStore.selectedDataToBeUpdated.name);
  // setFieldValue("versionNumber", dbStore.selectedDataToBeUpdated.versionNumber);
  setFieldValue("size", dbStore.selectedDataToBeUpdated.size);
  setFieldValue(
    "inventoryNumber",
    dbStore.selectedDataToBeUpdated.inventoryNumber
  );

  setFieldValue("description", dbStore.selectedDataToBeUpdated.description);
  setFieldValue("notes", dbStore.selectedDataToBeUpdated.notes);
  setFieldValue("ownerType", dbStore.selectedDataToBeUpdated.partOwnerTypeId);

  systemSelectedData.value = {};
};

watch(
  () => dbStore.selectedDataToBeUpdated,
  (newValue) => {
    setInitialValue();
  }
);

onMounted(() => {
  setInitialValue();
});

const onSubmit = handleSubmit(handleCreateBundle);
</script>
<style scoped lang="css">
textarea {
  resize: none;
}

.counterRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.increase {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: transparent;
  color: black;
}
.increase:hover {
  background-color: #ccc;
  color: white;
  border-color: #007bff;
}

.switcherContainer {
  padding-right: 12px;
}

.required {
  color: red;
}
</style>
