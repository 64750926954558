<template>
  <!--  -->
  <DraggableMenu
    id="search-menu"
    :isOpen="searchStore.isMenuOpen"
    :isMinimized="searchStore.minimized"
    :title="'Search'"
    @toggleMinimize="searchStore.toggleMinimize"
  >
    <div :class="{ 'body-wrapper': true, minimized: searchStore.minimized }">
      <div class="controls">
        <div class="search-container">
          <div class="col-12">
            <label class="col-form-label">type</label>

            <select
              class="form-select"
              v-model="selectedType"
              @change="resetSearchResults"
            >
              <option value="" disabled selected hidden>Select</option>
              <option :value="type" v-for="type in elementTypes">
                {{ type }}
              </option>
            </select>
          </div>
          <div class="col-12">
            <label class="col-form-label">search</label>
            <div class="search-bar">
              <input
                :disabled="selectedType === ''"
                type="text"
                v-model="searchTerm"
                @input="(event) => onSearch(event.target.value)"
                placeholder="Search..."
              />
            </div>
          </div>
        </div>

        <div class="col-12" v-if="searchResults.length">
          <label class="col-form-label">results:</label>
          <div
            @click="handleClick(item)"
            :class="{
              'favorit-item': true,
              active: searchStore?.activeElement?.id === item.id,
            }"
            v-for="item in searchResults"
            :key="item.name"
          >
            {{ item.name }}
          </div>
        </div>
        <p
          class="no-results"
          v-if="!searchResults.length && searchTerm && !isLoading"
        >
          No items found
        </p>
      </div>
    </div>
  </DraggableMenu>
</template>

<script setup>
import { ref } from "vue";

import DraggableMenu from "@/components/shared/dragable-menu.vue";
import { elementDbSearch } from "@/services/database";
import { useSearchMenu } from "@/pinia/searchStore";

const props = defineProps([
  "isMenuOpen",
  "viewer",
  "allProjectModelsList",
  "draggedPositionToSend",
]);
const searchStore = useSearchMenu();
const emit = defineEmits(["setSelectedPartToDrag"]);

const searchTerm = ref("");
const selectedType = ref("");

const debounceTimeout = ref(null);

const searchResults = ref([]);

const isLoading = ref(false);

const elementTypes = ["Elements", "Bundles", "Groups"];

const resetSearchResults = () => {
  searchResults.value = [];
  searchTerm.value = "";
  searchStore.setActiveElement(null);
};

const onDragHandler = (event, item) => {
  console.log(item);
  event.dataTransfer.dropEffect = "move";
  event.dataTransfer.effectAllowed = "move";
  event.dataTransfer.setData("item", JSON.stringify(item));
};

const handleClick = (item) => {
  // make the first letter of the type uppercase
  const type = selectedType.value.slice(0, -1);

  if (searchStore.activeElement?.id === item.id) {
    searchStore.setActiveElement(null);
  } else {
    searchStore.setActiveElement({ ...item, type: type });
  }
};

const onSearch = (search) => {
  console.log("search", search);
  isLoading.value = true;
  if (debounceTimeout.value) {
    clearTimeout(debounceTimeout.value);
  }
  debounceTimeout.value = setTimeout(() => {
    if (search.length) {
      const params = new URLSearchParams();
      params.append("Keyword", search);
      elementDbSearch(params)
        .then((response) => {
          isLoading.value = false;
          searchResults.value = response[selectedType.value.toLowerCase()];
        })
        .catch((err) => {
          isLoading.value = false;
          console.error(err);
        });
    }
  }, 1000);
};
</script>

<style scoped lang="scss">
.body-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.search-bar input {
  width: 100%;
  padding: 2px 8px;
  background-color: rgb(240, 240, 240) !important;
  border: none;
  height: 33px;
}

.favorit-item {
  padding: 12px;
  border-bottom: 1px solid #6b7280;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #f3f4f6;
  }

  &:last-child {
    border-bottom: none;
  }
  &.active {
    background-color: #f3f4f6;
  }
  .delete {
    background-color: transparent;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 14px;
    padding: 0;

    & i {
      margin: 0;
    }
  }
}

.no-results {
  margin: 0;
  margin-top: 8px;
}
</style>
