<template>
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-xl-7 b-center bg-size"
        :style="{
          backgroundImage:
            'url(' + require('../assets/images/login/2.jpg') + ')',
        }"
        style="
          background-size: cover;
          background-position: center center;
          display: block;
        "
      >
        <img
          class="bg-img-cover bg-center"
          src="../assets/images/login/2.jpg"
          alt="looginpage"
          style="display: none"
        />
      </div>
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div class="login-main">
              <div class="text-center">
                <div v-if="loading">
                  <img
                    class=""
                    src="../assets/images/login/loader-gif.gif"
                    alt="looginpage"
                  />
                </div>
                <div v-if="error" class="">
                  <h4>{{ error }}</h4>
                  <button
                    @click="goToLogin"
                    class="btn btn-secondary w-100 mt-5"
                  >
                    Go To Login
                  </button>
                </div>
                <!-- <h4 v-else>Successfully authenticated! Token: {{ token }}</h4> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {
  getThreeLeggedToken,
  getUserInfoFrom3LeggedToken,
} from "@/services/autodesk";
import { ifUserExist } from "@/services/auth";
import { useRouter } from "vue-router";

const router = useRouter();
const loading = ref(true);
const error = ref(null);
const token = ref(null);

const goToLogin = () => {
  router.push("/auth/login");
};

const fetch3LegToken = (code) => {
  loading.value = true;
  getThreeLeggedToken(
    code, // authorizationCode
    "https://localhost:8080/api/auth/callback" // redirectUri
  )
    .then((res) => {
      console.log("res", res);
      localStorage.setItem("ACC_TOKEN", res.access_token);
      localStorage.setItem("access_token", res.access_token);
      handleGetUserInfoFrom3LeggedToken();
    })
    .catch((err) => {
      loading.value = false;
      error.value = true;
      console.error(err);
    });
};

const handleGetUserInfoFrom3LeggedToken = () => {
  loading.value = true;
  getUserInfoFrom3LeggedToken()
    .then((res) => {
      console.log("res", res);
      handleIsUserExist(res.emailId);
    })
    .catch((err) => {
      loading.value = false;
      error.value = true;
      console.error(err);
    });
};

const handleIsUserExist = (email) => {
  ifUserExist(email)
    .then((res) => {
      loading.value = false;
      console.log("res", res);
    })
    .catch((err) => {
      loading.value = false;
      error.value = "User doesn't exist on system";
      console.error(err);
    });
};

onMounted(() => {
  // Extract the "code" from the URL
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  if (!code) {
    error.value = "Authorization code is missing!";
    loading.value = false;
    return;
  }
  fetch3LegToken(code);
});
</script>
