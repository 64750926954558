import { defineStore } from "pinia";
import { ref } from "vue";

export const useCategoriesStylesStore = defineStore("categories-styles", () => {
  const shouldUpdate = ref(false);
  const selectedLookup = ref({
    name: "hello worlds",
    onSuccess: () => {},
    dependentId: undefined,
  });

  function setSelectedLookup(newLookup) {
    selectedLookup.value = newLookup;
  }

  return { shouldUpdate, setSelectedLookup, selectedLookup };
});
