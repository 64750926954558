<template>
  <div v-if="isLoading" class="overlay-loader"></div>
  <div class="d-flex justify-content-between align-items-center mb-2 me-3">
    <h4 class="mb-0">Burden Category</h4>
    <button
      @click="openAddBurdenCategory"
      class="btn btn-primary"
      style="margin-left: auto"
    >
      Add new Burden Category
    </button>
  </div>

  <div v-if="!createBurdenCategoryModal" class="mt-4">
    <ag-grid-vue
      :loading="false"
      :defaultColDef="defaultColDef"
      :pagination="false"
      :paginationPageSize="10"
      :paginationPageSizeSelector="[10, 20, 30]"
      :rowData="allBurdenCategories"
      :columnDefs="colDefs"
      style="height: 600px; width: 100%"
      @grid-ready="gridReady"
      class="ag-theme-quartz"
    >
    </ag-grid-vue>
  </div>

  <div v-else class="mt-4">
    <div class="">
      <div class="">
        <h5>Add new Burden Category</h5>
      </div>
      <div class="">
        <div class="row">
          <div class="col-md-6 form-group mb-3">
            <label class="col-form-label">Name</label>
            <div class="form-input">
              <input v-model="burdenCatName" class="form-control" />
            </div>
          </div>
          <div class="col-md-6 form-group mb-3">
            <label class="col-form-label">Default Percentage Amount</label>
            <div class="form-input">
              <input v-model="burdenCatNamePercentage" class="form-control" />
            </div>
          </div>

          <div class="col-md-6 form-group mb-3">
            <label class="col-form-label">Default Value</label>
            <div class="form-input">
              <input v-model="burdenCatValue" class="form-control" />
            </div>
          </div>

          <div class="col-md-6 form-group mb-3">
            <label class="col-form-label">Description</label>
            <div class="form-input">
              <textarea
                v-model="burdenCatDescription"
                class="form-control"
                rows="2"
              ></textarea>
            </div>
          </div>

          <div class="col-md-6 form-group mb-3">
            <label class="col-form-label d-block pb-7">Is Public</label>
            <div class="custom-switch">
              <span
                @click="handleSwitch(1)"
                :class="burdenCatIsPublic === 1 ? 'active' : ''"
                >Yes</span
              >
              <span
                @click="handleSwitch(2)"
                :class="burdenCatIsPublic === 2 ? 'active' : ''"
                >No</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end mb-4">
        <button
          v-if="isUpdate"
          class="btn btn-secondary me-3"
          @click="handleDeleteBurdenCategory"
        >
          Delete
        </button>
        <button
        class="btn btn-secondary me-3" @click="closeModal">
          Close
        </button>
        <button
        v-if="isUpdate"
        class="btn btn-primary" @click="handleUpdateBurdenCategory()">
         Update
        </button>
        <button 
        v-else
        class="btn btn-primary" @click="handleSaveBurdenCategory()">
          Save changes
        </button>

      </div>
    </div>
  </div>

</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import {
  createBurdenCategory,
  getAllBurdenCategories,
  deleteBurdenCategory,
  updateBurdenCategory
} from "@/services/database";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue3"; // Vue Data Grid Component

const createBurdenCategoryModal = ref(false);

const burdenCatName = ref("");
const burdenCatNamePercentage = ref("");
const burdenCatValue = ref("");
const burdenCatDescription = ref("");
const burdenCatIsPublic = ref(1);
const isLoading = ref(false);
const isUpdate = ref(false);
const allBurdenCategories = ref([]);
const selectedBurdenCategory = ref("");

const colDefs = ref([
  { field: "name", flex: 3 },
  { field: "defaultPercentageAmount" },
  { field: "defaultValue" },
  {
    field: "isPublic",
    cellRenderer: (params) => {
      let publicText = "";
      switch (params.value) {
        case true:
          publicText = "Yes";
          break;
        case false:
          publicText = "No";
          break;
      }
      return `<div>${publicText}</div>`;
    },
  },
  { field: "description", flex: 2 },
  {
    field: "actions",
    headerName: "Action",
    cellRenderer: (params) => {
      // Create a unique ID for the button
      const buttonId = `btn-${params.node.id}`;
      // Create the button with the unique ID
      const button = `<div class="pe-2 clickable align-self-center font-16 blue-link underline" id="${buttonId}">View</div>`;
      // Use a timeout to ensure the button is in the DOM before adding the event listener
      setTimeout(() => {
        document.getElementById(buttonId)?.addEventListener("click", () => {
          viewBurden(params.data);
        });
      }, 0);
      return button;
    },
  },
]);

watch(
  () => burdenCatNamePercentage.value,
  (newValue) => {
    if (newValue > 0) {
      burdenCatValue.value = 0;
    }
  }
);

watch(
  () => burdenCatValue.value,
  (newValue) => {
    if (newValue > 0) {
      burdenCatNamePercentage.value = 0;
    }
  }
);

onMounted(() => {
  fetchAllBurdenCategories();
});

const clearValues = () => {
    burdenCatName.value = ""
    burdenCatDescription.value = ""
 burdenCatIsPublic.value  = 1
    burdenCatNamePercentage.value = ""
 burdenCatValue.value = ""
  }

const handleSaveBurdenCategory = () => {
  isLoading.value = true;
  const burdenCategoryPayload = {
    name: burdenCatName.value,
    description: burdenCatDescription.value,
    isPublic: burdenCatIsPublic.value === 1 ? true : false,
    defaultPercentageAmount: burdenCatNamePercentage.value,
    defaultValue: burdenCatValue.value,
  };
  createBurdenCategory(burdenCategoryPayload)
    .then((res) => {
      fetchAllBurdenCategories();
      isLoading.value = false;
      closeModal();
      toast.success("Burden Category Created");
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
};


const handleUpdateBurdenCategory = () => {
  isLoading.value = true;
  const burdenCategoryPayload = {
    name: burdenCatName.value,
    description: burdenCatDescription.value,
    isPublic: burdenCatIsPublic.value === 1 ? true : false,
    defaultPercentageAmount: burdenCatNamePercentage.value,
    defaultValue: burdenCatValue.value,
  };
  updateBurdenCategory(burdenCategoryPayload, selectedBurdenCategory.value)
    .then((res) => {
      fetchAllBurdenCategories();
      isLoading.value = false;
      closeModal();
      clearValues()
      toast.success("Burden Category Updated");
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
};

const handleDeleteBurdenCategory = () => {
  isLoading.value = true;
  deleteBurdenCategory(selectedBurdenCategory.value)
    .then((res) => {
     fetchAllBurdenCategories()
      isLoading.value = false;
      closeModal();
    })
    .catch((err) => {
      isLoading.value = false;
      console.error(err);
    });
};

const fetchAllBurdenCategories = () => {
  isLoading.value = true;
  getAllBurdenCategories()
    .then((res) => {
      allBurdenCategories.value = res;
      isLoading.value = false;
    })
    .catch((err) => {
      isLoading.value = false;
      console.error(err);
    });
};
          
const viewBurden = (param) => {
  selectedBurdenCategory.value = param.id
  isUpdate.value = true;
  burdenCatName.value = param.name;
  burdenCatNamePercentage.value = param.defaultPercentageAmount;
  burdenCatValue.value = param.defaultValue;
  burdenCatDescription.value = param.description;
  burdenCatIsPublic.value = param.isPublic === true ? 1 : 0;
  createBurdenCategoryModal.value = true;
};

const handleSwitch = (param) => {
  burdenCatIsPublic.value = param;
};

const closeModal = () => {
  isUpdate.value = false;
  createBurdenCategoryModal.value = false;
};

const openAddBurdenCategory = () => {
  createBurdenCategoryModal.value = true;
};
</script>
