<template>
  <!--  -->
  <DraggableMenu
    id="show-hide-menu"
    :isOpen="props.isMenuOpen"
    :isMinimized="minimized"
    :title="'Show/Hide Menu'"
    @toggleMinimize="toggleMinimize"
  >
    <div :class="{ 'body-wrapper': true, minimized: minimized }">
      <div class="col-12">
        <label class="col-form-label">Select a Model</label>
        <div class="form-input">
          <select
            @change="onSelectModelChange"
            v-model="selectedModel"
            class="form-select"
          >
            <option value="" disabled selected hidden>Select</option>
            <option v-for="list in props.modelList" :value="list.modelID">
              Model {{ list.modelID }} -
              {{ list.isVerified ? "Verified" : "Not Verified" }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-12">
        <label class="col-form-label d-block pb-7">Show/Hide</label>
        <div class="custom-switch">
          <span
            @click="handleSwitch(1)"
            :class="selectedShowHideType === 1 ? 'active' : ''"
            >Show</span
          >
          <span
            @click="handleSwitch(2)"
            :class="selectedShowHideType === 2 ? 'active' : ''"
            >Hide</span
          >
        </div>
      </div>
    </div>
  </DraggableMenu>
</template>

<script setup>
import { ref } from "vue";

import DraggableMenu from "@/components/shared/dragable-menu.vue";

const props = defineProps(["isMenuOpen", "modelList"]);
const minimized = ref(true);
const selectedShowHideType = ref(1);
const selectabilityType = ref(1);
const selectedModel = ref("");
const emit = defineEmits(["selectModelChange"]);

const toggleMinimize = () => {
  minimized.value = !minimized.value;
};

const handleSwitch = (type) => {
  console.log("Type", type);
  selectedShowHideType.value = type;
  emit(
    "selectModelChange",
    selectedModel.value,
    selectedShowHideType.value,
    selectabilityType.value
  );
};

const onSelectModelChange = () => {
  emit(
    "selectModelChange",
    selectedModel.value,
    selectedShowHideType.value,
    selectabilityType.value
  );
};
</script>

<style scoped lang="scss">
.body-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
