<template>
  <div v-if="isLoading" class="overlay-loader"></div>
  <div class="d-flex justify-content-between align-items-center mb-2 me-3">
    <h4 class="mb-0">Job Title</h4>
    <button
      @click="openAddJobTitle"
      class="btn btn-primary"
      style="margin-left: auto"
    >
      Add new Job Title
    </button>
  </div>

  <div v-if="!createJobTitleModal" class="mt-4">
    <ag-grid-vue
      :loading="false"
      :defaultColDef="defaultColDef"
      :pagination="false"
      :paginationPageSize="10"
      :paginationPageSizeSelector="[10, 20, 30]"
      :rowData="allJobTitle"
      :columnDefs="colDefs"
      style="height: 600px; width: 100%"
      @grid-ready="gridReady"
      class="ag-theme-quartz"
    >
    </ag-grid-vue>
  </div>

  <div v-else class="mt-4">
    <div class="">
      <div class="">
        <h5>Add new Job Title</h5>
      </div>
      <div class="">
        <div class="row">
          <div class="col-md-6 form-group mb-3">
            <label class="col-form-label">Name</label>
            <div class="form-input">
              <input v-model="jobTitleName" class="form-control" />
            </div>
          </div>
          <div class="col-md-6 form-group mb-3">
            <label class="col-form-label">Hourly Rate</label>
            <div class="form-input">
              <input v-model="jobTitleHourlyRate" class="form-control" />
            </div>
          </div>

          <div class="col-md-6 form-group mb-3">
            <label class="col-form-label">Select Labor Division</label>
            <div class="form-input">
              <select v-model="jobTitleLabor" class="form-select">
                <option value="" disabled selected>Select</option>
                <option :value="labor.id" v-for="labor in allLaborDivision">
                  {{ labor.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-6 form-group mb-3">
            <label class="col-form-label">Select Burden</label>
            <div class="form-input">
              <select
                v-model="jobTitleBurden"
                @change="handleSelect"
                class="form-select"
              >
                <option value="" disabled selected>Select</option>
                <option :value="burden.id" v-for="burden in allBurden">
                  {{ burden.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-6 form-group mb-3">
            <label class="col-form-label">Description</label>
            <div class="form-input">
              <textarea
                v-model="jobTitleDescription"
                class="form-control"
                rows="2"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end mb-4 mt-4">
        <button
          v-if="isUpdate"
          class="btn btn-secondary me-3"
          @click="handleDeleteJobTitle"
        >
          Delete
        </button>
        <button class="btn btn-secondary me-3" @click="closeModal">
          Close
        </button>
        <button
          v-if="isUpdate"
          class="btn btn-primary"
          @click="handleUpdateJobTitle()"
        >
          Update
        </button>
        <button v-else class="btn btn-primary" @click="handleSaveJobTitle()">
          Save changes
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import {
  createJobTitle,
  getAllJobTitle,
  updateJobTitle,
  deleteJobTitle,
  getAllLaborDivision,
  getAllBurden
} from "@/services/database";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue3"; // Vue Data Grid Component

const createJobTitleModal = ref(false);

const jobTitleName = ref("");
const jobTitleHourlyRate = ref("");
const jobTitleLabor = ref("");
const jobTitleBurden = ref("");
const jobTitleDescription = ref("");

const isLoading = ref(false);
const isUpdate = ref(false);
const allJobTitle = ref([]);
const selectedJobTitle = ref("");
const allBurden = ref([])
const allLaborDivision = ref([])

const colDefs = ref([
  { field: "name", flex: 3 },
  { field: "description" },
  { field: "hourlyRate" },
  { field: "burdenCost" },
  { field: "burdenPercent" },
  { field: "totalCost" },
  {
    field: "actions",
    headerName: "Action",
    cellRenderer: (params) => {
      // Create a unique ID for the button
      const buttonId = `btn-job-${params.node.id}`;
      // Create the button with the unique ID
      const button = `<div class="pe-2 clickable align-self-center font-16 blue-link underline" id="${buttonId}">View</div>`;
      // Use a timeout to ensure the button is in the DOM before adding the event listener
      setTimeout(() => {
        document.getElementById(buttonId)?.addEventListener("click", () => {
          viewJobTitle(params.data);
        });
      }, 0);
      return button;
    },
  },
]);

onMounted(() => {
  fetchAllJobTitle();
  fetchAllBurden();
  fetchAllLaborDivision()
});

const clearValues = () => {
     jobTitleName.value = ""
    jobTitleDescription.value = ""
jobTitleHourlyRate.value = ""
 jobTitleLabor.value = ""
 jobTitleBurden.value = ""
  }

const fetchAllBurden = () => {
    isLoading.value = true;
    getAllBurden()
      .then((res) => {
        allBurden.value = res;
        isLoading.value = false;
      })
      .catch((err) => {
        isLoading.value = false;
        console.error(err);
      });
  };

  const fetchAllLaborDivision = () => {
    isLoading.value = true;
    getAllLaborDivision()
      .then((res) => {
        allLaborDivision.value = res;
        isLoading.value = false;
      })
      .catch((err) => {
        isLoading.value = false;
        console.error(err);
      });
  };

const handleSaveJobTitle = () => {
  isLoading.value = true;
  const jobTitlePayload = {
    name: jobTitleName.value,
    description: jobTitleDescription.value,
    hourlyRate: jobTitleHourlyRate.value,
    labourDivisionId: jobTitleLabor.value,
    burdenId: jobTitleBurden.value,
  };
  createJobTitle(jobTitlePayload)
    .then((res) => {
      fetchAllJobTitle();
      isLoading.value = false;
      closeModal();
      toast.success("Job title Created");
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
};

const handleUpdateJobTitle = () => {
  isLoading.value = true;
  const jobTitlePayload = {
    name: jobTitleName.value,
    description: jobTitleDescription.value,
    hourlyRate: jobTitleHourlyRate.value,
    labourDivisionId: jobTitleLabor.value,
    burdenId: jobTitleBurden.value,
  };
  updateJobTitle(jobTitlePayload, selectedJobTitle.value)
    .then((res) => {
      fetchAllJobTitle();
      isLoading.value = false;
      closeModal();
      clearValues()
      toast.success("Job Title Updated");
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
};

const handleDeleteJobTitle = () => {
  isLoading.value = true;
  deleteJobTitle(selectedJobTitle.value)
    .then((res) => {
      fetchAllJobTitle();
      isLoading.value = false;
      closeModal();
    })
    .catch((err) => {
      isLoading.value = false;
      console.error(err);
    });
};

const fetchAllJobTitle = () => {
  isLoading.value = true;
  getAllJobTitle()
    .then((res) => {
      allJobTitle.value = res;
      isLoading.value = false;
    })
    .catch((err) => {
      isLoading.value = false;
      console.error(err);
    });
};

const viewJobTitle = (param) => {
  selectedJobTitle.value = param.id;
  isUpdate.value = true;
  jobTitleName.value = param.name;
  jobTitleHourlyRate.value = param.hourlyRate;
  jobTitleLabor.value = param.labourDivisionId;
  jobTitleBurden.value = param.burdenId;
  jobTitleDescription.value = param.description;
  createJobTitleModal.value = true;
};

const closeModal = () => {
  isUpdate.value = false;
  createJobTitleModal.value = false;
};

const openAddJobTitle = () => {
  createJobTitleModal.value = true;
};
</script>
