<template>
  <div class="ps-3">
    <form @submit="onSubmit" id="create-part-form">
      <div class="row">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Name
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.name">(required)</span>
          </label>
          <div class="form-input">
            <input
              v-model="newPartName"
              v-bind="nameAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Upc
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.upc">(required)</span>
          </label>
          <div class="form-input">
            <input
              v-model="newPartUpc"
              v-bind="upcAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Cut Sheet Url
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.sheetUrl">(required)</span>
          </label>
          <div class="form-input">
            <input
              v-model="newPartCutSheetUrl"
              v-bind="sheetUrlAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Manufacturer Part Number
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.partNumber">(required)</span>
          </label>
          <div class="form-input">
            <select
              v-model="newPartManufacturerPartNumber"
              v-bind="partNumber"
              class="form-select"
            >
              <option
                :value="manufacturer.id"
                v-for="manufacturer in manufacturerData"
              >
                {{ manufacturer.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <categories-systems
        :hideAddButtons="true"
        :staticSelection="true"
        :categoriesData="props.categoriesData"
        :selectedData="systemSelectedData"
        @onSelect="
          (newSelectedData) =>
            (systemSelectedData = { ...systemSelectedData, ...newSelectedData })
        "
        @fetchAllCategories="emit('fetchAllCategories')"
        :errors="errors"
        :submitCount="submitCount"
      />

      <div class="row">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Version Number
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.versionNumber && submitCount"
              >(required)</span
            >
          </label>
          <div class="form-input">
            <input
              v-model="newPartVersionNumber"
              v-bind="versionNumberAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Owner Type
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.ownerType && submitCount"
              >(required)</span
            >
          </label>
          <select
            v-model="newPartOwnerType"
            v-bind="ownerTypeAttrs"
            class="form-select"
          >
            <option :value="owner.id" v-for="owner in props.ownerTypeData">
              {{ owner.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Cost Code
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.costCode && submitCount"
              >(required)</span
            >
          </label>
          <select
            v-model="newPartCostCode"
            v-bind="costCodeAttrs"
            class="form-select"
          >
            <option :value="costCode.id" v-for="costCode in props.costCodeData">
              {{ costCode.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Price
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.priceUnit && submitCount"
              >(required)</span
            >
          </label>
          <select
            v-model="newPartPrice"
            v-bind="priceAttrs"
            class="form-select"
          >
            <option :value="price.id" v-for="price in pricingUnitsData">
              {{ price.name }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, watch, onMounted } from "vue";
import {
  getAllPricingUnits,
  getAllManufacturers,
  createPartService,
  handleUpdateDBTarget,
} from "@/services/database";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useForm, useSetFieldValue } from "vee-validate";
import CategoriesSystems from "@/components/database/shared/categories-systems.vue";
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase";

const props = defineProps(["categoriesData", "ownerTypeData", "costCodeData"]);
const emit = defineEmits(["onSubmit"]);
const dbStore = useUpdateDatabaseComponentsStore();

const updateLaborCostPart = ref("");

const createdPart = ref("");
const updateLaborPricePart = ref("");
const isLoading = ref(false);
const pricingUnitsData = ref([]);
const manufacturerData = ref([]);
const newPartImages = ref([]);
const systemSelectedData = ref({
  CategoryId: dbStore.selectedDataToBeUpdated.CategoryId,
  SystemId: dbStore.selectedDataToBeUpdated.SystemId,
  SubSystemId: dbStore.selectedDataToBeUpdated.SubSystemId,
  StyleId: dbStore.selectedDataToBeUpdated.StyleId,
});

const isPrivate = ref("private");
const {
  errors,
  handleSubmit,
  defineField,
  submitCount,
  setFieldError,
  setFieldValue,
  resetForm,
} = useForm({
  validationSchema: {
    name: (value) => (!value ? "field is required" : true),
    upc: (value) => (!value ? "field is required" : true),
    sheetUrl: (value) => (!value ? "field is required" : true),
    partNumber: (value) => (!value ? "field is required" : true),
    versionNumber: (value) => (!value ? "field is required" : true),
    ownerType: (value) => (!value ? "field is required" : true),
    costCode: (value) => (!value ? "field is required" : true),
    priceUnit: (value) => (!value ? "field is required" : true),

    CategoryId: (value) =>
      !systemSelectedData.value.CategoryId ? "field is required" : true,
    SystemId: (value) =>
      !systemSelectedData.value.SystemId ? "field is required" : true,
    SubSystemId: (value) =>
      !systemSelectedData.value.SubSystemId ? "field is required" : true,
    StyleId: (value) =>
      !systemSelectedData.value.StyleId ? "field is required" : true,
  },
});

const [newPartName, nameAttrs] = defineField("name");
const [newPartPrice, priceAttrs] = defineField("priceUnit");

const [newPartUpc, upcAttrs] = defineField("upc");

const [newPartCutSheetUrl, sheetUrlAttrs] = defineField("sheetUrl");
const [newPartManufacturerPartNumber, partNumber] = defineField("partNumber");

const [newPartVersionNumber, versionNumberAttrs] = defineField("versionNumber");
const [newPartOwnerType, ownerTypeAttrs] = defineField("ownerType");
const [newPartCostCode, costCodeAttrs] = defineField("costCode");

watch(systemSelectedData, (newValue) => {
  console.log("newValue", newValue);

  setFieldError(
    "CategoryId",
    newValue.CategoryId ? undefined : "field is required"
  );
  setFieldError(
    "SystemId",
    newValue.SystemId ? undefined : "field is required"
  );
  setFieldError(
    "SubSystemId",
    newValue.SubSystemId ? undefined : "field is required"
  );
  setFieldError("StyleId", newValue.StyleId ? undefined : "field is required");
});

onBeforeMount(() => {
  fetchAllManufacturers();
  fetchAllPricingUnits();
});

const fetchAllManufacturers = () => {
  getAllManufacturers()
    .then((res) => {
      manufacturerData.value = res.items;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const resetFormHandler = () => {
  resetForm();
  systemSelectedData.value = {};
};

const handleCreatePart = () => {
  const modal = document.getElementById("updateDBModal");

  const { CategoryId, SystemId, SubSystemId, StyleId } =
    systemSelectedData.value;
  isLoading.value = true;
  const partFormData = new FormData();
  partFormData.append("Name", newPartName.value);
  partFormData.append(
    "ManufacturerPartNumber",
    newPartManufacturerPartNumber.value
  );
  partFormData.append("Upc", newPartUpc.value);
  partFormData.append("CutSheetUrl", newPartCutSheetUrl.value);
  partFormData.append("VersionNumber", newPartVersionNumber.value);
  partFormData.append("OwnerTypeId", newPartOwnerType.value);
  partFormData.append("CostCodeId", newPartCostCode.value);
  partFormData.append("CategoryId", CategoryId);
  partFormData.append("SystemId", SystemId);
  partFormData.append("SubSystemId", SubSystemId);
  partFormData.append("StyleId", StyleId);
  partFormData.append("isPrivate", isPrivate.value);
  partFormData.append("LaborCostId", 1);
  partFormData.append("PriceId", 1);
  for (var x = 0; x < newPartImages.value.length; x++) {
    partFormData.append("Images", newPartImages.value[x]);
  }
  emit("onSubmit", partFormData);
  modal.classList.remove("show");
  resetFormHandler();
  dbStore.setTargetData({});

  // handleUpdateDBTarget("part", partFormData)
  //   .then((res) => {
  //     isLoading.value = false;
  //     console.log("res", res);
  //   })
  //   .catch((err) => {
  //     isLoading.value = false;
  //     toast.error("Something Went Wrong");
  //     console.error(err);
  //   });
};

const onFileChange = (e) => {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  newPartImages.value = files;
  console.log("newPartImages", newPartImages.value);
};

const fetchAllPricingUnits = () => {
  getAllPricingUnits()
    .then((res) => {
      pricingUnitsData.value = res.items;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const setInitialValue = () => {
  setFieldValue("name", dbStore.selectedDataToBeUpdated.name);
  setFieldValue("upc", dbStore.selectedDataToBeUpdated.upc);
  setFieldValue("sheetUrl", dbStore.selectedDataToBeUpdated.cutSheetUrl);
  setFieldValue(
    "partNumber",
    dbStore.selectedDataToBeUpdated.manufacturerPartNumber
  );
  setFieldValue("versionNumber", dbStore.selectedDataToBeUpdated.versionNumber);
  setFieldValue("ownerType", dbStore.selectedDataToBeUpdated.ownerType);
  setFieldValue("costCode", dbStore.selectedDataToBeUpdated.costCode);
  setFieldValue("priceUnit", dbStore.selectedDataToBeUpdated.priceUnit);
  systemSelectedData.value = {};
};

watch(
  () => dbStore.selectedDataToBeUpdated,
  (newValue) => {
    setInitialValue();
  }
);

onMounted(() => {
  setInitialValue();
});

const onSubmit = handleSubmit(handleCreatePart);
</script>
<style scoped lang="css">
.required {
  color: red;
}
</style>
