import axios from "axios";

export const createUserPassword = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: { "Content-Type": "application/json" },
      data,
      method: "post",
      url: "https://estimating.candrelec.com/api/CreateUserPassword",
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
export const registerUser = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: { "Content-Type": "application/json" },
      data: {
        userName: "testDragon",
        emailAddress: "userDragon@example.com",
        password: "Test123!",
        appName: "test",
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/account/register",
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const loginUser = (payload) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: { "Content-Type": "application/json" },
      data: payload,
      method: "post",
      url: "https://estimating.candrelec.com/api/Userlogin",
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const ifUserExist = (email) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: { "Content-Type": "application/json" },
      method: "get",
      url: `https://estimating.candrelec.com/api/IsUserExistsAtSystem?email=${email}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
