<template>
        <div class="custom-modal">
        <div class="custom-modal-content">
          <div class="custom-modal-header">
            <h5>Confirmation</h5>
            <button @click="handleNoModal" class="btn-close">✕</button>
          </div>
          <div class="custom-modal-body text-center">
            <h5>
                Are you sure want to close this?
            </h5>
          </div>
          <div class="custom-modal-footer">
            <button class="btn btn-secondary me-3" @click="handleNoModal">No</button>
            <button class="btn btn-primary" @click=handleYesModal>Yes</button>
          </div>
        </div>
      </div>

      <div class="custom-modal-backdrop"></div>
</template>

<script setup>
import { ref } from "vue";
// const props = defineProps("type");
const emit = defineEmits(["handleNoModal", "handleYesModal"]);

const handleNoModal = () => {
    emit('handleNoModal')
}

const handleYesModal = () => {
    emit('handleYesModal')
}

</script>