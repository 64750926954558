import { defineStore } from "pinia";
import { ref } from "vue";

export const useMoveStore = defineStore("move", () => {
  const isMenuOpen = ref(false);
  const minimized = ref(true);
  const moveMode = ref("model"); //"object" or "part"

  const setIsMenuOpen = (menuState) => {
    isMenuOpen.value = menuState;
  };
  const toggleMinimize = () => {
    minimized.value = !minimized.value;
  };

  const changeMoveMode = (newMode) => {
    moveMode.value = newMode;
  };
  return {
    isMenuOpen,
    minimized,
    moveMode,
    setIsMenuOpen,
    toggleMinimize,
    changeMoveMode,
  };
});
