<template>
  <!--  -->
  <form @submit="onSubmit" id="create-element-form">
    <div class="row">
      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Name
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.name">(required)</span>
        </label>
        <div class="form-input">
          <input
            v-model="newElementName"
            v-bind="nameAttrs"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Version Number
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.versionNumber">(required)</span>
        </label>
        <div class="form-input">
          <input
            v-model="newElementVersionNumber"
            v-bind="versionNumberAttrs"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Size
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.size">(required)</span>
        </label>
        <div class="form-input">
          <input
            v-model="newElementSize"
            v-bind="sizeAttrs"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Inventory Number
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.inventoryNumber">(required)</span>
        </label>
        <input
          v-model="newElementInventoryNumber"
          v-bind="inventoryNumberAttrs"
          class="form-control"
        />
      </div>
    </div>
    <categories-systems
      :staticSelection="true"
      :categoriesData="props.categoriesData"
      :selectedData="systemSelectedData"
      :initialSelectedData="initiaSystemSelectedData"
      @onSelect="
        (newSelectedData) =>
          (systemSelectedData = { ...systemSelectedData, ...newSelectedData })
      "
      @fetchAllCategories="emit('fetchAllCategories')"
      :errors="errors"
      :submitCount="submitCount"
    />
    <div class="row">
      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Description
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.description">(required)</span>
        </label>
        <div class="form-input">
          <textarea
            v-model="newElementDescription"
            v-bind="descriptionAttrs"
            class="form-control"
            rows="2"
          ></textarea>
        </div>
      </div>

      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Notes
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.notes">(required)</span>
        </label>
        <div class="form-input">
          <textarea
            v-model="newElementNotes"
            v-bind="notesAttrs"
            class="form-control"
            rows="2"
          ></textarea>
        </div>
      </div>
      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Part Owner Type

          <sup class="required"> * </sup>
          <span class="required" v-if="errors.ownerType">(required)</span>
        </label>
        <select
          v-model="newElementPartOwnerTypeId"
          v-bind="ownerTypeAttrs"
          class="form-select"
        >
          <option :value="owner.id" v-for="owner in props.ownerTypeData">
            {{ owner.name }}
          </option>
        </select>
      </div>

      <!-- <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Object File Location Url
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.fileUrl">(required)</span>
        </label>
        <input
          v-model="newElementObjectFileLocationUrl"
          v-bind="fileUrlAttrs"
          class="form-control"
        />
      </div> -->
    </div>
  </form>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { createElementService } from "@/services/database";
import CategoriesSystems from "@/components/database/update/categories-systems.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { useForm } from "vee-validate";
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase";

const props = defineProps([
  "ownerTypeData",
  "allPartsData",
  "allElementsData",
  "categoriesData",
]);

const emit = defineEmits(["fetchAllCategories", "onGridReady"]);
const isLoading = ref(false);
const elemetRFA = ref([]);
const initiaSystemSelectedData = ref({});

const newElementParts = ref([]);
const newElementImages = ref([]);
const systemSelectedData = ref({});
const isPrivate = ref("private");

const {
  errors,
  handleSubmit,
  defineField,
  submitCount,
  setFieldError,
  setFieldValue,
  resetForm,
} = useForm({
  validationSchema: {
    name: (value) => (!value ? "field is required" : true),
    versionNumber: (value) => (!value ? "field is required" : true),
    size: (value) => (!value ? "field is required" : true),
    inventoryNumber: (value) => (!value ? "field is required" : true),
    description: (value) => (!value ? "field is required" : true),
    notes: (value) => (!value ? "field is required" : true),
    // fileUrl: (value) => (!value ? "field is required" : true),
    ownerType: (value) => (!value ? "field is required" : true),
    CategoryId: (value) =>
      !systemSelectedData.value.CategoryId ? "field is required" : true,
    SystemId: (value) =>
      !systemSelectedData.value.SystemId ? "field is required" : true,
    SubSystemId: (value) =>
      !systemSelectedData.value.SubSystemId ? "field is required" : true,
    StyleId: (value) =>
      !systemSelectedData.value.StyleId ? "field is required" : true,
  },
});

const [newElementName, nameAttrs] = defineField("name");
const [newElementVersionNumber, versionNumberAttrs] =
  defineField("versionNumber");
const [newElementSize, sizeAttrs] = defineField("size");
const [newElementInventoryNumber, inventoryNumberAttrs] =
  defineField("inventoryNumber");
const [newElementDescription, descriptionAttrs] = defineField("description");
const [newElementNotes, notesAttrs] = defineField("notes");
// const [newElementObjectFileLocationUrl, fileUrlAttrs] = defineField("fileUrl");
const [newElementPartOwnerTypeId, ownerTypeAttrs] = defineField("ownerType");

watch(systemSelectedData, (newValue) => {
  console.log("newValue", newValue);

  setFieldError(
    "CategoryId",
    newValue.CategoryId ? undefined : "field is required"
  );
  setFieldError(
    "SystemId",
    newValue.SystemId ? undefined : "field is required"
  );
  setFieldError(
    "SubSystemId",
    newValue.SubSystemId ? undefined : "field is required"
  );
  setFieldError("StyleId", newValue.StyleId ? undefined : "field is required");
});

const resetFormHandler = () => {
  resetForm();
  systemSelectedData.value = {};
  isPrivate.value = "private";
};

const dbStore = useUpdateDatabaseComponentsStore();

const handleCreateElement = () => {
  const modal = document.getElementById("updateDBModal");

  isLoading.value = true;
  const { CategoryId, SystemId, SubSystemId, StyleId } =
    systemSelectedData.value;
  const getPartIds = newElementParts.value.map((item) => item.id);
  const elementFormData = new FormData();

  elementFormData.append("ElementId", dbStore.selectedDataToBeUpdated.id);
  elementFormData.append(
    "RfaObjectUrn",
    dbStore.selectedDataToBeUpdated.rfaObjectActivityUrn
  );
  elementFormData.append(
    "DwfxObjectUrn",
    dbStore.selectedDataToBeUpdated.dwfxObjectActivityUrn
  );
  elementFormData.append(
    "DwfxTranslatedUrn",
    dbStore.selectedDataToBeUpdated.dwfxObjectTranslatedUrn
  );

  elementFormData.append("Name", newElementName.value);
  elementFormData.append("VersionNumber", newElementVersionNumber.value);
  elementFormData.append("Description", newElementDescription.value);
  elementFormData.append("Size", newElementSize.value);
  elementFormData.append("Notes", newElementNotes.value);
  elementFormData.append("InventoryNumber", newElementInventoryNumber.value);
  elementFormData.append("PartOwnerTypeId", newElementPartOwnerTypeId.value);
  elementFormData.append(
    "ElementVersionId",
    dbStore.selectedDataToBeUpdated.id
  );
  elementFormData.append(
    "ElementId",
    dbStore.selectedDataToBeUpdated.elementId
  );
  // elementFormData.append(
  //   "ObjectFileLocationUrl",
  //   newElementObjectFileLocationUrl.value
  // );
  elementFormData.append("CategoryId", CategoryId);
  elementFormData.append("SystemId", SystemId);
  elementFormData.append("SubSystemId", SubSystemId);
  elementFormData.append("StyleId", StyleId);
  dbStore.selectedDataToBeUpdated.parts.map((item) => {
    elementFormData.append("PartsIds", item.id);
  });
  // for (var x = 0; x < elemetRFA.value.length; x++) {
  //   elementFormData.append("RFAFile", elemetRFA.value[x]);
  // }

  // for (var x = 0; x < newElementImages.value.length; x++) {
  //   elementFormData.append("ElementSymbolPictures", newElementImages.value[x]);
  // }

  // for (var x = 0; x < getPartIds.length; x++) {
  //   elementFormData.append("PartsIds", getPartIds[x]);
  // }

  emit("onSubmit", elementFormData);
  modal.classList.remove("show");
  dbStore.setTargetData({});

  resetFormHandler();
};

const onFileChangeElement = (e) => {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  newElementImages.value = files;
};

const setInitialValue = () => {
  setFieldValue("name", dbStore.selectedDataToBeUpdated.name);
  setFieldValue("versionNumber", dbStore.selectedDataToBeUpdated.versionNumber);
  setFieldValue("size", dbStore.selectedDataToBeUpdated.size);
  setFieldValue(
    "inventoryNumber",
    dbStore.selectedDataToBeUpdated.inventoryNumber
  );

  setFieldValue("description", dbStore.selectedDataToBeUpdated.description);
  setFieldValue("notes", dbStore.selectedDataToBeUpdated.notes);
  setFieldValue("ownerType", dbStore.selectedDataToBeUpdated.partOwnerTypeId);

  systemSelectedData.value = {};
  initiaSystemSelectedData.value = {
    CategoryId: dbStore.selectedDataToBeUpdated.CategoryId,
    SystemId: dbStore.selectedDataToBeUpdated.SystemId,
    SubSystemId: dbStore.selectedDataToBeUpdated.SubSystemId,
    StyleId: dbStore.selectedDataToBeUpdated.StyleId,
  };
};

watch(
  () => dbStore.selectedDataToBeUpdated,
  (newValue) => {
    setInitialValue();
  }
);

onMounted(() => {
  setInitialValue();
});

const onSubmit = handleSubmit(handleCreateElement);
</script>

<style scoped lang="css">
textarea {
  resize: none;
}
.rfa-container {
  margin-top: 20px;
}
.custom-file-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabledRfa {
  cursor: default;
  background-color: #0d6efd;
  border-color: #0d6efd;
  opacity: 0.65;
}
.required {
  color: red;
}
</style>
