<template>
  <!--  -->
  <div v-if="!isAdd">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="mb-0">Elements List</h4>
      <button
        @click="openCloseAdd"
        class="btn btn-primary"
        style="margin-left: auto"
      >
        Add new Element
      </button>
    </div>
    <ag-grid-vue
      :loading="false"
      :defaultColDef="defaultColDef"
      :rowSelection="'multiple'"
      :pagination="true"
      :paginationPageSize="10"
      :paginationPageSizeSelector="[10, 20, 30]"
      :rowData="props.allElementsData"
      :columnDefs="colDefsElement"
      style="height: 600px; width: 100%"
      class="ag-theme-quartz"
      @gridReady="(e) => emit('onGridReady', e)"
    >
    </ag-grid-vue>
  </div>

  <div v-if="isAdd">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="mb-0">Create new element.</h4>
      <public-or-private
        :isPrivate="isPrivate"
        @updateIsPrivate="(newValue) => (isPrivate = newValue)"
      />
    </div>
    <form @submit="onSubmit" id="create-element-form">
      <div class="row">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Name
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.name">(required)</span>
          </label>
          <div class="form-input">
            <input
              v-model="newElementName"
              v-bind="nameAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Version Number
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.versionNumber">(required)</span>
          </label>
          <div class="form-input">
            <input
              v-model="newElementVersionNumber"
              v-bind="versionNumberAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Size
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.size">(required)</span>
          </label>
          <div class="form-input">
            <input
              v-model="newElementSize"
              v-bind="sizeAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Inventory Number
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.inventoryNumber"
              >(required)</span
            >
          </label>
          <input
            v-model="newElementInventoryNumber"
            v-bind="inventoryNumberAttrs"
            class="form-control"
          />
        </div>
      </div>
      <categories-systems
        :categoriesData="props.categoriesData"
        :selectedData="systemSelectedData"
        @onSelect="
          (newSelectedData) =>
            (systemSelectedData = { ...systemSelectedData, ...newSelectedData })
        "
        @fetchAllCategories="emit('fetchAllCategories')"
        :errors="errors"
        :submitCount="submitCount"
      />
      <div class="row">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Description
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.description">(required)</span>
          </label>
          <div class="form-input">
            <textarea
              v-model="newElementDescription"
              v-bind="descriptionAttrs"
              class="form-control"
              rows="2"
            ></textarea>
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Notes
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.notes">(required)</span>
          </label>
          <div class="form-input">
            <textarea
              v-model="newElementNotes"
              v-bind="notesAttrs"
              class="form-control"
              rows="2"
            ></textarea>
          </div>
        </div>
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Part Owner Type

            <sup class="required"> * </sup>
            <span class="required" v-if="errors.ownerType">(required)</span>
          </label>
          <select
            v-model="newElementPartOwnerTypeId"
            v-bind="ownerTypeAttrs"
            class="form-select"
          >
            <option :value="owner.id" v-for="owner in props.ownerTypeData">
              {{ owner.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Object File Location Url
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.fileUrl">(required)</span>
          </label>
          <input
            v-model="newElementObjectFileLocationUrl"
            v-bind="fileUrlAttrs"
            class="form-control"
          />
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Cost Code
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.costCode">(required)</span>
          </label>
          <div class="d-flex">
            <select
              v-model="newPartCostCode"
              v-bind="costCodeAttrs"
              class="form-select"
            >
              <option
                :value="costCode.id"
                v-for="costCode in props.costCodeData"
              >
                {{ costCode.name }}
              </option>
            </select>
            <button
              class="add"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#createCostCodeModal"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-md-6">
        <div class="col-md-6 w-full form-group mb-2 switcherContainer">
          <label class="col-form-label p-0 mb-2">Elements</label>
          <div class="w-100 d-flex">
            <multiselect
              :close-on-select="false"
              :showLabels="false"
              selectLabel=""
              deselectLabel=""
              :preserve-search="true"
              :searchable="true"
              :modelValue="newElementParts"
              tag-placeholder=""
              placeholder="Type to search"
              label="name"
              track-by="id"
              :taggable="false"
              :options="partsOptions"
              :multiple="true"
              @search-change="(search) => fetchOptions(search)"
              @update:modelValue="
                (selectedOption) => handleSelect(selectedOption)
              "
            >
              <template #selection="{ values, search, isOpen }">
                <p style="display: none"></p>
                <span
                  class="multiselect__single"
                  style="
                    background-color: #f5f5f5;
                    font-size: 14px;
                    color: #adadad;
                  "
                  v-if="values.length"
                  v-show="!isOpen"
                  >Type to search</span
                >
              </template>
            </multiselect>

            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
              @click="searchFilterStore.setSearchType('bundle')"
              :class="{
                'search-btn': true,
                active: searchFilterStore.hasFilters,
              }"
            >
              <i class="fa fa-sort-amount-asc" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3 new-part-image">
        <div class="d-flex align-items-center gap-2 rfa-container">
          <label
            style="width: 100%"
            :class="{
              'custom-file-upload btn btn-primary m-0': true,
              disabledRfa: rfaLoader,
            }"
          >
            Upload element RFA
            <input
              @change="onElementRFAChange"
              :disabled="rfaLoader"
              type="file"
              multiple="multiple"
            />
          </label>
          <div class="text-center" v-if="rfaLoader">
            <div
              class="spinner-border spinner-border-sm text-primary"
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        <div v-if="elemetRFA.length > 0">
          {{ elemetRFA.length }} file uploaded.
        </div>
      </div>
      <acc-connect />
    </div>

    <div class="d-flex gap-3 justify-content-end mt-4">
      <div
        class="w-50"
        v-if="elementURNs && elementURNs.length"
        style="width: 100%; height: 550px; margin-bottom: 62px"
      >
        <Viewer
          :isElement="true"
          :viewerRef="viewerRef"
          :URNs="elementURNs"
          @removeUrn="
            (urn) => {
              console.log('remove from elements', urn);
              console.log('remove from elements', elementURNs);
              console.log(
                'remove from elements',
                elementURNs[0].index == urn.index
              );
              elemetRFA = [];
              elementURNs = elementURNs.filter(
                (item) => item.index !== urn.index
              );
            }
          "
          @updateViewer="(viewer) => (viewerRef = viewer)"
        />
      </div>
      <div class="w-50" style="width: 100%; height: 550px; margin-bottom: 62px">
        <Viewer
          :isElement="true"
          :viewerRef="viewerRef"
          :URNs="[]"
          :hideToolbar="true"
        />
      </div>
    </div>

    <parts-table
      :newElementParts="newElementParts"
      @updateElement="
        (newUpdatedElement) => (newElementParts = newUpdatedElement)
      "
    />

    <div class="row d-flex align-items-center">
      <div class="col-md-3 form-group mb-2 new-part-image">
        <label
          style="width: 100%"
          for="file-upload"
          class="custom-file-upload btn btn-primary"
        >
          Click To Upload Images
        </label>
        <input
          id="file-upload"
          @change="onFileChangeElement"
          type="file"
          multiple="multiple"
        />
        <div v-if="newElementImages.length > 0">
          {{ newElementImages.length }} images uploaded.
        </div>
      </div>
      <div class="col-md-3 new-part-image">
        <button
          @click=""
          style="width: 100%"
          class="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#addPartsModal"
        >
          Add New Part
        </button>
      </div>
    </div>

    <div class="d-flex justify-content-end gap-4">
      <button @click="openCloseAdd" class="btn btn-secondary">Cancel</button>
      <button class="btn btn-primary" form="create-element-form">
        Create Element
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { createElementService, elementDbSearch } from "@/services/database";
import partsTable from "./parts-table.vue";
import CategoriesSystems from "@/components/database/shared/categories-systems.vue";
import publicOrPrivate from "@/components/database/shared/public-or-private.vue";
import Viewer from "@/components/database/viewer.vue";
import AccConnect from "@/components/database/elements/acc-connect.vue";
import { toast } from "vue3-toastify";
import Multiselect from "vue-multiselect";

import "vue3-toastify/dist/index.css";
import "vue-multiselect/dist/vue-multiselect.css";

import { useGlobalStore } from "@/pinia/globalStore";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue3"; // Vue Data Grid Component
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase";
import { convertRfaToDwfx } from "@/services/database";
import { useForm } from "vee-validate";
import { useSearchFilterStore } from "@/pinia/searchFilterStore";

const colDefsElement = ref([
  { field: "name", flex: 5 },
  { field: "inventoryNumber" },
  { field: "versionNumber" },
  { field: "size" },
  { field: "notes" },
  {
    field: "actions",
    headerName: "Action",
    cellRenderer: (params) => {
      // Create a unique ID for the button
      const buttonId = `btn-${params.node.id}`;
      // Create the button with the unique ID
      const button = `<div class="pe-2 clickable align-self-center font-16 blue-link underline" data-bs-toggle="modal"
            data-bs-target="#updateDBModal" id="${buttonId}">Edit</div>`;
      // Use a timeout to ensure the button is in the DOM before adding the event listener
      setTimeout(() => {
        document.getElementById(buttonId)?.addEventListener("click", () => {
          handleOpenEditModal(params.data);
        });
      }, 0);
      return button;
    },
  },
]);

const props = defineProps([
  "ownerTypeData",
  "allPartsData",
  "allElementsData",
  "categoriesData",
  "costCodeData",
]);

const emit = defineEmits(["fetchAllCategories", "onGridReady"]);
const isAdd = ref(false);
const isLoading = ref(false);
const elemetRFA = ref([]);

const newElementParts = ref([]);
const newElementImages = ref([]);
const systemSelectedData = ref({});
const isPrivate = ref("private");
const rfaLoader = ref(false);
const globalStore = useGlobalStore();
const dbStore = useUpdateDatabaseComponentsStore();
const elementURNs = ref([]);
const urnResponse = ref({
  DwfxObjectUrn: "",
  DwfxTranslatedUrn: "",
  RfaObjectUrn: "",
});
const debounceTimeout = ref(null);
const viewerRef = ref(null);
const partsOptions = ref([]);

const searchFilterStore = useSearchFilterStore();

const { errors, handleSubmit, defineField, submitCount, setFieldError } =
  useForm({
    validationSchema: {
      name: (value) => (!value ? "field is required" : true),
      versionNumber: (value) => (!value ? "field is required" : true),
      size: (value) => (!value ? "field is required" : true),
      inventoryNumber: (value) => (!value ? "field is required" : true),
      costCode: (value) => (!value ? "field is required" : true),
      description: (value) => (!value ? "field is required" : true),
      notes: (value) => (!value ? "field is required" : true),
      fileUrl: (value) => (!value ? "field is required" : true),
      ownerType: (value) => (!value ? "field is required" : true),
      CategoryId: (value) =>
        !systemSelectedData.value.CategoryId ? "field is required" : true,
      SystemId: (value) =>
        !systemSelectedData.value.SystemId ? "field is required" : true,
      SubSystemId: (value) =>
        !systemSelectedData.value.SubSystemId ? "field is required" : true,
      StyleId: (value) =>
        !systemSelectedData.value.StyleId ? "field is required" : true,
    },
  });

const [newElementName, nameAttrs] = defineField("name");
const [newElementVersionNumber, versionNumberAttrs] =
  defineField("versionNumber");
const [newElementSize, sizeAttrs] = defineField("size");
const [newElementInventoryNumber, inventoryNumberAttrs] =
  defineField("inventoryNumber");
const [newElementDescription, descriptionAttrs] = defineField("description");
const [newElementNotes, notesAttrs] = defineField("notes");
const [newElementObjectFileLocationUrl, fileUrlAttrs] = defineField("fileUrl");
const [newElementPartOwnerTypeId, ownerTypeAttrs] = defineField("ownerType");
const [newPartCostCode, costCodeAttrs] = defineField("costCode");

watch(systemSelectedData, (newValue) => {
  console.log("newValue", newValue);

  setFieldError(
    "CategoryId",
    newValue.CategoryId ? undefined : "field is required"
  );
  setFieldError(
    "SystemId",
    newValue.SystemId ? undefined : "field is required"
  );
  setFieldError(
    "SubSystemId",
    newValue.SubSystemId ? undefined : "field is required"
  );
  setFieldError("StyleId", newValue.StyleId ? undefined : "field is required");
});

const openCloseAdd = () => {
  isAdd.value = !isAdd.value;
  globalStore.isCreateOpen = isAdd.value;
};

const resetFormHandler = () => {
  resetForm();
  systemSelectedData.value = {};
  isPrivate.value = "private";
};

const handleOpenEditModal = (data) => {
  console.log("data is", data);
  dbStore.setTarget("element");
  dbStore.setTargetData(data);
};

const handleCreateElement = () => {
  isLoading.value = true;
  const { CategoryId, SystemId, SubSystemId, StyleId } =
    systemSelectedData.value;
  const getPartIds = newElementParts.value.map((item) => item.id);
  const elementFormData = new FormData();
  elementFormData.append("Name", newElementName.value);
  elementFormData.append("VersionNumber", newElementVersionNumber.value);
  elementFormData.append("Description", newElementDescription.value);
  elementFormData.append("Size", newElementSize.value);
  elementFormData.append("Notes", newElementNotes.value);
  elementFormData.append("InventoryNumber", newElementInventoryNumber.value);
  elementFormData.append("PartOwnerTypeId", newElementPartOwnerTypeId.value);
  elementFormData.append(
    "ObjectFileLocationUrl",
    newElementObjectFileLocationUrl.value
  );
  elementFormData.append("CategoryId", CategoryId);
  elementFormData.append("SystemId", SystemId);
  elementFormData.append("SubSystemId", SubSystemId);
  elementFormData.append("StyleId", StyleId);
  elementFormData.append("isPrivate", isPrivate.value);
  elementFormData.append("CostCodeId", newPartCostCode.value);

  elementFormData.append("dwfxUrn", urnResponse.value.DwfxObjectUrn);
  elementFormData.append(
    "translatedDwfxUrn",
    urnResponse.value.DwfxTranslatedUrn
  );
  elementFormData.append("rfaUrn", urnResponse.value.RfaObjectUrn);

  for (var x = 0; x < elemetRFA.value.length; x++) {
    elementFormData.append("RFAFile", elemetRFA.value[x]);
  }

  for (var x = 0; x < newElementImages.value.length; x++) {
    elementFormData.append("ElementSymbolPictures", newElementImages.value[x]);
  }

  for (var x = 0; x < getPartIds.length; x++) {
    elementFormData.append("PartsIds", getPartIds[x]);
  }

  createElementService(elementFormData)
    .then((res) => {
      fetchAllElements();
      isLoading.value = false;
      toast.success("New Element Created");
      resetFormHandler();
      console.log("res", res);
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
};

const onElementRFAChange = (e) => {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  rfaLoader.value = true;
  elemetRFA.value.push(files[0]);
  // set file in form data
  let formData = new FormData();
  formData.append("RFAFile", files[0]);
  convertRfaToDwfx(formData)
    .then((res) => {
      urnResponse.value.DwfxTranslatedUrn = res.translatedDwfxUrn;
      urnResponse.value.DwfxObjectUrn = res.dwfxUrn;
      urnResponse.value.RfaObjectUrn = res.rfaUrn;
      let newElement = {
        index: elementURNs.value.length + 1,
        urn: res.translatedDwfxUrn,
        elementName: "clamp",
      };
      rfaLoader.value = false;
      elementURNs.value.push(newElement);
    })
    .catch((err) => {
      rfaLoader.value = false;
      console.error(err);
    });
  console.log("elemetRFA", elemetRFA.value);
};

const onFileChangeElement = (e) => {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  newElementImages.value = files;
};

const onSubmit = handleSubmit(handleCreateElement);

const fetchOptions = async (search) => {
  if (debounceTimeout.value) {
    clearTimeout(debounceTimeout.value);
  }
  debounceTimeout.value = setTimeout(() => {
    if (search.length) {
      const selectedFilters = searchFilterStore.selectedFilters;
      const selectedFiltersKeys = Object.keys(selectedFilters);

      const params = new URLSearchParams();
      params.append("Keyword", search);

      if (selectedFiltersKeys.length > 0) {
        selectedFiltersKeys.forEach((key) => {
          if (selectedFilters[key])
            params.append(`${key}s`, selectedFilters[key]);
        });
      }

      elementDbSearch(params)
        .then((response) => {
          partsOptions.value = response.parts;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, 1000);
};

const handleSelect = (selectedOption) => {
  if (selectedOption.length > newElementParts.value.length) {
    newElementParts.value = selectedOption;
  }
};
</script>

<style scoped lang="scss">
textarea {
  resize: none;
}
.rfa-container {
  margin-top: 20px;
}
.custom-file-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disabledRfa {
  cursor: default;
  background-color: #0d6efd;
  border-color: #0d6efd;
  opacity: 0.65;
}
.required {
  color: red;
}
.switcherContainer {
  padding-right: 12px;
}

.search-btn {
  background-color: transparent !important;
  color: black !important;

  &.active {
    background-color: #007bff !important;
    color: white !important;
  }
}
</style>
