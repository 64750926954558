<template>
  <form @submit="onSubmit" id="create-group-form">
    <div class="row">
      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Name
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.name">(required)</span>
        </label>
        <div class="form-input">
          <input
            v-model="newGroupName"
            v-bind="nameAttrs"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Size
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.size">(required)</span>
        </label>
        <div class="form-input">
          <input
            v-model="newGroupSize"
            v-bind="sizeAttrs"
            class="form-control"
          />
        </div>
      </div>

      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Part Owner Type
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.ownerType">(required)</span>
        </label>
        <select
          v-model="newGroupPartOwnerTypeId"
          v-bind="ownerTypeAttrs"
          class="form-select"
        >
          <option :value="owner.id" v-for="owner in props.ownerTypeData">
            {{ owner.name }}
          </option>
        </select>
      </div>
    </div>
    <categories-systems
      :hideAddButtons="true"
      :staticSelection="true"
      :categoriesData="props.categoriesData"
      :selectedData="systemSelectedData"
      @onSelect="
        (newSelectedData) =>
          (systemSelectedData = { ...systemSelectedData, ...newSelectedData })
      "
      @fetchAllCategories="emit('fetchAllCategories')"
      :errors="errors"
      :submitCount="submitCount"
    />
    <div class="row">
      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Description
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.description">(required)</span>
        </label>
        <div class="form-input">
          <textarea
            v-model="newGroupDescription"
            v-bind="descriptionAttrs"
            class="form-control"
            rows="2"
          ></textarea>
        </div>
      </div>

      <div class="col-md-3 form-group mb-2">
        <label class="col-form-label"
          >Notes
          <sup class="required"> * </sup>
          <span class="required" v-if="errors.notes">(required)</span>
        </label>
        <div class="form-input">
          <textarea
            v-model="newGroupNotes"
            v-bind="notesAttrs"
            class="form-control"
            rows="2"
          ></textarea>
        </div>
      </div>
    </div>
  </form>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import CategoriesSystems from "@/components/database/shared/categories-systems.vue";

import { useForm } from "vee-validate";

import "vue3-toastify/dist/index.css";
import "vue-multiselect/dist/vue-multiselect.css";
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase";

const props = defineProps(["ownerTypeData", "categoriesData"]);

const emit = defineEmits(["onSubmit"]);

const systemSelectedData = ref({});

const isLoading = ref(false);

const dbStore = useUpdateDatabaseComponentsStore();

const {
  errors,
  handleSubmit,
  defineField,
  submitCount,
  setFieldError,
  resetForm,
  setFieldValue,
} = useForm({
  validationSchema: {
    name: (value) => (!value ? "field is required" : true),
    size: (value) => (!value ? "field is required" : true),
    description: (value) => (!value ? "field is required" : true),
    notes: (value) => (!value ? "field is required" : true),
    ownerType: (value) => (!value ? "field is required" : true),

    CategoryId: (value) =>
      !systemSelectedData.value.CategoryId ? "field is required" : true,
    SystemId: (value) =>
      !systemSelectedData.value.SystemId ? "field is required" : true,
    SubSystemId: (value) =>
      !systemSelectedData.value.SubSystemId ? "field is required" : true,
    StyleId: (value) =>
      !systemSelectedData.value.StyleId ? "field is required" : true,
  },
});

const [newGroupName, nameAttrs] = defineField("name");

const [newGroupSize, sizeAttrs] = defineField("size");

const [newGroupDescription, descriptionAttrs] = defineField("description");
const [newGroupNotes, notesAttrs] = defineField("notes");

const [newGroupPartOwnerTypeId, ownerTypeAttrs] = defineField("ownerType");

watch(systemSelectedData, (newValue) => {
  console.log("newValue", newValue);

  setFieldError(
    "CategoryId",
    newValue.CategoryId ? undefined : "field is required"
  );
  setFieldError(
    "SystemId",
    newValue.SystemId ? undefined : "field is required"
  );
  setFieldError(
    "SubSystemId",
    newValue.SubSystemId ? undefined : "field is required"
  );
  setFieldError("StyleId", newValue.StyleId ? undefined : "field is required");
});

const resetFormHandler = () => {
  resetForm();
  systemSelectedData.value = {};
};

const handleCreateGroup = () => {
  const modal = document.getElementById("updateDBModal");

  isLoading.value = true;
  const groupFormData = new FormData();
  const { CategoryId, SystemId, SubSystemId, StyleId } =
    systemSelectedData.value;

  // fill BundleElement in case of positioning.

  // wrap this with if condition if not viewer.
  // if (props.selectedGroupOption.value == "rfa")
  //   for (var x = 0; x < groupRFA.value.length; x++) {
  //     elementFormData.append("RFAFile", groupRFA.value[x]);
  //   }

  groupFormData.append("Name", newGroupName.value);
  groupFormData.append("Description", newGroupDescription.value);
  groupFormData.append("Size", newGroupSize.value);
  groupFormData.append(
    "VersionNumber",
    dbStore.selectedDataToBeUpdated.versionNumber
  );
  groupFormData.append("MainGroupId", dbStore.selectedDataToBeUpdated.groupId);
  groupFormData.append("Notes", newGroupNotes.value);
  groupFormData.append("PartOwnerTypeId", newGroupPartOwnerTypeId.value);
  groupFormData.append("CategoryId", CategoryId);
  groupFormData.append("SystemId", SystemId);
  groupFormData.append("SubSystemId", SubSystemId);
  groupFormData.append("StyleId", StyleId);
  emit("onSubmit", groupFormData);
  dbStore.setTargetData({});
  modal.classList.remove("show");
  resetFormHandler();
};

const setInitialValue = () => {
  setFieldValue("name", dbStore.selectedDataToBeUpdated.name);
  setFieldValue("size", dbStore.selectedDataToBeUpdated.size);

  setFieldValue("description", dbStore.selectedDataToBeUpdated.description);
  setFieldValue("notes", dbStore.selectedDataToBeUpdated.notes);
  setFieldValue("ownerType", dbStore.selectedDataToBeUpdated.partOwnerTypeId);

  systemSelectedData.value = {};
};

watch(
  () => dbStore.selectedDataToBeUpdated,
  (newValue) => {
    setInitialValue();
  }
);

onMounted(() => {
  setInitialValue();
});

const onSubmit = handleSubmit(handleCreateGroup);
</script>
<style scoped lang="css">
textarea {
  resize: none;
}

.counterRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.increase {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: transparent;
  color: black;
}
.increase:hover {
  background-color: #ccc;
  color: white;
  border-color: #007bff;
}
.switcherContainer {
  padding-right: 12px;
}
.required {
  color: red;
}
</style>
