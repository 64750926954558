<template>
  <!--  -->
  <div
    :id="props.id"
    style="top: 10px; right: 10px"
    draggable="true"
    @dragstart.self="
      (e) => {
        dragStart(e, props.id);
      }
    "
    :class="{
      'favorit-list': true,
      active: props.isOpen,
      minimized: props.isMinimized,
    }"
  >
    <div class="header">
      <p
        @click="
          () => {
            emit('toggleMinimize');
          }
        "
      >
        <span :class="{ arrow: true, rotate: !props.isMinimized }">
          <img src="@/assets/svg/rightArrow.svg" alt="" />
        </span>
        {{ props.title }}
      </p>
    </div>
    <div class="draggable-body" @drop.stop>
      <slot />
    </div>
  </div>
</template>

<script setup>
const props = defineProps(["id", "title", "isOpen", "isMinimized"]);
const emit = defineEmits(["toggleMinimize"]);

const dragStart = (e, id) => {
  console.log(e);
  e.dataTransfer.dropEffect = "move";
  e.dataTransfer.effectAllowed = "move";
  e.dataTransfer.setData("id", id);
  e.dataTransfer.setData("clientX", e.clientX);
  e.dataTransfer.setData("clientY", e.clientY);
};
</script>

<style scoped lang="scss">
.favorit-list {
  width: 300px;
  display: none;
  flex-direction: column;
  gap: 12px;
  position: absolute;
  background-color: white;
  border-radius: 4px;
  padding: 12px;
  z-index: 100;
  max-height: 300px;
  overflow-y: auto;
  transition-property: max-height;
  transition-duration: 300ms;
  transform-origin: top;
  color: black;

  &.active {
    display: flex;
  }
  &.minimized {
    max-height: 48px;
    overflow: hidden;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      gap: 8px;
      & .arrow {
        width: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        filter: invert(1);
        transform: rotate(90deg);
        transition-duration: 300ms;

        &.rotate {
          transform: rotate(270deg);
        }
      }
    }
  }

  .body-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &.minimized {
    }
  }
}

.increase {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: transparent;
  color: black;
}
.increase:hover {
  background-color: #ccc;
  color: white;
  border-color: #007bff;
}

.input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & input {
    width: 90%;
    padding: 2px 8px;
  }
}

.draggable-body {
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
