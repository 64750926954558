<template>
  <div class="custom-switch">
    <span
      @click="emit('updateIsPrivate', 'public')"
      :class="props.isPrivate === 'public' ? 'active' : ''"
      >Public</span
    >
    <span
      @click="emit('updateIsPrivate', 'private')"
      :class="props.isPrivate === 'private' ? 'active' : ''"
      >Private</span
    >
  </div>
</template>

<script setup>
const props = defineProps(["isPrivate"]);
const emit = defineEmits(["updateIsPrivate"]);
</script>