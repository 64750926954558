<template>
  <!--  -->
  <DraggableMenu
    id="move-settings"
    :isOpen="moveStore.isMenuOpen"
    :isMinimized="moveStore.minimized"
    :title="'Move settings'"
    @toggleMinimize="moveStore.toggleMinimize"
  >
    <div class="settings-body">
      <div class="setting-option">
        <p>Translation Mode:</p>
        <div class="custom-switch">
          <span
            @click="changeMoveModeHandler('model')"
            :class="{ active: moveStore.moveMode === 'model' }"
            >Model</span
          >
          <span
            @click="changeMoveModeHandler('part')"
            :class="{ active: moveStore.moveMode === 'part' }"
            >Part</span
          >
        </div>
      </div>
    </div>
  </DraggableMenu>
</template>

<script setup>
import DraggableMenu from "@/components/shared/dragable-menu.vue";
import { useMoveStore } from "@/pinia/moveStore";
import { onMove } from "../database/utils";

const props = defineProps(["viewer"]);

const moveStore = useMoveStore();

const changeMoveModeHandler = (mode) => {
  moveStore.changeMoveMode(mode);
  onMove(props.viewer, mode);
};
</script>

<style scoped lang="scss">
.settings-body {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  & .setting-option {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    & p {
      font-size: 12px;
      margin: 0;
    }
  }
}
.custom-switch {
  padding: 2px;
  display: flex;
  align-items: center;
  margin: 0;
  & span {
    font-size: 10px;
    padding: 3px 12px;
  }
}
</style>
