<template>
  <viewer
    :elementsCount="elementsCount"
    @addModelToViewer="handleAddModel"
    :elements="elements"
    :bundles="bundles"
    :groups="groups"
    :viewerRef="viewerRef"
    @updateViewer="(viewer) => (viewerRef = viewer)"
  />
</template>

<script setup>
import viewer from "./viewer.vue";
import { ref } from "vue";

const viewerRef = ref(null);
const elementsCount = ref(2);

const initialElement = {
  id: 1,
  name: "Element3",
  objectXPosition: 88.25457193311354,
  objectYPosition: 68.70127273589605,
  objectZPosition: -13.167288602999374,
  order: 1,
  translatedUrn:
    "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6d2lwLmRtLnByb2QvMjc4YTAwNmUtMzVhMC00Yjk0LTllNjMtNGMxNjZiMWE2MTViLmR3Zng=",
  type: "Element",
  uniqueId: 1,
};

const elements = ref([initialElement]);
const bundles = ref([]);
const groups = ref([]);

const addElement = (element) => {
  let newElements = [...elements.value];
  // add uniqueId to the element
  let newElement = {
    ...element,
    uniqueId: elementsCount.value,
  };
  newElements.push(newElement);
  elements.value = newElements;
  elementsCount.value++;
};

const addBundle = (bundle) => {
  debugger;
  let newBundles = [...bundles.value];
  let newBundle = { ...bundle };
  // add the uniqueId to each element in the bundle
  newBundle.budleElements = newBundle.budleElements.map((element) => {
    const newElement = { ...element };
    newElement.uniqueId = elementsCount.value;
    elementsCount.value++;
    return newElement;
  });
  newBundles.push(newBundle);
  bundles.value = newBundles;
};

const addGroup = (group) => {
  let newGroups = [...groups.value];
  let newGroup = { ...group };

  newGroup.groupBundles.map((bundle) => {
    bundle.budleElements = bundle.budleElements.map((element) => {
      const newElement = { ...element };
      newElement.uniqueId = elementsCount.value;
      elementsCount.value++;
      return newElement;
    });
  });

  newGroups.push(newGroup);
  groups.value = newGroups;
};

const handleAddModel = (obj) => {
  console.log(obj, "handleAddModel");

  switch (obj.type) {
    case "Element":
      addElement(obj);
      break;
    case "Bundle":
      addBundle(obj);
      break;
    case "Group":
      addGroup(obj);
      break;
  }
};
</script>
