<template>
    <div class="mx-3">
    <BurdenCategory
    v-if="props.selectedChildLookup === 'burdenCategory'"
    />

    <Burden
    v-if="props.selectedChildLookup === 'burden'"
    />

    <LaborDivision
    v-if="props.selectedChildLookup === 'laborDivision'"
     />

    <JobTitle
    v-if="props.selectedChildLookup === 'jobTitle'"
    />

    <LaborAssignment
    v-if="props.selectedChildLookup === 'laborAssignment'"
    />
    </div>

</template>

<script setup>
import BurdenCategory from "@/components/database/laborDatabase/burdenCategory/index.vue";
import Burden from "@/components/database/laborDatabase/burden/index.vue";
import LaborDivision from "@/components/database/laborDatabase/laborDivision/index.vue";
import JobTitle from "@/components/database/laborDatabase/jobTitle/index.vue";
import LaborAssignment from "@/components/database/laborDatabase/laborAssignment/index.vue";

const props = defineProps([
  "selectedChildLookup",
]);

</script>
