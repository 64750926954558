<template>
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-xl-7 b-center bg-size"
        :style="{
          backgroundImage:
            'url(' + require('../../assets/images/login/2.jpg') + ')',
        }"
        style="
          background-size: cover;
          background-position: center center;
          display: block;
        "
      >
        <img
          class="bg-img-cover bg-center"
          src="../../assets/images/login/2.jpg"
          alt="looginpage"
          style="display: none"
        />
      </div>
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div class="login-main">
              <form @submit="onSubmit" class="theme-form">
                <h4>Sign in to account</h4>
                <p class="login-note">Enter your email & password to login</p>
                <div class="form-group">
                  <label class="col-form-label">Email Address</label>
                  <input
                    v-model="username"
                    v-bind="usernameAttrs"
                    class="form-control"
                    type="email"
                  />
                  <p v-if="errors.username && submitCount" class="error">
                    Please enter your email
                  </p>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <div class="form-input position-relative">
                    <input
                      class="form-control"
                      :type="showPassword ? 'text' : 'password'"
                      v-model="password"
                      v-bind="passwordAttrs"
                    />
                    <div class="show-hide">
                      <span
                        :class="showPassword ? 'show' : 'hide'"
                        @click="toggleShowPassword"
                      ></span>
                    </div>
                  </div>
                  <p v-if="errors.password && submitCount" class="error">
                    Please enter your password
                  </p>
                </div>
                <div class="form-group mb-0">
                  <button
                    :disabled="isLoading"
                    class="btn btn-primary btn-block w-100 submit-button"
                  >
                    <span v-if="!isLoading">Sign in</span>
                    <output
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </output>
                  </button>
                </div>

                <div class="mt-4">
                  <a class="btn btn-secondary w-100" href="https://developer.api.autodesk.com/authentication/v2/authorize?response_type=code&client_id=1KT4A9fwCwdfkhL9ksQv1aqEwJ3ScnEq&redirect_uri=https://localhost:8080/api/auth/callback&scope=data:read data:write">Connect to ACC</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useAuthStore } from "@/pinia/authStore";
import { getAccessToken, getThreeLeggedToken } from "@/services/autodesk";
import { loginUser } from "@/services/auth";
import { useForm } from "vee-validate";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { toast } from "vue3-toastify";

const showPassword = ref(false);
const isLoading = ref(false);
const { errors, handleSubmit, defineField, submitCount } = useForm({
  validationSchema: {
    username: (value) => (!value ? "field is required" : true),
    password: (value) => (!value ? "field is required" : true),
  },
});

const [username, usernameAttrs] = defineField("username");
const [password, passwordAttrs] = defineField("password");
const authStore = useAuthStore();
const router = useRouter();

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};

const onSubmit = handleSubmit((values) => {
  isLoading.value = true;

  loginUser(values)
    .then((res) => {
      fetchAccessToken()
      // fetch3LegToken()
      authStore.setToken(res.token);
      authStore.setUserData(res.user);
      isLoading.value = false;
      router.push('/')
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error('Something went wrong');
      console.log(err);
    });
});

const fetchAccessToken = () => {
  getAccessToken()
    .then((res) => {
      localStorage.setItem("access_token", res.access_token);
      // accessToken.value = res.access_token
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

</script>

<style scoped lang="scss">
.error {
  color: red !important;
  font-size: 12px !important;
  margin: 4px 0 !important;
}
.login-note {
  color: $white-color !important;
}
.submit-button {
  margin-top: 16px !important;
  // background-color: $white-color !important;
  // color: $dark-black-color !important;
}
.submit-button:disabled {
  background-color: $white-color !important;
}

.login-card {
  background: $dark-black-color !important;
}

.login-main {
  background-color: $regular-black-color !important;
  color: $white-color !important;
}


.show-hide span {
  color: #f3f4f6 !important;
}
</style>
