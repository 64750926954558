<template>
    <div v-if="isLoading" class="overlay-loader"></div>
    <div class="d-flex justify-content-between align-items-center mb-2 me-3">
      <h4 class="mb-0">Burden</h4>
      <button
        @click="openAddBurden"
        class="btn btn-primary"
        style="margin-left: auto"
      >
        Add new Burden
      </button>
    </div>
  
    <div v-if="!createBurdenModal" class="mt-4">
      <ag-grid-vue
        :loading="false"
        :defaultColDef="defaultColDef"
        :pagination="false"
        :paginationPageSize="10"
        :paginationPageSizeSelector="[10, 20, 30]"
        :rowData="allBurden"
        :columnDefs="colDefs"
        style="height: 600px; width: 100%"
        @grid-ready="gridReady"
        class="ag-theme-quartz"
      >
      </ag-grid-vue>
    </div>
  
    <div v-else class="mt-4">
      <div class="">
        <div class="">
          <h5>Add new Burden</h5>
        </div>
        <div class="">
          <div class="row">
            <div class="col-md-6 form-group mb-3">
              <label class="col-form-label">Name</label>
              <div class="form-input">
                <input v-model="burdenName" class="form-control" />
              </div>
            </div>  
          </div>

          <div class="row">
            <div class="col-md-6 form-group mb-3">
              <label class="col-form-label">Select Categories</label>
              <div class="form-input">
                <select
                v-model="selectedCatID"
                @change="handleSelect"
                class="form-select">
            <option value="" disabled selected>Select</option>
            <option :value="category" v-for="category in allBurdenCategories">{{
            category.name }}</option>
        </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
                <div class="d-flex bg-alice mb-2 p-2 x-between"
                v-for="selectedCategory in burdenCategories"
                :key="selectedCategory.id"
                >
                <span> {{ selectedCategory.name }}</span>
                <span
                @click="deleteSelectedCategory(selectedCategory.id)"
                class="icon-trash pe-2 clickable align-self-center red-color"></span>

            </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mb-4 mt-4">
          <button
            v-if="isUpdate"
            class="btn btn-secondary me-3"
            @click="handleDeleteBurden"
          >
            Delete
          </button>
          <button
          class="btn btn-secondary me-3" @click="closeModal">
            Close
          </button>
          <button
          v-if="isUpdate"
          class="btn btn-primary" @click="handleUpdateBurden()">
           Update
          </button>
          <button 
          v-else
          class="btn btn-primary" @click="handleSaveBurden()">
            Save changes
          </button>
  
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, watch } from "vue";
  import {
    createBurden,
    getAllBurden,
    deleteBurden,
    updateBurden,
    getAllBurdenCategories
  } from "@/services/database";
  
  import { toast } from "vue3-toastify";
  import "vue3-toastify/dist/index.css";
  
  import Multiselect from "vue-multiselect";
  import "vue-multiselect/dist/vue-multiselect.css";

  import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
  import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
  import { AgGridVue } from "ag-grid-vue3"; // Vue Data Grid Component
  
  const createBurdenModal = ref(false);
  
  const burdenName = ref("");
  const burdenCategories = ref([]);
  const selectedCatID = ref("")
  
  const isLoading = ref(false);
  const isUpdate = ref(false);
  const allBurdenCategories = ref([]);
  const allBurden = ref([]);
  const selectedBurden = ref("");
  
  const colDefs = ref([
    { field: "name", flex: 3 },
    { field: "adder" },
    { field: "multiplier" },
    {
      field: "actions",
      headerName: "Action",
      cellRenderer: (params) => {
        // Create a unique ID for the button
        const buttonId = `btn-bur-${params.node.id}`;
        // Create the button with the unique ID
        const button = `<div class="pe-2 clickable align-self-center font-16 blue-link underline" id="${buttonId}">View</div>`;
        // Use a timeout to ensure the button is in the DOM before adding the event listener
        setTimeout(() => {
          document.getElementById(buttonId)?.addEventListener("click", () => {
            viewBurden(params.data);
          });
        }, 0);
        return button;
      },
    },
  ]);
    
  onMounted(() => {
    fetchAllBurdenCategories();
    fetchAllBurden();
  });

  const clearValues = () => {
    burdenName.value = ""
    burdenCategories.value = []
  }

  
  const handleSaveBurden = () => {
    isLoading.value = true;
    const burdenPayload = {
      name: burdenName.value,
      BurdenCategoriesIds: burdenCategories.value.map(item => item.id.toString()),
    };
    createBurden(burdenPayload)
      .then((res) => {
        fetchAllBurden()
        isLoading.value = false;
        closeModal();
        toast.success("Burden Created");
      })
      .catch((err) => {
        isLoading.value = false;
        toast.error("Something Went Wrong");
        console.error(err);
      });
  };

  const handleSelect = () => {
    if (selectedCatID.value && !burdenCategories.value.includes(selectedCatID.value)) {
        burdenCategories.value.push(selectedCatID.value);
      }
};

const deleteSelectedCategory = (id) => {
     burdenCategories.value =  burdenCategories.value.filter(item => item.id !== id);
};
  
  
  const handleUpdateBurden = () => {
    isLoading.value = true;
    const burdenPayload = {
      name: burdenName.value,
      BurdenCategoriesIds: burdenCategories.value.map(item => item.id.toString()),
    };
    updateBurden(burdenPayload, selectedBurden.value)
      .then((res) => {
        fetchAllBurden();
        isLoading.value = false;
        closeModal();
        clearValues()
        toast.success("Burden Updated");
      })
      .catch((err) => {
        isLoading.value = false;
        toast.error("Something Went Wrong");
        console.error(err);
      });
  };
  
  const handleDeleteBurden = () => {
    isLoading.value = true;
    deleteBurden(selectedBurden.value)
      .then((res) => {
        fetchAllBurden()
        isLoading.value = false;
        closeModal();
      })
      .catch((err) => {
        isLoading.value = false;
        console.error(err);
      });
  };
  
  const fetchAllBurdenCategories = () => {
    isLoading.value = true;
    getAllBurdenCategories()
      .then((res) => {
        allBurdenCategories.value = res;
        isLoading.value = false;
      })
      .catch((err) => {
        isLoading.value = false;
        console.error(err);
      });
  };

  const fetchAllBurden = () => {
    isLoading.value = true;
    getAllBurden()
      .then((res) => {
        allBurden.value = res;
        isLoading.value = false;
      })
      .catch((err) => {
        isLoading.value = false;
        console.error(err);
      });
  };
  
  const viewBurden = (param) => {
    selectedBurden.value = param.id
    isUpdate.value = true;
    burdenName.value = param.name;
    burdenCategories.value = param.burdenCategories
    createBurdenModal.value = true;
  };

  
  const closeModal = () => {
    isUpdate.value = false;
    createBurdenModal.value = false;
  };
  
  const openAddBurden = () => {
    createBurdenModal.value = true;
  };
  </script>
  

  // Make modal into forms
  // Multiselect shouyld be a dropdown and list should display the selection