<template>
  <div class="tab-container" v-if="canManageCompany && !canManageAllCompanies">
    <div v-if="isCreate == 'list'">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <h4 class="mb-0">Company details</h4>
      </div>
      <div class="table-container">
        <table v-if="!companyStore.isLoading" class="table table-bordered">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col">Name</th>
              <th scope="col">Phone number</th>
              <th scope="col">Address</th>
              <th scope="col">Email</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <template
              v-for="(company, index) in companyStore.companies"
              :key="company.id"
            >
              <tr
                @click="openAccordion(company, index)"
                :class="{
                  clickableRow: company.users.length,
                }"
              >
                <td class="arrow-bar">
                  <button
                    class="arrowBtn"
                    :disabled="!company.users.length || !company.isActive"
                  >
                    <img src="@/assets/svg/rightArrow.svg" alt="" />
                  </button>
                </td>
                <td class="checkRow">
                  {{ company.name }}
                </td>
                <td>
                  {{ company.phone }}
                </td>
                <td>{{ company.address }}</td>
                <td>{{ company.email }}</td>
                <td class="actionsContainer">
                  <div class="actions">
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#addUserToCompanyModal"
                      class="accordion-toggle"
                      @click.stop="setUpdatedCompany(company, index)"
                    >
                      Add user
                    </button>
                  </div>
                </td>
              </tr>
              <tr v-if="company.users.length">
                <td class="p-0 last"></td>
                <td colspan="12" class="p-0">
                  <div
                    class="accordion-collapse collapse"
                    :id="`users${company.id}`"
                  >
                    <UsersTable
                      :users="company.users"
                      :company="company"
                      @setUpdatedCompany="setUpdatedCompany"
                      @setUpdateUser="setUpdateUser"
                    />
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div class="table-loader" v-if="companyStore.isLoading">
          <p class="placeholder-glow">
            <span class="placeholder col-12"></span>
          </p>
        </div>
        <!-- table here -->
      </div>
    </div>

    <AddUser :updatedCompany="updatedCompany" :isCompanyDetails="true" />
    <UpdateUser
      :updatedCompany="updatedCompany"
      :userData="updateUser"
      :isCompanyDetails="true"
    />
    <DeleteUser
      :updatedCompany="updatedCompany"
      :updateUser="updateUser"
      :isCompanyDetails="true"
    />
  </div>
</template>

<script setup>
import { onBeforeMount, ref } from "vue";

import { Collapse } from "bootstrap/dist/js/bootstrap.bundle";

import "vue-multiselect/dist/vue-multiselect.css";

import { useAuthStore } from "@/pinia/authStore";
import UsersTable from "@/components/companies/company-users/users-table.vue";
import { useCompanyStore } from "@/pinia/companyStore";
import AddUser from "@/components/companies/company-users/add-user.vue";
import DeleteUser from "@/components/companies/company-users/delete-user.vue";
import UpdateUser from "@/components/companies/company-users/update-user.vue";

const companyStore = useCompanyStore();
const updatedCompany = ref({});
const updateUser = ref({});
const authStore = useAuthStore();
const userPremissions = authStore.userData.value.permissions;
const canManageAllCompanies = userPremissions.includes("CompaniesManagement");
const canManageCompany = userPremissions.includes("UsersManagement");

// extract submit to submit the form from a button

const isCreate = ref("list"); //list || create || update;

onBeforeMount(() => {
  if (!companyStore.companies.length) {
    companyStore.fetchCompany(authStore.userData.value.companyId);
  }
  if (!companyStore.userRoles.length) {
    companyStore.fetchRoles();
  }
});

const setUpdateUser = (user) => {
  updateUser.value = user;
};
const setUpdatedCompany = (company) => {
  updatedCompany.value = company;
};

const openAccordion = (company, ind) => {
  if (!company.users.length) return;
  const accordion = document.getElementById(`users${company.id}`);
  const isOpen = accordion.classList.contains("show");
  toggleArrow(ind, isOpen);
  showBar(ind, isOpen);
  const bsCollapse = new Collapse(accordion);
  bsCollapse.toggle();
};

const toggleArrow = (index, isOpen) => {
  const arrowBtns = document.querySelectorAll(".arrowBtn");
  if (isOpen) {
    arrowBtns[index].classList.remove("rotate");
    return "+";
  } else {
    arrowBtns[index].classList.add("rotate");
    return "-";
  }
};

const showBar = (index, isOpen) => {
  const arrowBar = document.querySelectorAll(".arrow-bar");
  if (isOpen) {
    arrowBar[index].classList.remove("show");
  } else {
    arrowBar[index].classList.add("show");
  }
};
</script>
<style scoped lang="scss">
.tab-container {
  padding: 24px;
  padding-left: 0;
}
.table-loader {
  width: 100%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;

  & p {
    width: 100%;
    height: 100%;

    & span {
      width: 100%;
      height: 100%;
      background-color: $light-black-color;
    }
  }
}
.actionsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  height: 59px;
}

.arrowBtn {
  background-color: transparent;
  transition-duration: 300ms;
  transform: rotate(-180deg);
  i {
    color: gray;
  }

  &.rotate {
    transform: rotate(-270deg);
  }
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 24px;
}
.actionsContainer .actions button {
  background: #227093;
  color: white;
  padding: 1px 6px;
  border-radius: 5px;
  font-size: 12px;
}
.actionsContainer .actions button:disabled {
  opacity: 0.5;
  cursor: default;
}

.table-container {
  width: 100%;
  overflow-y: auto;
  position: relative;
}
table td,
table th {
  // color: white !important;
  border: none !important;
  vertical-align: middle;
}
table th,
table td {
  width: 20%;
}
table th:first-child,
table td:first-child {
  width: 60px;
}
thead tr {
  border: none !important;
}
thead tr th {
  // background-color: $dark-black-color !important;
}
tbody tr {
  border-color: transparent !important;
  border-bottom: 1px solid $border-color !important;
  &:last-child {
    border-bottom: none !important;
  }
}
tbody tr td {
  // background-color: $dark-black-color !important;
  font-size: 12px !important;
}
.clickableRow {
  cursor: pointer;
}
.companyStateBtn {
  width: 75px;
}

.last {
  background-color: transparent !important;
}

.arrow-bar {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 101%;
    left: 50%;
    width: 2px;
    height: 42.5px;
    transform: translate(-77%, 0) scaleY(0);
    background-color: gray;
    z-index: 1;
    transition-duration: 0ms;
    transform-origin: top;
  }
  &::before {
    content: "";
    position: absolute;
    top: calc(100% + 42.5px);
    left: 50%;
    width: 29px;
    height: 2px;
    transform: translate(-5%, 0) scaleX(0);
    background-color: gray;
    z-index: 1;
    transition-duration: 0ms;
    transition-delay: 0ms;
    transform-origin: left;
  }

  &.show {
    &::after {
      transform: translate(-77%, 0) scaleY(1);
      transition-duration: 300ms;
    }
    &::before {
      transition-duration: 200ms;
      transform: translate(-5%, 0) scaleX(1);
      transition-delay: 300ms;
    }
  }
}
</style>
