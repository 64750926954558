<template>
    <div v-if="isLoading" class="overlay-loader"></div>
    <div class="d-flex justify-content-between align-items-center mb-2 me-3">
      <h4 class="mb-0">Labor Assignment</h4>
      <button
        @click="openAddLaborAssignment"
        class="btn btn-primary"
        style="margin-left: auto"
      >
        Add new Labor Assignment
      </button>
    </div>
  
    <div v-if="!createLaborAssignmentModal" class="mt-4">
      <ag-grid-vue
        :loading="false"
        :defaultColDef="defaultColDef"
        :pagination="false"
        :paginationPageSize="10"
        :paginationPageSizeSelector="[10, 20, 30]"
        :rowData="allLaborAssignment"
        :columnDefs="colDefs"
        style="height: 600px; width: 100%"
        @grid-ready="gridReady"
        class="ag-theme-quartz"
      >
      </ag-grid-vue>
    </div>
  
    <div v-else class="mt-4">
      <div class="">
        <div class="">
          <h5>Add new Labor Assignment</h5>
        </div>
        <div class="">
          <div class="row">
            <div class="col-md-6 form-group mb-3">
            <label class="col-form-label">Select Bidding Package</label>
            <div class="form-input">
              <select v-model="selectedBiddingPackage" class="form-select">
                <option value="" disabled selected>Select</option>
                <option :value="biddingPackage.id" v-for="biddingPackage in allBiddingPackages">
                  {{ biddingPackage.name }}
                </option>
              </select>
            </div>
          </div>

            <div class="col-md-6 form-group mb-3">
            <label class="col-form-label">Select Job Title</label>
            <div class="form-input">
              <select
              @change="handleSelect"
              v-model="selectedJobTitle" class="form-select">
                <option value="" disabled selected>Select</option>
                <option :value="jobTitle" v-for="jobTitle in allJobTitles">
                  {{ jobTitle.name }}
                </option>
              </select>
            </div>
          </div>
          <div
          v-for="(selectedJobTitleValue, index) in selectedJobTitleList"
          :key="selectedJobTitleValue.id"
          class="row">
            <div
            v-if="selectedJobTitleValue.name"
             class="row">
                <div class="">
                    <div class="d-flex mb-2 p-2 align-items-center"
                >
                <span
                @click="deleteSelectedJobTitleValue(selectedJobTitleValue.id)"
                class="icon-trash pe-2 clickable align-self-center red-color mt-4"></span>
                <div class="col-md-4 mx-5">
                    <label class="col-form-label">Order Index</label>
              <div class="form-input">
                <input v-model="selectedJobTitleList[index].orderIndex" class="form-control" />
              </div>
                </div>
                <span class="mt-4"> {{ selectedJobTitleValue.name }}</span>

            </div>
                </div>
                <!-- <div class="col-md-4">
                    <label class="col-form-label">Order Index</label>
              <div class="form-input">
                <input v-model="selectedJobTitleList[index].orderIndex" class="form-control" />
              </div>
                </div> -->
            </div>

            </div>


          </div>
        </div>
        <div class="d-flex justify-content-end mb-4 mt-4">
          <button
            v-if="isUpdate"
            class="btn btn-secondary me-3"
            @click="handleDeleteLaborAssignment"
          >
            Delete
          </button>
          <button
          class="btn btn-secondary me-3" @click="closeModal">
            Close
          </button>
          <button
          v-if="isUpdate"
          class="btn btn-primary" @click="handleUpdateLaborAssignment()">
           Update
          </button>
          <button 
          v-else
          class="btn btn-primary" @click="handleSaveLaborAssignment()">
            Save changes
          </button>
  
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, watch } from "vue";
  import {
    createLaborAssignment,
    getAllLaborAssignment,
    updateLaborAssignment,
    deleteLaborAssignment,
    getAllBiddingPackages,
    getAllJobTitle
  } from "@/services/database";
  
  import { toast } from "vue3-toastify";
  import "vue3-toastify/dist/index.css";
  
  import Multiselect from "vue-multiselect";
  import "vue-multiselect/dist/vue-multiselect.css";

  import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
  import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
  import { AgGridVue } from "ag-grid-vue3"; // Vue Data Grid Component
  
  const createLaborAssignmentModal = ref(false);
  
  const orderIndex = ref("");
  const selectedJobTitle = ref("");
  const selectedBiddingPackage = ref("");
  
  const isLoading = ref(false);
  const isUpdate = ref(false);
  const allLaborAssignment = ref([]);
  const selectedLaborAssignment = ref("");

  const selectedJobTitleList = ref([
  {
    "biddingPackageId": "",
    "jobTitleId": "",
    "orderIndex": ""
  }
  ])
  const allBiddingPackages = ref([])
  const allJobTitles = ref([])
  
  const colDefs = ref([

    { field: "biddingPackageId" },
    { field: "orderIndex" },
    { field: "jobTitle", flex: 2 },
    { field: "hourlyRate" },
    { field: "burdenCost" },
    { field: "burdenPercent" },
    { field: "totalCost" },
    {
      field: "actions",
      headerName: "Action",                               
      cellRenderer: (params) => {
        // Create a unique ID for the button
        const buttonId = `btn-assign-${params.node.id}`;
        // Create the button with the unique ID
        const button = `<div class="pe-2 clickable align-self-center font-16 blue-link underline" id="${buttonId}">View</div>`;
        // Use a timeout to ensure the button is in the DOM before adding the event listener
        setTimeout(() => {
          document.getElementById(buttonId)?.addEventListener("click", () => {
            viewLabor(params.data);
          });
        }, 0);
        return button;                                 
      },
    },
  ]);
    
  onMounted(() => {   
    fetchAllLaborAssignment()     
    fetchAllBiddingPackages() 
    fetchAllJobTitle()                                    
  });                                                          

  const clearValues = () => {
    orderIndex.value = ""
  selectedBiddingPackage.value = ""
 selectedJobTitle.value = ""
  }

  const getPayload = () => {
    const transformedArray = selectedJobTitleList.value.map(item => ({
  biddingPackageId: selectedBiddingPackage.value,
  jobTitleId: item.id,
  orderIndex: parseInt(item.orderIndex || 1) // Default to 1 if `orderIndex` is undefined
}));

const filteredArray = transformedArray.filter(item => item.jobTitleId !== undefined);
return filteredArray
  }




  const handleSaveLaborAssignment = () => {
    console.log("transformedArray", getPayload())
    isLoading.value = true;
    const laborPayload = getPayload()
    createLaborAssignment(laborPayload)
      .then((res) => {
        fetchAllLaborAssignment()
        isLoading.value = false;
        closeModal();
        toast.success("Labor Assignment Created");
      })
      .catch((err) => {
        isLoading.value = false;
        toast.error("Something Went Wrong");
        console.error(err);
      });
  };
  
  const handleUpdateLaborAssignment = () => {
    isLoading.value = true;
    const laborPayload = {
       orderIndex: orderIndex.value,
      "biddingPackageId": selectedBiddingPackage.value,
      "jobTitleId": selectedJobTitle.value,
      "id":  selectedLaborAssignment.value
    };
    updateLaborAssignment(laborPayload)
      .then((res) => {
        fetchAllLaborAssignment();
        isLoading.value = false;
        closeModal();
        clearValues()
        toast.success("Labor Assignment Updated");
      })
      .catch((err) => {
        isLoading.value = false;
        toast.error("Something Went Wrong");
        console.error(err);
      });
  };                          
  
  const handleDeleteLaborAssignment = () => {
    isLoading.value = true;
    deleteLaborAssignment(selectedLaborAssignment.value)
      .then((res) => {
        fetchAllLaborAssignment()
        isLoading.value = false;
        closeModal();
      })
      .catch((err) => {
        isLoading.value = false;
        console.error(err);
      });
  };
  
  const fetchAllLaborAssignment = () => {
    isLoading.value = true;
    getAllLaborAssignment()
      .then((res) => {
        allLaborAssignment.value = res;
        isLoading.value = false;
      })
      .catch((err) => {
        isLoading.value = false;
        console.error(err);
      });
  };

  const fetchAllJobTitle = () => {
    isLoading.value = true;
    getAllJobTitle()
      .then((res) => {
        allJobTitles.value = res
        isLoading.value = false;
      })
      .catch((err) => {
        isLoading.value = false;
        console.error(err);
      });
  };

  const fetchAllBiddingPackages = () => {
    isLoading.value = true;
    getAllBiddingPackages()
      .then((res) => {
        allBiddingPackages.value = res
        console.log("res", res)
        isLoading.value = false;
      })
      .catch((err) => {
        isLoading.value = false;
        console.error(err);
      });
  };
  
  const viewLabor = (param) => {
    selectedLaborAssignment.value = param.labourAssignmentId
    isUpdate.value = true;
    orderIndex.value = param.orderIndex;
    selectedJobTitle.value = param.jobTitleId
    selectedBiddingPackage.value = param.biddingPackageId
    createLaborAssignmentModal.value = true;
  };

  
  const closeModal = () => {
    isUpdate.value = false;
    createLaborAssignmentModal.value = false;
  };
  
  const openAddLaborAssignment = () => {
    createLaborAssignmentModal.value = true;
  };

  const handleSelect = () => {
    if (selectedJobTitle.value && !selectedJobTitleList.value.includes(selectedJobTitle.value)) {
        selectedJobTitleList.value.push(selectedJobTitle.value);
      }
};

const deleteSelectedJobTitleValue = (id) => {
    selectedJobTitleList.value =  selectedJobTitleList.value.filter(item => item.id !== id);
};
  </script>
  