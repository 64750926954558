import { ref, computed } from "vue";
import { useRouter } from "vue-router";

export function useAuthStore() {
  const token = ref(localStorage.getItem("access_token_api") ?? null);
  const userData = ref(JSON.parse(localStorage.getItem("userData") || null));
  const router = useRouter();
  const setToken = (newToken) => {
    token.value = newToken;
    localStorage.setItem("access_token_api", newToken);
  };

  const setUserData = (newUserData) => {
    userData.value = newUserData;
    localStorage.setItem("userData", JSON.stringify(newUserData));
  };

  const clearAuth = () => {
    token.value = null;
    userData.value = null;
    localStorage.removeItem("access_token_api");
    localStorage.removeItem("userData");
    router.push("/auth/login");
  };

  const isAuthenticated = computed(() => !!token.value);

  return {
    token,
    userData,
    setToken,
    setUserData,
    clearAuth,
    isAuthenticated,
  };
}
