import { defineStore } from "pinia";
import { ref } from "vue";

export const useUpdateDatabaseComponentsStore = defineStore("dbStore", () => {
  const selectedTarget = ref("Bundle");
  const selectedDataToBeUpdated = ref({});
  const isUpdateOpen = ref(false);
  const openCloseUpdateModal = (newValue) => {
    isUpdateOpen.value = newValue;
  };
  const setTarget = (newTarget) => {
    selectedTarget.value = newTarget;
  };

  const setTargetData = (data) => {
    selectedDataToBeUpdated.value = data;
  };
  return {
    setTarget,
    selectedTarget,
    openCloseUpdateModal,
    isUpdateOpen,
    setTargetData,
    selectedDataToBeUpdated
  };
});
