<template>
  <div class="container-fluid">
    <div class="row">
      <div
        class="col-xl-7 b-center bg-size"
        :style="{
          backgroundImage:
            'url(' + require('../../assets/images/login/2.jpg') + ')',
        }"
        style="
          background-size: cover;
          background-position: center center;
          display: block;
        "
      >
        <img
          class="bg-img-cover bg-center"
          src="../../assets/images/login/2.jpg"
          alt="looginpage"
          style="display: none"
        />
      </div>
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div class="login-main">
              <form @submit="onSubmit" class="theme-form">
                <h4>Create user password</h4>
                <p class="card-note">Enter your Password</p>
                <div class="form-group">
                  <label class="col-form-label">Password</label>
                  <div class="form-input position-relative">
                    <input
                      class="form-control"
                      :type="showPassword ? 'text' : 'password'"
                      v-model="password"
                      v-bind="passwordAttrs"
                    />
                    <div class="show-hide">
                      <span
                        :class="showPassword ? 'show' : 'hide'"
                        @click="toggleShowPassword"
                      ></span>
                    </div>
                  </div>
                  <p v-if="errors.password && submitCount" class="error">
                    Please enter your password
                  </p>
                </div>
                <div class="form-group">
                  <label class="col-form-label">Confirm password</label>
                  <div class="form-input position-relative">
                    <input
                      class="form-control"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      v-model="confirmPassword"
                      v-bind="confirmPasswordAttrs"
                    />
                    <div class="show-hide">
                      <span
                        :class="showConfirmPassword ? 'show' : 'hide'"
                        @click="toggleShowConfirmPassword"
                      ></span>
                    </div>
                  </div>
                  <p v-if="errors.confirmPassword && submitCount" class="error">
                    Passwords do not match
                  </p>
                </div>
                <div class="form-group mb-0">
                  <button
                    :disabled="isLoading"
                    class="btn btn-primary btn-block w-100 submit-button"
                  >
                    <span v-if="!isLoading">Create password</span>
                    <output
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </output>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { createUserPassword } from "@/services/auth";
import { useForm, defineRule } from "vee-validate";
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { toast } from "vue3-toastify";

const showPassword = ref(false);
const showConfirmPassword = ref(false);
const isLoading = ref(false);


defineRule("required", (value) => {
  if (!value || !value.length) {
    return "This field is required";
  }
  return true;
});

defineRule("confirmed", (value, [target], ctx) => {
  if (value !== ctx.form[target]) {
    return "Passwords must match";
  }
  return true;
});

const { errors, handleSubmit, defineField, submitCount, setFieldError } =
  useForm({
    validationSchema: {
      password: { required: true },
      confirmPassword: { required: true, confirmed: "password" },
    },
  });

const [password, passwordAttrs] = defineField("password");
const [confirmPassword, confirmPasswordAttrs] = defineField("confirmPassword");

const router = useRouter();

const toggleShowPassword = () => {
  showPassword.value = !showPassword.value;
};
const toggleShowConfirmPassword = () => {
  showConfirmPassword.value = !showConfirmPassword.value;
};

const onSubmit = handleSubmit((values) => {
  const searchParams = new URLSearchParams(window.location.search);

isLoading.value = true;
  const payload = {
    email: searchParams.get('email'),
    password: values.password,
    verification: searchParams.get('token').replaceAll(' ', '+'),
  };
  console.log(payload);
  createUserPassword(payload)
    .then((res) => {
      isLoading.value = false;
      router.push("/auth/login");
      console.log(res);
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something went wrong");
      console.log(err);
    });
});

const resendMessage = (e) => {
  // prevent form from submit
  e.preventDefault();
  console.log("resend", e);
};
</script>

<style scoped lang="scss">
.error {
  color: red !important;
  font-size: 12px !important;
  margin: 4px 0 !important;
}
.card-note {
  color: $white-color !important;
}
.submit-button {
  margin-top: 16px !important;
  // background-color: $white-color !important;
  // color: $dark-black-color !important;
}
.submit-button:disabled {
  background-color: $white-color !important;
}

.login-card {
  background: $dark-black-color !important;
}

.login-main {
  background-color: $regular-black-color !important;
  color: $white-color !important;
}

.show-hide span {
  color: #f3f4f6 !important;
}
</style>