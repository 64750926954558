<template>
  <div style="width: 100%; height: 100%; position: relative" id="fullScreen">
    <viewer-control
      :viewer="viewer"
      :activeTools="activeTools"
      :panOrOrbit="panOrOrbit"
      :isGroup="props.isGroup"
      v-if="!hideToolbar"
    />
    <div id="forgeViewer"></div>
    <scale
      v-if="shouldLoadScale"
      :viewer="viewer"
      :models="props.URNs"
      :isGroup="props.isGroup"
      @removeModel="
        (urn, ind) => {
          console.log('remove from viewer', urn);
          emit('removeUrn', urn, ind);
        }
      "
    />
  </div>
</template>

<script setup>
import { ref, watch, onBeforeUnmount, computed } from "vue";
import scale from "@/components/database/scale.vue";
import ViewerControl from "@/components/database/viewer-control";

import {
  addViewable,
  hideBar,
  addViewableWithTransformMatrix,
  selectAll,
} from "@/components/database/utils";
import { usePallet } from "@/pinia/pallet";

const props = defineProps({
  URNs: Array,
  isBundle: Boolean,
  isGroup: Boolean,
  isElement: Boolean,
  hideToolbar: Boolean,
});

const emit = defineEmits(["updateViewer", "removeUrn", "addFavoriteModel"]);
const activeTools = [
  "line",
  "angle",
  "rotate",
  "move",
  "panOrOrbit",
  "fullScreen",
];
const favoriteStore = usePallet();
const isModelsLoaded = ref(false);
let viewer;
const options = {
  env: "AutodeskProduction",
  accessToken: localStorage.getItem("access_token"),
};

Autodesk.Viewing.Initializer(options, function onInitialized() {
  let viewerDiv = document.getElementById("forgeViewer");
  viewer = new Autodesk.Viewing.GuiViewer3D(viewerDiv);
  viewer.start();

  viewer.addEventListener("extensionLoaded", async function () {
    emit("updateViewer", viewer);
    window.viewer = viewer;
    hideBar(viewer);
  });

  viewer.container.addEventListener("click", function (ev) {
    const result = viewer.impl.intersectGround(ev.clientX, ev.clientY, true);
    const res = viewer.clientToWorld(ev.offsetX, ev.offsetY, true);
    if (favoriteStore.isMenuOpen && favoriteStore.activeElement && res) {
      // GET THE TYPE OF THE ACTIVE ITEMS
      //

      emit("addFavoriteModel", {
        ...favoriteStore.activeElement,
        objectXPosition: res.point.x,
        objectYPosition: res.point.y,
        objectZPosition: res.point.z,
      });
    }
  });
  viewer.addEventListener(
    Autodesk.Viewing.AGGREGATE_SELECTION_CHANGED_EVENT,
    (event) => {
      if (event.selections[0]) {
        const model = event.selections[0].model;
        const index = viewer.getAllModels().indexOf(model);
        const groupIndecies = props.isGroup
          ? props.URNs.find((x) => x.elementsIndices.includes(index))
              .elementsIndices
          : [];
        if (groupIndecies.length > 0) selectAll(event, viewer, groupIndecies);
      }
    }
  );

  viewer.addEventListener("modelAdded", (event) => {
    setTimeout(() => {
      viewer.resize();
      isModelsLoaded.value = true;
    }, 1000);
  });

  if (props.URNs.length && (props.isBundle || props.isElement)) {
    props.URNs.map(({ urn }, index) => {
      addViewable(viewer, urn, null, new THREE.Vector3(0, 0, 0), 0.1);
      viewer.impl.invalidate(true);
      viewer.fitToView();
    });
  }

  if (props.URNs.length && props.isGroup) {
    props.URNs[0].elements.map((ele, index) => {
      const rotationMatrix = new THREE.Matrix4().makeRotationFromEuler(
        new THREE.Euler(
          THREE.Math.degToRad(ele.objectXRotation),
          THREE.Math.degToRad(ele.objectYRotation),
          THREE.Math.degToRad(ele.objectZRotation)
        )
      );

      // Create the scaling matrix
      const scalingMatrix = new THREE.Matrix4().makeScale(
        ele.objectXScale,
        ele.objectYScale,
        ele.objectZScale
      );

      // Combine the rotation and scaling matrices
      const transformMatrix = new THREE.Matrix4().multiplyMatrices(
        rotationMatrix,
        scalingMatrix
      );

      addViewableWithTransformMatrix(
        viewer,
        ele.dwfxObjectTranslatedUrn,
        transformMatrix,
        new THREE.Vector3(
          ele.objectXPosition,
          ele.objectYPosition,
          ele.objectZPosition
        )
      );
    });
  }
});

onBeforeUnmount(() => {
  viewer.unloadExtension("Viewing.Extension.Transform");
  window.viewer = undefined;
  emit("updateViewer", undefined);
});

const shouldLoadScale = computed(() => {
  return props.URNs.length > 0 && isModelsLoaded.value;
});

const groupURNs = computed(() => {
  return props.URNs.map((x) => {
    return x.elements.map((ele) => {
      return { ...ele, elementsIndices: x.elementsIndices };
    });
  }).flat();
});

watch(
  () => props.URNs,
  (newValue, oldValue) => {
    // add new elemt to bundle  when
    if (
      newValue &&
      newValue.length > 0 &&
      props.isBundle &&
      oldValue.length < newValue.length
    ) {
      const element = newValue[newValue.length - 1];
      addViewable(
        viewer,
        element.urn,
        null,
        new THREE.Vector3(
          element.ObjectXPosition,
          element.ObjectYPosition,
          element.ObjectZPosition
        ),
        0.01
      );
    }

    // add new bundle to group
    if (
      newValue &&
      newValue.length > 0 &&
      props.isGroup &&
      oldValue.length < newValue.length
    ) {
      const { elements: bundleElements } = newValue[newValue.length - 1];
      bundleElements.map((ele) => {
        console.log("ELE", ele);
        const rotationMatrix = new THREE.Matrix4().makeRotationFromEuler(
          new THREE.Euler(
            THREE.Math.degToRad(ele.objectXRotation),
            THREE.Math.degToRad(ele.objectYRotation),
            THREE.Math.degToRad(ele.objectZRotation)
          )
        );

        // Create the scaling matrix
        const scalingMatrix = new THREE.Matrix4().makeScale(
          ele.objectXScale,
          ele.objectYScale,
          ele.objectZScale
        );

        // Combine the rotation and scaling matrices
        const transformMatrix = new THREE.Matrix4().multiplyMatrices(
          rotationMatrix,
          scalingMatrix
        );

        addViewableWithTransformMatrix(
          viewer,
          ele.dwfxObjectTranslatedUrn,
          transformMatrix,
          new THREE.Vector3(
            ele.objectXPosition,
            ele.objectYPosition,
            ele.objectZPosition
          ),
          0.01
        );
      });
    }
  }
);
</script>
<style scoped lang="css">
#forgeViewer {
  width: 100%;
  height: calc(100% - 50px);
}
</style>
