<template>
  <div class="container">
    <label class="headerTitle">Scale value</label>
    <input type="number" v-model="scaleValue" />
    <ul>
      <!--  -->
      <li v-for="(parentURNObject, ind) in props.models">
        <p>{{ parentURNObject.elementName }}</p>
        <div class="increase-decrease">
          <button
            type="button"
            class="btn btn-primary btn-sm control"
            @click="() => onDecrease(ind + 1, scaleValue)"
          >
            <i class="icon-minus"></i>
          </button>
          <span>{{ allScaleValues[ind]?.x.toFixed(2) }} </span>
          <span>{{ allScaleValues[ind]?.y.toFixed(2) }} </span>
          <span>{{ allScaleValues[ind]?.z.toFixed(2) }} </span>
          <button
            type="button"
            class="btn btn-primary btn-sm control"
            @click="() => onIncrease(ind + 1, scaleValue, parentURNObject)"
          >
            <i class="icon-plus"></i>
          </button>
        </div>
        <span class="focus" @click="() => onFocus(props.viewer, ind + 1)"
          ><i class="icon-search"></i>
        </span>
        <span
          class="focus"
          @click="
            () => onDelete(props.viewer, ind, parentURNObject, parentURNObject)
          "
        >
          <i class="icon-trash"></i>
        </span>
        <hr v-if="ind !== props.models.length - 1" />
      </li>
    </ul>
  </div>
</template>
<script setup>
import { computed, ref, watch } from "vue";
import { onFocus, onScale, getModelScale } from "@/components/database/utils";

const props = defineProps({
  models: Array,
  viewer: Object,
  isGroup: Boolean,
});

const emit = defineEmits(["removeModel"]);
const scaleValue = ref(0.01);
const trigerUpdate = ref(false);
const allScaleValues = computed(() => {
  trigerUpdate.value;
  return props.viewer
    .getAllModels()
    .map((x, ind) => getModelScale(props.viewer, ind + 1));
});

watch(
  () => props.models,
  (newValue) => {
    if (newValue.length) {
      setTimeout(() => {
        onIncrease(props.models.length, 0);
        onFocus(props.viewer, props.models.length);
      }, 2000);
    }
  }
);

const onIncrease = (ind, value = 0.01) => {
  trigerUpdate.value = !trigerUpdate.value;

  onScale(props.viewer, value, "+", ind);
};

const onDecrease = (ind, value = 0.01) => {
  trigerUpdate.value = !trigerUpdate.value;
  onScale(props.viewer, value, "-", ind);
};

const onDelete = (viewer, ind, parentURNObject) => {
  if (!props.isGroup) {
    emit("removeModel", parentURNObject, ind);
    viewer.unloadModel(viewer.getAllModels()[ind]);
  } else {
    const deleteModels = [];
    const allIndices = [];
    props.models[ind].elementsIndices.map((x) => {
      deleteModels.push(viewer.getAllModels()[x]);
      allIndices.push(x);
    });
    deleteModels.map((x) => viewer.unloadModel(x));
    emit("removeModel", parentURNObject, allIndices);
  }
};
</script>
<style scoped lang="scss">
label {
  margin-bottom: 4px;
  font-size: 14px;
}
input {
  width: 100%;
  margin-bottom: 8px;
  padding: 0 8px;
}

input {
  background-color: $light-black-color;
  border: none;
  color: white;
}

.focus {
  color: $white-color;
  display: flex;
  align-items: center;
  justify-content: center;
}

li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  gap: 8px;
}
li p {
  width: 100%;
  margin-bottom: 0;
  font-size: 12px;
}
.increase-decrease {
  display: flex;
  align-items: center;
}

.increase-decrease span {
  width: 50px;
  flex-shrink: 0;
  text-align: center;
}

span {
  cursor: pointer;
}

.container {
  position: absolute;
  right: 0;
  left: calc(100% - 300px);
  top: 35%;
  background-color: white;
  border-radius: 12px;
  padding: 12px;
  max-height: 300px;
  width: 300px;
  z-index: 12;
  overflow-y: auto;
}
ul hr {
  width: 100%;
  margin: 8px 0;
}
.control {
  padding: 4px 10px;
  font-size: 10px;
}
.icon-search {
  font-size: 14px;
  cursor: pointer;
}
/* hr:last-child {
  display: none;
} */
</style>
