<template>
  <!--  .-->
  <div v-if="!isCreate" class="d-block">
    <div class="d-flex justify-content-between align-items-center mb-5">
      <h4 class="mb-0">Parts List</h4>
      <div>
        <button @click="handleUpdateCostAndPrice" class="btn btn-primary mx-3">
          Update Cost And Price
        </button>
        <button @click="openCloseAdd" class="btn btn-primary">
          Add new Part
        </button>
      </div>
    </div>
    <ag-grid-vue
      :loading="false"
      :defaultColDef="defaultColDef"
      :rowSelection="'multiple'"
      :pagination="true"
      :paginationPageSize="10"
      :paginationPageSizeSelector="[10, 20, 30]"
      :rowData="props.allPartsData"
      :columnDefs="colDefsPart"
      style="height: 600px; width: 100%"
      class="ag-theme-quartz"
      @grid-ready="(params) => emit('onGridReady', params)"
    >
    </ag-grid-vue>

    <div v-if="showUpdateCostModal" class="custom-modal">
      <div class="custom-modal-content">
        <div class="custom-modal-header">
          <h5>Update Cost And Price By Percentage</h5>
          <button @click="closeModal" class="btn-close">✕</button>
        </div>
        <div class="custom-modal-body">
          <div class="mb-5">
            <div class="row mt-3">
              <div class="col-md-6 form-group mb-2">
                <label class="col-form-label">Percentage</label>
                <input
                  v-model="updateCostPricePercentage"
                  class="form-control"
                />
              </div>
              <div class="mt-3">
                <label class="col-form-label d-block pb-7"
                  >Update Labor Cost</label
                >
                <div class="custom-switch">
                  <span
                    @click="handleSwitchUpdateLaborCost(1)"
                    :class="isUpdateLaborCost === 1 ? 'active' : ''"
                    >Yes</span
                  >
                  <span
                    @click="handleSwitchUpdateLaborCost(2)"
                    :class="isUpdateLaborCost === 2 ? 'active' : ''"
                    >No</span
                  >
                </div>
              </div>

              <div class="mt-3">
                <label class="col-form-label d-block pb-7">Update Price</label>
                <div class="custom-switch">
                  <span
                    @click="handleSwitchUpdateLaborPrice(1)"
                    :class="isUpdateLaborPrice === 1 ? 'active' : ''"
                    >Yes</span
                  >
                  <span
                    @click="handleSwitchUpdateLaborPrice(2)"
                    :class="isUpdateLaborPrice === 2 ? 'active' : ''"
                    >No</span
                  >
                </div>
              </div>

              <div class="mt-3">
                <label class="col-form-label d-block pb-7"
                  >Is Increasing?</label
                >
                <div class="custom-switch">
                  <span
                    @click="handleSwitchUpdateLaborPriceIncrease(1)"
                    :class="isUpdateLaborPriceIncrease === 1 ? 'active' : ''"
                    >Yes</span
                  >
                  <span
                    @click="handleSwitchUpdateLaborPriceIncrease(2)"
                    :class="isUpdateLaborPriceIncrease === 2 ? 'active' : ''"
                    >No</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="custom-modal-footer">
          <button class="btn btn-secondary me-3" @click="closeModal">
            Close
          </button>
          <button
            class="btn btn-primary"
            @click="handleUpdateCostAndPriceByPercentage()"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>

    <div v-if="showUpdateCostModal" class="custom-modal-backdrop"></div>
  </div>

  <div v-else class="ps-3">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="mb-0">Create new parts.</h4>

      <public-or-private
        :isPrivate="isPrivate"
        @updateIsPrivate="(newValue) => (isPrivate = newValue)"
      />
    </div>

    <form @submit="onSubmit" id="create-part-form">
      <div class="row">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Name
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.name">(required)</span>
          </label>
          <div class="form-input">
            <input
              v-model="newPartName"
              v-bind="nameAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Upc
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.upc">(required)</span>
          </label>
          <div class="form-input">
            <input
              v-model="newPartUpc"
              v-bind="upcAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Cut Sheet Url
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.sheetUrl">(required)</span>
          </label>
          <div class="form-input">
            <input
              v-model="newPartCutSheetUrl"
              v-bind="sheetUrlAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Manufacturer
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.partNumber">(required)</span>
          </label>
          <div class="d-flex form-input">
            <select
              v-model="newPartManufacturerPartNumber"
              v-bind="partNumber"
              class="form-select"
            >
              <option
                :value="manufacturer.id"
                v-for="manufacturer in manufacturerData"
              >
                {{ manufacturer.name }}
              </option>
            </select>
            <button class="add" type="button" @click="openCreateManufacturer()">
              +
            </button>
          </div>
        </div>
      </div>

      <categories-systems
        :manufacturerData="manufacturerData"
        :categoriesData="props.categoriesData"
        :selectedData="systemSelectedData"
        @onSelect="
          (newSelectedData) =>
            (systemSelectedData = { ...systemSelectedData, ...newSelectedData })
        "
        @fetchAllCategories="emit('fetchAllCategories')"
        :errors="errors"
        :submitCount="submitCount"
      />

      <div class="row">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Version Number
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.versionNumber">(required)</span>
          </label>
          <div class="form-input">
            <input
              v-model="newPartVersionNumber"
              v-bind="versionNumberAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Owner Type
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.ownerType">(required)</span>
          </label>
          <select
            v-model="newPartOwnerType"
            v-bind="ownerTypeAttrs"
            class="form-select"
          >
            <option :value="owner.id" v-for="owner in props.ownerTypeData">
              {{ owner.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Cost Code
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.costCode">(required)</span>
          </label>
          <div class="d-flex">
            <select
              v-model="newPartCostCode"
              v-bind="costCodeAttrs"
              class="form-select"
            >
              <option
                :value="costCode.id"
                v-for="costCode in props.costCodeData"
              >
                {{ costCode.name }}
              </option>
            </select>
            <button
              class="add"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#createCostCodeModal"
            >
              +
            </button>
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Price
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.priceUnit">(required)</span>
          </label>
          <select
            v-model="newPartPrice"
            v-bind="priceAttrs"
            class="form-select"
          >
            <option :value="price.id" v-for="price in pricingUnitsData">
              {{ price.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Manufacturer Part Number
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.newPartManufacturer"
              >(required)</span
            >
          </label>
          <div class="form-input">
            <input
              v-model="newPartManufacturer"
              v-bind="manufacturerAttrs"
              class="form-control"
            />
          </div>
        </div>
      </div>
    </form>

    <div class="row">
      <div class="col-md-3 form-group mb-2 new-part-image">
        <label for="file-upload" class="custom-file-upload btn btn-primary">
          Click To Upload Images
        </label>
        <input
          id="file-upload"
          @change="onFileChange"
          type="file"
          multiple="multiple"
        />
        <div v-if="newPartImages.length > 0">
          {{ newPartImages.length }} images uploaded.
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end gap-4 mb-5">
      <button @click="openCloseAdd" class="btn btn-secondary">Cancel</button>
      <button form="create-part-form" class="btn btn-primary">
        Create Part
      </button>
    </div>

    <div
      v-if="createdPart"
      @click="handleOpenLaborCost"
      class="d-flex mb-5 clickable bg-generic mb-3 p-3 mt-5"
    >
      <i
        :class="[
          'fa m-2',
          {
            'fa-chevron-up': isOpenLaborCost,
            'fa-chevron-down': !isOpenLaborCost,
          },
        ]"
      ></i>
      <h4>Update Labor Cost</h4>
    </div>

    <div v-if="isOpenLaborCost" class="">
      <div class="row mt-3">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Part</label>
          <input disabled v-model="updateLaborCostPart" class="form-control" />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Bid Labor</label>
          <input v-model="updateLaborCostBidLabor" class="form-control" />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Bid Labor Unit</label>

          <select v-model="updateLaborCostBidLaborUnitID" class="form-select">
            <option value="" disabled selected>Select</option>
            <option
              :value="pricingUnit.id"
              v-for="pricingUnit in pricingUnitsData"
            >
              {{ pricingUnit.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Co Labor 1</label>
          <input v-model="updateLaborCostCoLabor1" class="form-control" />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Co Labor 1 Unit</label>
          <select v-model="updateLaborCostCoLabor1Unit" class="form-select">
            <option value="" disabled selected>Select</option>
            <option
              :value="pricingUnit.id"
              v-for="pricingUnit in pricingUnitsData"
            >
              {{ pricingUnit.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Co Labor 2</label>
          <input v-model="updateLaborCostCoLabor2" class="form-control" />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Co Labor 2 Unit</label>
          <select v-model="updateLaborCostCoLabor2Unit" class="form-select">
            <option value="" disabled selected>Select</option>
            <option
              :value="pricingUnit.id"
              v-for="pricingUnit in pricingUnitsData"
            >
              {{ pricingUnit.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Neca 1</label>
          <input v-model="updateLaborCostNeca1" class="form-control" />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Neca 1 Unit</label>
          <select v-model="updateLaborCostNeca1Unit" class="form-select">
            <option value="" disabled selected>Select</option>
            <option
              :value="pricingUnit.id"
              v-for="pricingUnit in pricingUnitsData"
            >
              {{ pricingUnit.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Neca 2</label>
          <input v-model="updateLaborCostNeca2" class="form-control" />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Neca 2 Unit</label>
          <select v-model="updateLaborCostNeca2Unit" class="form-select">
            <option value="" disabled selected>Select</option>
            <option
              :value="pricingUnit.id"
              v-for="pricingUnit in pricingUnitsData"
            >
              {{ pricingUnit.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Neca 3</label>
          <input v-model="updateLaborCostNeca3" class="form-control" />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Neca 3 Unit</label>
          <select v-model="updateLaborCostNeca3Unit" class="form-select">
            <option value="" disabled selected>Select</option>
            <option
              :value="pricingUnit.id"
              v-for="pricingUnit in pricingUnitsData"
            >
              {{ pricingUnit.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button @click="handleUpdateLaborCost" class="btn btn-primary mt-3">
          Update
        </button>
      </div>
    </div>

    <div
      v-if="createdPart"
      @click="handleOpenLaborPrice"
      class="d-flex mb-5 clickable bg-generic mb-3 p-3 mt-5"
    >
      <i
        :class="[
          'fa m-2',
          {
            'fa-chevron-up': isOpenLaborPrice,
            'fa-chevron-down': !isOpenLaborPrice,
          },
        ]"
      ></i>
      <h4>Update Price</h4>
    </div>

    <div v-if="isOpenLaborPrice" class="mb-5">
      <div class="row mt-3">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Part</label>
          <input disabled v-model="updateLaborPricePart" class="form-control" />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Book Price</label>
          <input v-model="updateLaborPriceBookPrice" class="form-control" />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Book Price Unit</label>
          <select v-model="updateLaborPriceBookPriceUnit" class="form-select">
            <option value="" disabled selected>Select</option>
            <option
              :value="pricingUnit.id"
              v-for="pricingUnit in pricingUnitsData"
            >
              {{ pricingUnit.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Price 1</label>
          <input v-model="updateLaborPrice1" class="form-control" />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Price 1 Unit</label>
          <select v-model="updateLaborPrice1Unit" class="form-select">
            <option value="" disabled selected>Select</option>
            <option
              :value="pricingUnit.id"
              v-for="pricingUnit in pricingUnitsData"
            >
              {{ pricingUnit.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Price 2</label>
          <input v-model="updateLaborPrice2" class="form-control" />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Price 2 Unit</label>
          <select v-model="updateLaborPrice2Unit" class="form-select">
            <option value="" disabled selected>Select</option>
            <option
              :value="pricingUnit.id"
              v-for="pricingUnit in pricingUnitsData"
            >
              {{ pricingUnit.name }}
            </option>
          </select>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Price 3</label>
          <input v-model="updateLaborPrice3" class="form-control" />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">Price 3 Unit</label>
          <select v-model="updateLaborPrice3Unit" class="form-select">
            <option value="" disabled selected>Select</option>
            <option
              :value="pricingUnit.id"
              v-for="pricingUnit in pricingUnitsData"
            >
              {{ pricingUnit.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button @click="handleUpdateLaborPrice" class="btn btn-primary mt-3">
          Update
        </button>
      </div>
    </div>

    <div v-if="showManufacturerModal">
      <div class="custom-modal">
        <div class="custom-modal-content">
          <div class="custom-modal-header">
            <h5>Create Manufacturer</h5>
            <button @click="closeCreateManufacturer" class="btn-close">
              ✕
            </button>
          </div>
          <div class="custom-modal-body">
            <div class="row">
              <div class="col-md-6 form-group">
                <label class="col-form-label">Manufacturer Name</label>
                <div class="form-input">
                  <input v-model="newManufacturerName" class="form-control" />
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label class="col-form-label">Website</label>
                <div class="form-input">
                  <input v-model="newManufacturerWebUrl" class="form-control" />
                </div>
              </div>

              <div class="col-md-6 form-group">
                <label class="col-form-label">Phone</label>
                <div class="form-input">
                  <input v-model="newManufacturerPhone" class="form-control" />
                </div>
              </div>

              <div class="col-md-6 form-group">
                <label class="col-form-label">Email</label>
                <div class="form-input">
                  <input v-model="newManufacturerEmail" class="form-control" />
                </div>
              </div>

              <div class="col-md-6 form-group">
                <label class="col-form-label">Address</label>
                <div class="form-input">
                  <input
                    v-model="newManufacturerAddress"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="custom-modal-footer">
            <button
              class="btn btn-secondary me-3"
              @click="closeCreateManufacturer"
            >
              Cancel
            </button>
            <button class="btn btn-primary" @click="handleCreateManufacturer">
              Yes
            </button>
          </div>
        </div>
      </div>

      <div class="custom-modal-backdrop"></div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount, watch } from "vue";
import { useGlobalStore } from "@/pinia/globalStore";
import {
  getAllPricingUnits,
  getAllManufacturers,
  createPartService,
  updateLaborCost,
  updateLaborPrice,
  UpdateCostAndPriceByPercentage,
  createManufacturer,
} from "@/services/database";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue3"; // Vue Data Grid Component
import { useForm } from "vee-validate";
import CategoriesSystems from "@/components/database/shared/categories-systems.vue";
import publicOrPrivate from "./shared/public-or-private.vue";
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase";
const dbStore = useUpdateDatabaseComponentsStore();

const globalStore = useGlobalStore();

const colDefsPart = ref([
  { field: "name", flex: 5 },
  { field: "categoryName" },
  { field: "systemName" },
  { field: "subSystemName" },
  { field: "styleName" },
  { field: "manufacturerPartNumber" },
  {
    field: "actions",
    headerName: "Action",
    cellRenderer: (params) => {
      const buttonId = `btn-${params.node.id}`;
      const button = `
      
      <div class="pe-2 clickable align-self-center font-16 blue-link underline" data-bs-toggle="modal"
            data-bs-target="#updateDBModal" id="${buttonId}">Edit</div>
      `;

      setTimeout(() => {
        document
          .getElementById("updateDBModal")
          ?.addEventListener("click", () => {});

        document.getElementById(buttonId)?.addEventListener("click", () => {
          handleOpenEditModal(params.data);
        });
      }, 0);
      return button;
    },
  },
]);

const props = defineProps([
  "allPartsData",
  "ownerTypeData",
  "costCodeData",
  "categoriesData",
  "pricingUnitsData",
]);
const emit = defineEmits([
  "fetchAllParts",
  "fetchAllCategories",
  "onGridReady",
]);

const handleOpenEditModal = (data) => {
  dbStore.setTarget("part");
  dbStore.setTargetData(data);
};

const isCreate = ref(false);
const updateLaborCostPart = ref("");
const updateLaborCostBidLabor = ref("");
const updateLaborCostBidLaborUnitID = ref("");
const updateLaborCostCoLabor1 = ref("");
const updateLaborCostCoLabor1Unit = ref("");
const updateLaborCostCoLabor2 = ref("");
const updateLaborCostCoLabor2Unit = ref("");
const updateLaborCostNeca1 = ref("");
const updateLaborCostNeca1Unit = ref("");
const updateLaborCostNeca2 = ref("");
const updateLaborCostNeca2Unit = ref("");
const updateLaborCostNeca3 = ref("");
const updateLaborCostNeca3Unit = ref("");
const showUpdateCostModal = ref("");
const showManufacturerModal = ref(false);

const newManufacturerName = ref("");
const newManufacturerWebUrl = ref("");
const newManufacturerPhone = ref("");
const newManufacturerEmail = ref("");
const newManufacturerAddress = ref("");

const createdPart = ref("");
const isOpenLaborCost = ref(false);
const isOpenLaborPrice = ref(false);

const updateLaborPricePart = ref("");
const updateLaborPriceBookPrice = ref("");
const updateLaborPriceBookPriceUnit = ref("");
const updateLaborPrice1 = ref("");
const updateLaborPrice1Unit = ref("");
const updateLaborPrice2 = ref("");
const updateLaborPrice2Unit = ref("");
const updateLaborPrice3 = ref("");
const updateLaborPrice3Unit = ref("");

const updateCostPricePercentage = ref("");

const isUpdateLaborCost = ref(1);
const isUpdateLaborPrice = ref(1);
const isUpdateLaborPriceIncrease = ref(1);

const isLoading = ref(false);
const pricingUnitsData = ref([]);
const manufacturerData = ref([]);
const newPartImages = ref([]);
const systemSelectedData = ref({});

const isPrivate = ref("private");

const { errors, handleSubmit, defineField, submitCount, setFieldError } =
  useForm({
    validationSchema: {
      name: (value) => (!value ? "field is required" : true),
      upc: (value) => (!value ? "field is required" : true),
      sheetUrl: (value) => (!value ? "field is required" : true),
      partNumber: (value) => (!value ? "field is required" : true),
      versionNumber: (value) => (!value ? "field is required" : true),
      ownerType: (value) => (!value ? "field is required" : true),
      costCode: (value) => (!value ? "field is required" : true),
      priceUnit: (value) => (!value ? "field is required" : true),
      manufacturer: (value) => (!value ? "field is required" : true),

      CategoryId: (value) =>
        !systemSelectedData.value.CategoryId ? "field is required" : true,
      SystemId: (value) =>
        !systemSelectedData.value.SystemId ? "field is required" : true,
      SubSystemId: (value) =>
        !systemSelectedData.value.SubSystemId ? "field is required" : true,
      StyleId: (value) =>
        !systemSelectedData.value.StyleId ? "field is required" : true,
    },
  });

const [newPartName, nameAttrs] = defineField("name");
const [newPartPrice, priceAttrs] = defineField("priceUnit");

const [newPartUpc, upcAttrs] = defineField("upc");

const [newPartCutSheetUrl, sheetUrlAttrs] = defineField("sheetUrl");
const [newPartManufacturerPartNumber, partNumber] = defineField("partNumber");
const [newPartManufacturer, manufacturerAttrs] = defineField("manufacturer");

const [newPartVersionNumber, versionNumberAttrs] = defineField("versionNumber");
const [newPartOwnerType, ownerTypeAttrs] = defineField("ownerType");
const [newPartCostCode, costCodeAttrs] = defineField("costCode");

watch(systemSelectedData, (newValue) => {
  console.log("newValue", newValue);

  setFieldError(
    "CategoryId",
    newValue.CategoryId ? undefined : "field is required"
  );
  setFieldError(
    "SystemId",
    newValue.SystemId ? undefined : "field is required"
  );
  setFieldError(
    "SubSystemId",
    newValue.SubSystemId ? undefined : "field is required"
  );
  setFieldError("StyleId", newValue.StyleId ? undefined : "field is required");
});

watch(isPrivate, (newValue) => {
  console.log("my system values is", newValue);
});

const closeModal = () => {
  showUpdateCostModal.value = false;
};

const openCloseAdd = () => {
  isCreate.value = !isCreate.value;
  globalStore.isCreateOpen = isCreate.value;
};

onBeforeMount(() => {
  fetchAllManufacturers();
  fetchAllPricingUnits();
});

const handleCreateManufacturer = () => {
  const manufacturerPayload = {
    name: newManufacturerName.value,
    webSiteUrl: newManufacturerWebUrl.value,
    phone: newManufacturerPhone.value,
    email: newManufacturerEmail.value,
    address: newManufacturerAddress.value,
  };
  createManufacturer(manufacturerPayload)
    .then((res) => {
      showManufacturerModal.value = false;
      fetchAllManufacturers();
      toast.success("Manufacturer Created");
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleUpdateCostAndPrice = () => {
  showUpdateCostModal.value = true;
};

const handleOpenLaborPrice = () => {
  isOpenLaborPrice.value = !isOpenLaborPrice.value;
};

const handleOpenLaborCost = () => {
  isOpenLaborCost.value = !isOpenLaborCost.value;
};

const openCreateManufacturer = () => {
  showManufacturerModal.value = true;
};
const closeCreateManufacturer = () => {
  showManufacturerModal.value = false;
};

const fetchAllManufacturers = () => {
  getAllManufacturers()
    .then((res) => {
      manufacturerData.value = res.items;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleSwitchUpdateLaborCost = (param) => {
  isUpdateLaborCost.value = param;
};

const handleSwitchUpdateLaborPrice = (param) => {
  isUpdateLaborPrice.value = param;
};

const handleSwitchUpdateLaborPriceIncrease = (param) => {
  isUpdateLaborPriceIncrease.value = param;
};

const handleUpdateLaborCost = () => {
  const payload = {
    partId: createdPart.value.id,
    bidLabor: updateLaborCostBidLabor.value,
    bidLaborUnitId: updateLaborCostBidLaborUnitID.value,
    colabor1: updateLaborCostCoLabor1.value,
    colabor1UniteId: updateLaborCostCoLabor1Unit.value,
    colabor2: updateLaborCostCoLabor2.value,
    colabor2UniteId: updateLaborCostCoLabor2Unit.value,
    neca1: updateLaborCostNeca1.value,
    neca1uniteId: updateLaborCostNeca1Unit.value,
    neca2: updateLaborCostNeca2.value,
    neca2uniteId: updateLaborCostNeca2Unit.value,
    neca3: updateLaborCostNeca3.value,
    neca3uniteId: updateLaborCostNeca3Unit.value,
  };
  updateLaborCost(payload)
    .then((res) => {
      toast.success("Successfully Updated");
      console.log("res", res);
    })
    .catch((err) => {
      toast.success("Something went wrong");
      console.error(err);
    });
};

const handleUpdateLaborPrice = () => {
  const payload = {
    partId: createdPart.value.id,
    bookPrice: updateLaborPriceBookPrice.value,
    bookPriceUnitId: updateLaborPriceBookPriceUnit.value,
    price1: updateLaborPrice1.value,
    price1UnitId: updateLaborPrice1Unit.value,
    price2: updateLaborPrice2.value,
    price2UnitId: updateLaborPrice2Unit.value,
    price3: updateLaborPrice3.value,
    price3UnitId: updateLaborPrice3Unit.value,
  };
  updateLaborPrice(payload)
    .then((res) => {
      toast.success("Successfully Updated");
      console.log("res", res);
    })
    .catch((err) => {
      toast.success("Something went wrong");
      console.error(err);
    });
};

const handleUpdateCostAndPriceByPercentage = () => {
  const payload = {
    percentage: parseInt(updateCostPricePercentage.value),
    updateLaborCost: isUpdateLaborCost.value === 1,
    updatePrice: isUpdateLaborPrice.value === 1,
    isIncrease: isUpdateLaborPriceIncrease.value === 1,
  };
  UpdateCostAndPriceByPercentage(payload)
    .then((res) => {
      toast.success("Successfully Updated");
      console.log("res", res);
    })
    .catch((err) => {
      toast.success("Something went wrong");
      console.error(err);
    });
};
const resetFormHandler = () => {
  resetForm();
  systemSelectedData.value = {};
  isPrivate.value = "private";
};
const handleCreatePart = () => {
  const { CategoryId, SystemId, SubSystemId, StyleId } =
    systemSelectedData.value;
  isLoading.value = true;
  const partFormData = new FormData();
  partFormData.append("Name", newPartName.value);
  partFormData.append("ManufacturerPartNumber", newPartManufacturer.value);
  partFormData.append("ManufacturerId", newPartManufacturerPartNumber.value);
  partFormData.append("Upc", newPartUpc.value);
  partFormData.append("CutSheetUrl", newPartCutSheetUrl.value);
  partFormData.append("VersionNumber", newPartVersionNumber.value);
  partFormData.append("OwnerTypeId", newPartOwnerType.value);
  partFormData.append("CostCodeId", newPartCostCode.value);
  partFormData.append("CategoryId", CategoryId);
  partFormData.append("SystemId", SystemId);
  partFormData.append("SubSystemId", SubSystemId);
  partFormData.append("StyleId", StyleId);
  partFormData.append("isPrivate", isPrivate.value);
  partFormData.append("LaborCostId", 1);
  partFormData.append("PriceId", 1);
  for (var x = 0; x < newPartImages.value.length; x++) {
    partFormData.append("Images", newPartImages.value[x]);
  }

  createPartService(partFormData, newPartManufacturerPartNumber.value)
    .then((res) => {
      createdPart.value = res;
      updateLaborCostPart.value = createdPart.value.name;
      updateLaborPricePart.value = createdPart.value.name;
      isLoading.value = false;
      emit("fetchAllParts");
      toast.success("New Part Created");
      resetFormHandler();
      console.log("res", res);
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
};

const onFileChange = (e) => {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  newPartImages.value = files;
  console.log("newPartImages", newPartImages.value);
};

const fetchAllPricingUnits = () => {
  getAllPricingUnits()
    .then((res) => {
      pricingUnitsData.value = res.items;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const onSubmit = handleSubmit(handleCreatePart);
</script>
<style scoped lang="css">
.required {
  color: red;
}
.add {
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
