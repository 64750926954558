<template>
  <div
    class="modal fade"
    id="categoriesModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Create {{ categoryStore.selectedLookup.name }}
          </h5>
          <button
            class="btn-close"
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            v-if="categoryStore.selectedLookup.name === 'category'"
            class="row"
          >
            <div class="col-md-12 form-group">
              <label class="col-form-label">Category Name</label>
              <div class="form-input">
                <input v-model="newCategoryName" class="form-control" />
              </div>
            </div>
          </div>

          <div
            v-if="categoryStore.selectedLookup.name === 'system'"
            class="row"
          >
            <div class="col-md-12 form-group">
              <label class="col-form-label">System Name</label>
              <div class="form-input">
                <input v-model="newSystemName" class="form-control" />
              </div>
            </div>
          </div>

          <div
            v-if="categoryStore.selectedLookup.name === 'subsystem'"
            class="row"
          >
            <div class="col-md-12 form-group">
              <label class="col-form-label">SubSystem Name</label>
              <div class="form-input">
                <input v-model="newSubSystemName" class="form-control" />
              </div>
            </div>
          </div>

          <div v-if="categoryStore.selectedLookup.name === 'style'" class="row">
            <div class="col-md-12 form-group">
              <label class="col-form-label">Style Name</label>
              <div class="form-input">
                <input v-model="newStyleName" class="form-control" />
              </div>
            </div>
          </div>

          <div v-if="categoryStore.selectedLookup.name === 'manufacturer'" class="row">
              <div class="col-md-6 form-group">
                <label class="col-form-label">Manufacturer Name</label>
                <div class="form-input">
                  <input v-model="newManufacturerName" class="form-control" />
                </div>
              </div>
              <div class="col-md-6 form-group">
                <label class="col-form-label">Website</label>
                <div class="form-input">
                  <input v-model="newManufacturerWebUrl" class="form-control" />
                </div>
              </div>

              <div class="col-md-6 form-group">
                <label class="col-form-label">Phone</label>
                <div class="form-input">
                  <input v-model="newManufacturerPhone" class="form-control" />
                </div>
              </div>

              <div class="col-md-6 form-group">
                <label class="col-form-label">Email</label>
                <div class="form-input">
                  <input v-model="newManufacturerEmail" class="form-control" />
                </div>
              </div>

              <div class="col-md-6 form-group">
                <label class="col-form-label">Address</label>
                <div class="form-input">
                  <input
                    v-model="newManufacturerAddress"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button
            @click="handleCreateLookup"
            class="btn btn-primary"
            data-bs-dismiss="modal"
            type="button"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  createCategory,
  createSystem,
  createSubSystem,
  createStyle,
  createManufacturer
} from "@/services/database";
import { ref } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import { useCategoriesStylesStore } from "@/pinia/categories";
const newCategoryName = ref("");
const newSystemName = ref("");
const newSubSystemName = ref("");
const newStyleName = ref("");

const newManufacturerName = ref("");
const newManufacturerWebUrl = ref("");
const newManufacturerPhone = ref("");
const newManufacturerEmail = ref("");
const newManufacturerAddress = ref("");

const categoryStore = useCategoriesStylesStore();

const handleCreateManufacturer = () => {
  const manufacturerPayload = {
    name: newManufacturerName.value,
    webSiteUrl: newManufacturerWebUrl.value,
    phone: newManufacturerPhone.value,
    email: newManufacturerEmail.value,
    address: newManufacturerAddress.value,
  };
  createManufacturer(manufacturerPayload)
    .then((res) => {
      toast.success("Manufacturer Created");
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleCreateCategory = () => {
  const categoryPayload = {
    name: newCategoryName.value,
  };
  createCategory(categoryPayload)
    .then((res) => {
      console.log("res", res);
      toast.success("Category Created");
      categoryStore.selectedLookup.onSuccess();
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleCreateSystem = () => {
  const systemPayload = {
    name: newSystemName.value,
    categoryId: categoryStore.selectedLookup.dependentId,
  };
  createSystem(systemPayload)
    .then((res) => {
      console.log("res", res);
      categoryStore.selectedLookup.onSuccess(systemPayload.categoryId);
      toast.success("System Created");
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleCreateSubSystem = () => {
  const subSystemPayload = {
    name: newSubSystemName.value,
    systemId: categoryStore.selectedLookup.dependentId,
  };
  createSubSystem(subSystemPayload)
    .then((res) => {
      console.log("res", res);
      categoryStore.selectedLookup.onSuccess(subSystemPayload.systemId);
      toast.success("Sub System Created");
    })
    .catch((err) => {
      console.error(err);
    });
};

const handleCreateStyle = () => {
  const stylePayload = {
    name: newStyleName.value,
    subSystemId: categoryStore.selectedLookup.dependentId,
  };
  createStyle(stylePayload)
    .then((res) => {
      console.log("res", res);
      categoryStore.selectedLookup.onSuccess(stylePayload.subSystemId);
      toast.success("Style Created");
    })
    .catch((err) => {
      console.error(err);
    });
};
const handleCreateLookup = () => {
  if (categoryStore.selectedLookup.name === "category") {
    handleCreateCategory();
  }
  if (categoryStore.selectedLookup.name === "system") {
    handleCreateSystem();
  }
  if (categoryStore.selectedLookup.name === "subsystem") {
    handleCreateSubSystem();
  }
  if (categoryStore.selectedLookup.name === "style") {
    handleCreateStyle();
  }
  if (categoryStore.selectedLookup.name === "manufacturer") {
    handleCreateManufacturer();
  }
};
</script>
