<template>
  <div>
    <!-- Tabs Navigation -->
    <div class="tabs">
      <div
        v-for="(tab, index) in tabs"
        :key="tab.id"
        :class="[
          'tab',
          {
            active: activeTab === tab.id,
            'listing-tab': tab.name === 'Project Listing',
          },
        ]"
      >
        <!-- <img
          @click="selectTab(tab.id)"
          v-if="tab.name === 'Project Listing'"
          width="26"
          height="26"
          src="../../../assets/images/icon-3.svg"
          alt=""
        /> -->
        <span @click="selectTab(tab.id)">{{ tab.name }}</span>
        <button
          v-if="tab.name != 'Project Listing'"
          class="close-btn"
          @click="closeTab(tab.id)"
        >
          ✕
        </button>
      </div>
      <button class="new-tab-btn btn btn-primary" @click="addListingTab">
        +
      </button>
    </div>

    <!-- Tab Content -->
    <div class="tab-content">
      <div v-if="activeTab">
        <!-- Render the correct tab's content -->
        <component
          @select-project="addDetailsTab"
          :is="getTabComponent(activeTab)"
          :tab-data="getTabData(activeTab)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { eventBus } from "../../../eventbus";

// Tabs state
const tabs = ref([{ id: 1, name: "Project Listing", type: "listing" }]);
const activeTab = ref(tabs.value[0].id);
const nextTabId = ref(2);

// Add a new listing tab
const addListingTab = () => {
  const existingTab = tabs.value.find((tab) => tab.type === "listing");
  if (existingTab) {
    activeTab.value = existingTab.id; // Focus on the existing Project Listing tab
  } else {
    tabs.value.push({
      id: nextTabId.value,
      name: "Project Listing",
      type: "listing",
    });
    activeTab.value = nextTabId.value;
    nextTabId.value++;
  }
};

const addTab = (type, name, projectId) => {
  tabs.value.push({ id: nextTabId.value, name, type, projectId });
  activeTab.value = nextTabId.value;
  nextTabId.value++;
};

// Add a new project details tab
const addDetailsTab = (projectId, projectName) => {
  tabs.value.push({
    id: nextTabId.value,
    name: `Project: ${projectName}`,
    type: "details",
    projectId,
  });
  activeTab.value = nextTabId.value;
  nextTabId.value++;

  const activeTabConst = activeTab.value;
  console.log("activeTabConst", activeTabConst);
  eventBus.emit("active-tab-changed", activeTabConst);
};

// Close a tab
const closeTab = (id) => {
  tabs.value = tabs.value.filter((tab) => tab.id !== id);
  if (activeTab.value === id && tabs.value.length > 0) {
    activeTab.value = tabs.value[0].id; // Switch to the first tab if the active one is closed
  } else if (tabs.value.length === 0) {
    activeTab.value = null; // No active tab if all tabs are closed
  }
};

// Select a tab
const selectTab = (id) => {
  activeTab.value = id;
  const activeTabConst = tabs.value.find((tab) => tab.id === id);
  console.log("activeTabConst", activeTabConst);
  eventBus.emit("active-tab-changed", activeTabConst);
};

// Get the component for the current tab
const getTabComponent = (id) => {
  // const tab = tabs.value.find((tab) => tab.id === id);
  // return tab?.type === "details" ? "ProjectDetails" : "ProjectListing";

  const tab = tabs.value.find((tab) => tab.id === id);
  if (!tab) return null;
  if (tab.type === "listing") return "ProjectListing";
  if (tab.type === "details") return "ProjectDetails";
  if (tab.type === "createProject") return "CreateProject";
  if (tab.type === "database") return "DatabasePage";
  if (tab.type === "analytics") return "AnalyticsPage";
  if (tab.type === "settings") return "SettingsPage";
  if (tab.type === "breakdown") return "BreakDown";
  if (tab.type === "takeoff") return "TakeOff";
  if (tab.type === "companies") return "Companies";
  if (tab.type === "company") return "Company";
  return null;
};

// Get data for the current tab
const getTabData = (id) => {
  return tabs.value.find((tab) => tab.id === id);
};

const onOpenTab = (payload) => {
  const existingTab = tabs.value.find((tab) => tab.type === payload.type);
  if (existingTab) {
    activeTab.value = existingTab.id;
  } else {
    addTab(payload.type, payload.name, payload.saveProjectId);
  }
};

onMounted(() => {
  eventBus.on("open-tab", onOpenTab);
});

onBeforeUnmount(() => {
  eventBus.off("open-tab", onOpenTab);
});
</script>

<!-- Import your components -->
<script>
import ProjectListing from "../project/projectListing.vue";
import ProjectDetails from "../project/createProject.vue";
import CreateProject from "../project/createProject.vue";
import DatabasePage from "../database/index.vue";
import BreakDown from "../breakdown/index.vue";
import TakeOff from "../takeoff/index.vue";
import Companies from "../companies/index.vue";
import Company from "../company/index.vue";

export default {
  components: {
    ProjectListing,
    ProjectDetails,
    DatabasePage,
    CreateProject,
    BreakDown,
    TakeOff,
    Companies,
    Company,
  },
};
</script>

<style scoped>
.tabs {
  display: flex;
    align-items: center;
    background-color: transparent;
    padding: 0px 8px 19px 1px;
    border-bottom: 1px solid #ccc;
    margin-top: -40px;
    width: 80%;
}

.tab {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  margin-right: 4px;
  background-color: #e1e1e1;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
}

.tab.active {
  background-color: white;
  border: 1px solid #ccc;
  border-bottom: none;
}

.tab span {
  margin-right: 8px;
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #000;
  padding: 0 0px 0 20px;
}

.new-tab-btn {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-left: 8px;
  padding: 8px 12px;
  color: #fff;
}

.tab-content {
  padding: 16px;
  border: 1px solid #ccc;
  background-color: white;
  margin-top: -1px;
  height: 100vh;
  padding-bottom: 100px;
  overflow-y: auto;
}
</style>