<template>
    <div class="breakdown p-20">
        <h3 class="mb-3">
             Breakdown
        </h3>
        <div class="bid-package-area">
                <div class="row">
                    <div class="col-md-2">
                        <h5>
                            Base Bid
                        </h5>
                        <p class="clickable link-color">Add Bid Package</p>
                    </div>
                    <div class="col-md-10 bid-right-container">
                        <div class="row">
                            <div class="col-md-2">
                                <div class="row">
                            <div class="col-md-12 form-group">
                                <label class="col-form-label">Package Status</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-12 form-group">
                                <label class="col-form-label">Square Feet</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-12 form-group">
                                <label class="col-form-label">Lead Estimator</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-12 form-group">
                                <label class="col-form-label">Project Manager</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-12 form-group">
                                <label class="col-form-label">Lead Foreman</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-12 form-group">
                                <label class="col-form-label">Due Date</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>
                        </div>
                            </div>
                            <div class="col-md-10">
                                <div class="row">
                            <div class="col-md-2">
                                <p>Breakdown 1</p>
                                <div class="break-down-col p-2">
                                 <p @contextmenu="onContextMenu($event)">A</p>
                                 <p>B</p>
                                 <p>C</p>
                                 <p>A</p>
                                 <p class="link-color clickable">Add new</p>
                                </div>
                            </div>

                            <div class="col-md-2">
                                <p>Breakdown 2</p>
                                <div class="break-down-col">

                                </div>
                            </div>

                            <div class="col-md-2">
                                <p>Breakdown 3</p>
                                <div class="break-down-col">

                                </div>
                            </div>

                            <div class="col-md-2">
                                <p>Breakdown 4</p>
                                <div class="break-down-col">

                                </div>
                            </div>
                            <div class="col-md-2">
                                <p>Breakdown 5</p>
                                <div class="break-down-col">

                                </div>
                            </div>
                        </div>

                        <button class="btn btn-primary mt-3" type="button">Copy Breakdown From</button>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-md-2">
                                <div class="row">
                            <div class="col-md-12 form-group mt-5">
                                <label class="col-form-label">Estimator</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>

                            <div class="col-md-12 form-group">
                                <label class="col-form-label">Status</label>
                                <div class="form-input">
                                    <input class="form-control" />
                                </div>
                            </div>


                  
                        </div>
                            </div>

                            <div class="col-md-10">
                                <h4 class="my-3">
                                    Full Breakdown List
                                </h4>
                             <div class="break-down-list p-2">
                                <div class="row">
                                    <div class="col-md-4">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <p>AaWw1</p>
                                            </div>
                                            <div class="col-md-3">
                                                <p>Data</p>
                                            </div>
                                            <div class="col-md-3">
                                                <p>Estimator</p>
                                            </div>
                                            <div class="col-md-3">
                                                <p>Status</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 border-grey-left-1">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <p>AaWw1</p>
                                            </div>
                                            <div class="col-md-3">
                                                <p>Data</p>
                                            </div>
                                            <div class="col-md-3">
                                                <p>Estimator</p>
                                            </div>
                                            <div class="col-md-3">
                                                <p>Status</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 border-grey-left-1">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <p>AaWw1</p>
                                            </div>
                                            <div class="col-md-3">
                                                <p>Data</p>
                                            </div>
                                            <div class="col-md-3">
                                                <p>Estimator</p>
                                            </div>
                                            <div class="col-md-3">
                                                <p>Status</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                             </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
    </div>
</template>

<script setup>
import ContextMenu from '@imengyu/vue3-context-menu'

const onContextMenu = (e) => {
  e.preventDefault();
  ContextMenu.showContextMenu({
    x: e.x,
    y: e.y,
    items: [
      { 
        label: "Delete", 
        onClick: () => {
          console.log("You click a menu item");
        }
      },
      { 
        label: "Merge with B", 
        onClick: () => {
          console.log("You click a menu item");
        }
      },
    ]
  });
}

</script>

<style lang="scss" scoped>
.bid-package-area {
    background: #fff;
    box-shadow: 0 0 5px #ccc;
    padding: 20px;
    margin-top: 30px;
    border-radius: 8px;
}

.bid-right-container {
    border-left: 1px solid #ccc;
    padding-left: 30px;
}

.break-down-list {
     border: 1px solid #ccc;
    border-radius: 4px;
}

.break-down-col {
    border-radius: 4px;
     border: 1px solid #ccc;
    min-height: 376px;
    p {
        margin-bottom: 5px;
    }
}

</style>