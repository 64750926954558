<template>
  <table class="table table-bordered users-table">
    <!--  -->
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">phone</th>
        <th scope="col">Address</th>
        <th scope="col">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="user in props.users" :key="user.id">
        <td>
          {{ `${user.firstName} ${user.lastName}` }}
        </td>
        <td>{{ user.email }}</td>
        <!-- make td editable -->
        <td>
          {{ user.phone }}
        </td>
        <td>
          {{ user.address }}
        </td>
        <td class="actions">
          <button
            @click="setUpdateUserAndCompany(user, props.company)"
            data-bs-toggle="modal"
            data-bs-target="#updateUserModal"
          >
            Edit
          </button>
          <button
            data-bs-toggle="modal"
            data-bs-target="#deleteUserModal"
            @click="setUpdateUserAndCompany(user, props.company)"
          >
            Delete
          </button>
          <button
            :disabled="user.hasPassword || isLoading"
            @click="sendEmailHandler(user)"
          >
            Send email
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { resendEmail } from "@/services/company";
import { ref } from "vue";
import { toast } from "vue3-toastify";

const props = defineProps(["users", "company"]);
const emit = defineEmits(["setUpdatedCompany", "setUpdateUser"]);
const isLoading = ref(false);

const setUpdateUserAndCompany = (user, company) => {
  emit("setUpdateUser", user);
  emit("setUpdatedCompany", company);
};

const sendEmailHandler = (user) => {
  isLoading.value = true;
  resendEmail(user.email)
    .then((res) => {
      isLoading.value = false;
      toast.success("Email sent successfully");
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Failed to send email");
    });
};
</script>

<style scoped lang="scss">
table th {
  // color: white !important;
  border: none !important;
}
table th {
  width: 20%;
}
thead tr {
  border: none !important;
}
thead tr th {
  background-color: $light-black-color !important;
}
.actions {
  display: flex;
  align-items: center;
  gap: 24px;
}
.actions button {
  background: #227093;
  color: white;
  padding: 1px 6px;
  border-radius: 5px;
  font-size: 12px;
}
.actions button:disabled {
  opacity: 0.5;
  cursor: default;
}
tbody tr {
  border-color: transparent !important;
  border-bottom: 1px solid $border-color !important;
}
tbody tr td {
  // background-color: $light-black-color !important;
  font-size: 12px !important;
}
table td {
  // color: white !important;
  border: none !important;
}
</style>
