import { defineStore } from "pinia";
import { ref } from "vue";

export const useSearchMenu = defineStore("search", () => {
  const activeElement = ref(null);
  const isMenuOpen = ref(false);
  const minimized = ref(true);
  const itemsList = ref([]);
  //
  function setActiveElement(newElement) {
    activeElement.value = newElement;
  }

  function setItemsList(newList) {
    itemsList.value = newList;
  }

  function isOpen(openState) {
    isMenuOpen.value = openState;
  }

  function toggleMinimize() {
    minimized.value = !minimized.value;
  }

  return {
    activeElement,
    itemsList,
    isMenuOpen,
    minimized,
    setActiveElement,
    setItemsList,
    isOpen,
    toggleMinimize,
  };
});
