import { defineStore } from "pinia";
import { ref } from "vue";

export const useSearchFilterStore = defineStore("searchFilter", () => {
  const searchType = ref("part"); //"element" or "bundle"
  const selectedFilters = ref({});
  const hasFilters = ref(false);
  const setSearchType = (newType) => {
    searchType.value = newType;
  };
  const hasSelectedFilters = (newFilters) => {
    return !!(
      newFilters.CategoryId ||
      newFilters.SystemId ||
      newFilters.SubSystemId ||
      newFilters.StyleId
    );
  };
  const setSelectedFilters = (newFilters) => {
    selectedFilters.value = newFilters;
    hasFilters.value = hasSelectedFilters(newFilters);
  };

  const resetFilters = () => {
    selectedFilters.value = {};
    hasFilters.value = false;
  };
  return {
    searchType,
    selectedFilters,
    hasFilters,
    setSearchType,
    setSelectedFilters,
    resetFilters,
  };
});
