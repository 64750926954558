import axios from "axios";

export const updateDBTarget = (path, data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/${path}`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
export const changePalletOrder = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/ChangePalletOrder",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
export const deletePallet = (palletId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "delete",
      url: `https://estimating.candrelec.com/api/DeletePallet?palletId=${palletId}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
export const getPallets = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetPallets`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
export const addPallet = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/AddPallet",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const convertRfaToDwfx = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/ConvertRfaToDwfx",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const elementDbSearch = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      params,
      method: "get",
      url: `https://estimating.candrelec.com/api/ElementDbSearch`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const createCategory = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/api/app/part-category",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const createSystem = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/api/app/part-system",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const createSubSystem = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/api/app/part-sub-system",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const createStyle = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/api/app/part-style",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const createOwnerType = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/api/app/part-owner-type",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const createManufacturer = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/api/app/part-manufacturer",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const createPricingUnit = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/api/app/part-pricing-unit",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const createCostCode = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: "https://estimating.candrelec.com/api/api/app/part-cost-code",
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllOwnerType = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: "https://estimating.candrelec.com/api/api/app/part-owner-type",
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllPricingUnits = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: "https://estimating.candrelec.com/api/api/app/part-pricing-unit",
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllManufacturers = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: "https://estimating.candrelec.com/api/api/app/part-manufacturer",
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllCostCode = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: "https://estimating.candrelec.com/api/api/app/part-cost-code",
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllCategories = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: "https://estimating.candrelec.com/api/api/app/part-category",
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getSystemsByCategoryId = (categoryId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetSystemsByCategoryId?categoryId=${categoryId}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllSystems = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/api/app/part-system`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllSubSystems = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/api/app/part-sub-system`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllStyles = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/api/app/part-style`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getSubSystemsBySystemId = (systemId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetSubSystemsBySystemId?systemId=${systemId}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getStylesBySubSystemId = (subSystemId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetStylesBySubSystemId?subSystemId=${subSystemId}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const createPartService = (data, manufacturerId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreatePartVersion?ManufacturerId=${manufacturerId}`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const createElementService = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateElementVersion`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const createBundleService = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateBundle`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const createGroupService = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateGroup`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllParts = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllPartsVersions?apiUrl=https://estimating.candrelec.com/api`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllElements = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllElements`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllBundles = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllLatestBundles`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
export const getAllGroups = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllLatestGroups`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const filterDatabase = (params) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/PartDbSearch?${params}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const updateLaborCost = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/UpdateLaborCost`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const updateLaborPrice = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/UpdatePrice`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const UpdateCostAndPriceByPercentage = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/UpdateCostAndPriceByPercentage`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const createBurdenCategory = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateBurdenCategory`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllBurdenCategories = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllBurdenCategories`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const updateBurdenCategory = (data, id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/UpdateBurdenCategory/${id}`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const deleteBurdenCategory = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "delete",
      url: `https://estimating.candrelec.com/api/DeleteBurdenCategory/${id}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// BURDEN

export const createBurden = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateBurden`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllBurden = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllBurdens`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const updateBurden = (data, id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/UpdateBurden/${id}`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const deleteBurden = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "delete",
      url: `https://estimating.candrelec.com/api/DeleteBurden/${id}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// LABOR DIVISION

export const createLaborDivision = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateLabourDivision`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllLaborDivision = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllLabourDivisions`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const updateLaborDivision = (data, id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/UpdateLabourDivision/${id}`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const deleteLaborDivision = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "delete",
      url: `https://estimating.candrelec.com/api/DeleteLabourDivision/${id}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// JOB TITLE

export const createJobTitle = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateJobTitle`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllJobTitle = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllJobTitles`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const updateJobTitle = (data, id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/UpdateJobTitle/${id}`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const deleteJobTitle = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "delete",
      url: `https://estimating.candrelec.com/api/DeleteJobTitle/${id}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// Get all bidding packages
export const getAllBiddingPackages = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllBiddingPackages`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

// Labor Assignment

export const createLaborAssignment = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateLabourAssignment`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const getAllLaborAssignment = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllLabourAssignments`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const updateLaborAssignment = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/UpdateLabourAssignment`,
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};

export const deleteLaborAssignment = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token_api")}`,
      },
      method: "delete",
      url: `https://estimating.candrelec.com/api/DeleteLabourAssignment/${id}`,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err));
  });
};
