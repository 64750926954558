<template>
  <!--  -->
  <div v-if="!isCreate">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="mb-0">Bundles List</h4>
      <button
        @click="openCloseAdd"
        class="btn btn-primary"
        style="margin-left: auto"
      >
        Add new Bundle
      </button>
    </div>
    <div>
      <ag-grid-vue
        :loading="false"
        :rowSelection="'multiple'"
        :pagination="true"
        :paginationPageSize="10"
        :paginationPageSizeSelector="[10, 20, 30]"
        :rowData="props.allBundlesData"
        :columnDefs="colDefsBundle"
        style="height: 600px; width: 100%"
        class="ag-theme-quartz"
        @grid-ready="(params) => emit('onGridReady', params)"
      >
      </ag-grid-vue>
    </div>
  </div>
  <div v-if="isCreate">
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="mb-0">Create new bundle.</h4>
      <public-or-private
        :isPrivate="isPrivate"
        @updateIsPrivate="(newValue) => (isPrivate = newValue)"
      />
    </div>

    <form @submit="onSubmit" id="create-bundle-form">
      <div class="row">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Name<sup class="required"> * </sup>
            <span class="required" v-if="errors.name">(required)</span></label
          >
          <div class="form-input">
            <input
              v-model="newBundleName"
              v-bind="nameAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Size
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.size">(required)</span></label
          >
          <div class="form-input">
            <input
              v-model="newBundleSize"
              v-bind="sizeAttrs"
              class="form-control"
            />
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Inventory Number
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.inventoryNumber"
              >(required)</span
            >
          </label>
          <input
            v-model="newBundleInventoryNumber"
            v-bind="inventoryNumberAttrs"
            class="form-control"
          />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Part Owner Type
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.ownerType">(required)</span>
          </label>
          <select
            v-model="newBundlePartOwnerTypeId"
            v-bind="ownerTypeAttrs"
            class="form-select"
          >
            <option :value="owner.id" v-for="owner in props.ownerTypeData">
              {{ owner.name }}
            </option>
          </select>
        </div>
      </div>

      <categories-systems
        :categoriesData="props.categoriesData"
        :selectedData="systemSelectedData"
        @onSelect="
          (newSelectedData) =>
            (systemSelectedData = { ...systemSelectedData, ...newSelectedData })
        "
        @fetchAllCategories="emit('fetchAllCategories')"
        :errors="errors"
        :submitCount="submitCount"
      />
      <div class="row">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Object File Location Url

            <sup class="required"> * </sup>
            <span class="required" v-if="errors.fileUrl">(required)</span>
          </label>
          <input
            v-model="newBundleObjectFileLocationUrl"
            v-bind="fileUrlAttrs"
            class="form-control"
            style="height: 55px"
          />
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Description

            <sup class="required"> * </sup>
            <span class="required" v-if="errors.description">(required)</span>
          </label>
          <div class="form-input">
            <textarea
              v-model="newBundleDescription"
              class="form-control"
              v-bind="descriptionAttrs"
              style="height: 55px"
            ></textarea>
          </div>
        </div>

        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label">
            Notes
            <sup class="required"> * </sup>
            <span class="required" v-if="errors.notes">(required)</span>
          </label>
          <div class="form-input">
            <textarea
              v-model="newBundleNotes"
              v-bind="notesAttrs"
              class="form-control"
              style="height: 55px"
            ></textarea>
          </div>
        </div>
      </div>
    </form>
    <div class="w-full row mt-2">
      <div class="col-md-6">
        <div class="col-md-6 w-full form-group mb-2 switcherContainer">
          <label class="col-form-label p-0 mb-2">Elements</label>
          <div class="w-100 d-flex">
            <multiselect
              :close-on-select="false"
              :showLabels="false"
              selectLabel=""
              deselectLabel=""
              :preserve-search="true"
              :searchable="true"
              :modelValue="newBundleElements"
              tag-placeholder=""
              placeholder="Type to search"
              label="name"
              track-by="id"
              :taggable="false"
              :options="elementsOptions"
              :multiple="true"
              @search-change="(search) => fetchOptions(search)"
              @update:modelValue="
                (selectedOption) => handleSelect(selectedOption)
              "
            >
              <template #selection="{ values, search, isOpen }">
                <p style="display: none"></p>
                <span
                  class="multiselect__single"
                  style="
                    background-color: #f5f5f5;
                    font-size: 14px;
                    color: #adadad;
                  "
                  v-if="values.length"
                  v-show="!isOpen"
                  >Type to search</span
                >
              </template>
            </multiselect>

            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#filterModal"
              @click="searchFilterStore.setSearchType('element')"
              :class="{
                'search-btn': true,
                active: searchFilterStore.hasFilters,
              }"
            >
              <i class="fa fa-sort-amount-asc" aria-hidden="true"></i>
            </button>
          </div>
        </div>

        <div class="mt-4" style="width: fit-content">
          <bundle-group-switcher
            :modelValue="props.selectedBundleOption"
            @makeUpdate="emit('updateSelectedBundleOption', $event)"
            rfaId="rfaIdBundle"
            viewerId="viewerIdBundle"
            name="bundleToggle"
          />
        </div>
      </div>
      <div class="col-md-6" v-if="bundleElement.length">
        <table class="table table-bordered table-striped">
          <thead>
            <tr>
              <th scope="col">count</th>
              <th scope="col">Name</th>
              <th scope="col">Element VersionId</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(element, index) in bundleTableData" :key="index">
              <td class="counterRow">
                {{ element.quantity }}
                <button class="increase" @click="addExistingBundleAgain(index)">
                  +
                </button>
              </td>
              <td>{{ element.name }}</td>
              <td>{{ element.ElementVersionId }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- selected bundle option  -->

    <div class="row">
      <div class="col-md-3 form-group mb-2 new-part-image">
        <label for="file-upload" class="custom-file-upload btn btn-primary">
          Click To Upload Images
        </label>
        <input
          id="file-upload"
          @change="onFileChangeBundle"
          type="file"
          multiple="multiple"
        />
        <div v-if="newBundleImages.length > 0">
          {{ newBundleImages.length }} images uploaded.
        </div>
      </div>

      <div
        v-if="props.selectedBundleOption == 'rfa'"
        class="col-md-3 new-part-image"
      >
        <label class="custom-file-upload btn btn-primary">
          Click To Upload RFA

          <input @change="onBundleRFAChange" type="file" multiple="multiple" />
        </label>

        <div v-if="bundleRFA.length > 0">
          {{ bundleRFA.length }} file uploaded.
        </div>
      </div>
      <div
        v-if="
          props.selectedLookup === 'createBundle' &&
          props.selectedBundleOption == 'viewer' &&
          bundleElement.length
        "
        style="width: 100%; height: 550px; margin-bottom: 40px"
      >
        <Viewer
          :isBundle="true"
          :URNs="bundleElement"
          @removeUrn="onRemoveURN"
          :viewerRef="viewerRef"
          @updateViewer="(viewer) => (viewerRef = viewer)"
          @addFavoriteModel="
            (modelObj) => {
              newBundleElements.push(modelObj);
            }
          "
        />
      </div>
      <!-- Use the view on bundles  -->

      <div class="d-flex justify-content-end gap-4 my-4">
        <button @click="openCloseAdd" class="btn btn-secondary">Cancel</button>
        <button form="create-bundle-form" class="btn btn-primary">
          Create Bundle
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onUnmounted } from "vue";
import Viewer from "@/components/database/viewer.vue";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue3"; // Vue Data Grid Component
import BundleGroupSwitcher from "@/components/database/bundle-group-switcher.vue";
import Multiselect from "vue-multiselect";
import CategoriesSystems from "@/components/database/shared/categories-systems.vue";
import publicOrPrivate from "./shared/public-or-private.vue";
import { getModelPositions } from "./utils";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { createBundleService, elementDbSearch } from "@/services/database";

import "vue-multiselect/dist/vue-multiselect.css";

import { useForm } from "vee-validate";
import { useGlobalStore } from "@/pinia/globalStore";

const globalStore = useGlobalStore();
import { useSearchFilterStore } from "@/pinia/searchFilterStore";
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase";

const props = defineProps([
  "selectedLookup",
  "allBundlesData",
  "selectedBundleOption",
  "ownerTypeData",
  "allElementsData",
  "categoriesData",
]);

const emit = defineEmits([
  "updateSelectedBundleOption",
  "fetchAllCategories",
  "fetchAllBundles",
  "onGridReady",
]);

// extract submit to submit the form from a button
// const newBundlePartOwnerTypeId = ref("");
const newBundleImages = ref([]);
const viewerRef = ref();
const bundleRFA = ref([]);
const newBundleElements = ref([]);
const isCreate = ref(false);
const systemSelectedData = ref({});
const isPrivate = ref("private");
const bundleTableData = ref([]);
const bundleToBeAdded = ref({});
const elementsOptions = ref([]);
const debounceTimeout = ref(null);
const searchFilterStore = useSearchFilterStore();
const {
  errors,
  handleSubmit,
  defineField,
  submitCount,
  setFieldError,
  resetForm,
} = useForm({
  validationSchema: {
    name: (value) => (!value ? "field is required" : true),
    fileUrl: (value) => (!value ? "field is required" : true),
    size: (value) => (!value ? "field is required" : true),
    inventoryNumber: (value) => (!value ? "field is required" : true),
    description: (value) => (!value ? "field is required" : true),
    notes: (value) => (!value ? "field is required" : true),
    ownerType: (value) => (!value ? "field is required" : true),
    CategoryId: (value) =>
      !systemSelectedData.value.CategoryId ? "field is required" : true,
    SystemId: (value) =>
      !systemSelectedData.value.SystemId ? "field is required" : true,
    SubSystemId: (value) =>
      !systemSelectedData.value.SubSystemId ? "field is required" : true,
    StyleId: (value) =>
      !systemSelectedData.value.StyleId ? "field is required" : true,
  },
});

const [newBundleName, nameAttrs] = defineField("name");

const [newBundleSize, sizeAttrs] = defineField("size");
const [newBundleInventoryNumber, inventoryNumberAttrs] =
  defineField("inventoryNumber");
const [newBundleDescription, descriptionAttrs] = defineField("description");
const [newBundleNotes, notesAttrs] = defineField("notes");
const [newBundleObjectFileLocationUrl, fileUrlAttrs] = defineField("fileUrl");
const [newBundlePartOwnerTypeId, ownerTypeAttrs] = defineField("ownerType");

watch(systemSelectedData, (newValue) => {
  console.log("newValue", newValue);

  setFieldError(
    "CategoryId",
    newValue.CategoryId ? undefined : "field is required"
  );
  setFieldError(
    "SystemId",
    newValue.SystemId ? undefined : "field is required"
  );
  setFieldError(
    "SubSystemId",
    newValue.SubSystemId ? undefined : "field is required"
  );
  setFieldError("StyleId", newValue.StyleId ? undefined : "field is required");
});
const colDefsBundle = ref([
  { field: "name", flex: 5 },
  { field: "inventoryNumber" },
  { field: "versionNumber" },
  { field: "size" },
  { field: "notes" },
  {
    field: "actions",
    headerName: "Action",
    cellRenderer: (params) => {
      // Create a unique ID for the button
      const buttonId = `btn-${params.node.id}`;
      // Create the button with the unique ID
      // const button = `<div class="pe-2 clickable align-self-center font-16 blue-link underline" id="${buttonId}">
      //   Edit
      //   </div>`;
      // Use a timeout to ensure the button is in the DOM before adding the event listener
      // setTimeout(() => {
      //   document.getElementById(buttonId)?.addEventListener("click", () => {
      //     handleOpenEditModal(params.data);
      //   });
      // }, 0);

      const button = `
      
      <div class="pe-2 clickable align-self-center font-16 blue-link underline" data-bs-toggle="modal"
            data-bs-target="#updateDBModal" id="${buttonId}">Edit</div>
`;

      setTimeout(() => {
        document
          .getElementById("updateDBModal")
          ?.addEventListener("click", () => {});

        document.getElementById(buttonId)?.addEventListener("click", () => {
          handleOpenEditModal(params.data);
        });
      }, 0);
      return button;
    },
  },
]);

const isLoading = ref(false);

const dbStore = useUpdateDatabaseComponentsStore();

onUnmounted(() => {
  searchFilterStore.resetFilters();
});
const handleOpenEditModal = (data) => {
  console.log("data is", data);
  dbStore.setTarget("bundle");
  dbStore.setTargetData(data);
};

const openCloseAdd = () => {
  isCreate.value = !isCreate.value;
  globalStore.isCreateOpen = isCreate.value;
};

const onFileChangeBundle = (e) => {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  newBundleImages.value = files;
};

const resetFormHandler = () => {
  resetForm();
  newBundleImages.value = [];
  bundleRFA.value = [];
  systemSelectedData.value = {};
  newBundleElements.value = [];
  isPrivate.value = "private";
};

const handleCreateBundle = () => {
  isLoading.value = true;
  const getElementsIds = newBundleElements.value.map((item) => item.id);
  const bundleFormData = new FormData();
  const { CategoryId, SystemId, SubSystemId, StyleId } =
    systemSelectedData.value;

  let alldbIDs;
  if (props.selectedBundleOption == "viewer")
    alldbIDs = viewerRef.value.getAllModels().map((model) => {
      return model.myData.fragments.fragId2dbId[0];
    });

  const bundleElementData = bundleElement.value.map((element, index) => {
    const { scale, rotation, position } = getModelPositions(
      viewerRef.value,
      index + 1,
      alldbIDs[index]
    );

    delete element.urn;
    delete element.index;

    return {
      ...element,
      ObjectXPosition: position.x,
      ObjectYPosition: position.y,
      ObjectZPosition: position.z,
      ObjectXRotation: rotation.x,
      ObjectYRotation: rotation.y,
      ObjectZRotation: rotation.z,
      ObjectXScale: scale.x,
      ObjectYScale: scale.y,
      ObjectZScale: scale.z,
    };
  });

  // fill BundleElement in case of positioning.
  bundleFormData.append("BundleElements", JSON.stringify(bundleElementData));

  // wrap this with if condition if not viewer.
  if (props.selectedBundleOption.value == "rfa")
    for (var x = 0; x < bundleRFA.value.length; x++) {
      elementFormData.append("RFAFile", bundleRFA.value[x]);
    }

  bundleFormData.append("Name", newBundleName.value);
  bundleFormData.append("Description", newBundleDescription.value);
  bundleFormData.append("Size", newBundleSize.value);
  bundleFormData.append("VersionNumber", "323");
  bundleFormData.append("Notes", newBundleNotes.value);
  bundleFormData.append("InventoryNumber", newBundleInventoryNumber.value);
  bundleFormData.append("PartOwnerTypeId", newBundlePartOwnerTypeId.value);

  bundleFormData.append(
    "ObjectFileLocationUrl",
    newBundleObjectFileLocationUrl.value
  );

  bundleFormData.append("CategoryId", CategoryId);
  bundleFormData.append("SystemId", SystemId);
  bundleFormData.append("SubSystemId", SubSystemId);
  bundleFormData.append("StyleId", StyleId);
  bundleFormData.append("isPrivate", isPrivate.value);
  for (var x = 0; x < newBundleImages.value.length; x++) {
    bundleFormData.append("BundleSymbolPictures", newBundleImages.value[x]);
    console.log("newBundleImages.value[x]", newBundleImages.value[x]);
  }

  for (var x = 0; x < getElementsIds.length; x++) {
    bundleFormData.append("ElementsIds", getElementsIds[x]);
  }

  createBundleService(bundleFormData)
    .then((res) => {
      emit("fetchAllBundles");
      isLoading.value = false;
      toast.success("New Bundle Created");
      resetFormHandler();
      openCloseAdd();
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something Went Wrong");
      console.error(err);
    });
};

const bundleElement = computed(() => {
  return newBundleElements.value.map((element, index) => {
    debugger;
    return {
      index: index + 1,
      urn: element.dwfxObjectTranslatedUrn
        ? element.dwfxObjectTranslatedUrn
        : "dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6d2lwLmRtLnByb2QvMjc4YTAwNmUtMzVhMC00Yjk0LTllNjMtNGMxNjZiMWE2MTViLmR3Zng=",
      ElementVersionId: element.id.toString(),
      ObjectXPosition: element.objectXPosition || 0,
      ObjectYPosition: element.objectYPosition || 0,
      ObjectZPosition: element.objectZPosition || 0,
      ObjectXRotation: 0,
      ObjectYRotation: 0,
      ObjectZRotation: 0,
      ObjectXScale: 0.01,
      ObjectYScale: 0.01,
      ObjectZScale: 0.01,
      name: element.name,
    };
  });
});

const onRemoveURN = (urn, ind) => {
  const deletedBundle = newBundleElements.value[ind];
  newBundleElements.value = newBundleElements.value.filter((element, index) => {
    return index !== ind;
  });

  // we need to update the quantity
  bundleTableData.value.find((element, index) => {
    if (element.ElementVersionId == deletedBundle.id) {
      element.quantity -= 1;
    }
  });

  bundleTableData.value = bundleTableData.value.filter((element, index) => {
    return element.quantity !== 0;
  });
};

const addExistingBundleAgain = (index) => {
  console.log(" bundleElement.value[index];", bundleElement.value[index]);
  bundleToBeAdded.value = {
    ...bundleElement.value[index],
    index: bundleElement.value.length,
  };

  let bundle = newBundleElements.value.find((element) => {
    return element.id == bundleToBeAdded.value.ElementVersionId;
  });

  newBundleElements.value.push(bundle);

  // increase quantity based on index
  bundleTableData.value[index].quantity += 1;

  setTimeout(() => {
    bundleToBeAdded.value = {};
  }, 100);
};

watch(newBundleElements, (newVal, oldValue) => {
  if (newVal.length < oldValue.length) {
    return;
  }
  bundleTableData.value = newVal.map((element) => {
    return {
      name: element.name,
      ElementVersionId: element.id,
      quantity: 1,
    };
  });
});

const onBundleRFAChange = (e) => {
  var files = e.target.files || e.dataTransfer.files;
  if (!files.length) return;
  bundleRFA.value = files;
};

const fetchOptions = async (search) => {
  if (debounceTimeout.value) {
    clearTimeout(debounceTimeout.value);
  }
  debounceTimeout.value = setTimeout(() => {
    if (search.length) {
      const selectedFilters = searchFilterStore.selectedFilters;
      const selectedFiltersKeys = Object.keys(selectedFilters);

      const params = new URLSearchParams();
      params.append("Keyword", search);

      if (selectedFiltersKeys.length > 0) {
        selectedFiltersKeys.forEach((key) => {
          if (selectedFilters[key])
            params.append(`${key}s`, selectedFilters[key]);
        });
      }

      elementDbSearch(params)
        .then((response) => {
          elementsOptions.value = response.elements;
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, 1000);
};

const handleSelect = (selectedOption) => {
  if (selectedOption.length > newBundleElements.value.length) {
    newBundleElements.value = selectedOption;
  }
};

const onSubmit = handleSubmit(handleCreateBundle);
</script>
<style scoped lang="scss">
textarea {
  resize: none;
}

.counterRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.increase {
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: transparent;
  color: black;
}
.increase:hover {
  background-color: #ccc;
  color: white;
  border-color: #007bff;
}
.switcherContainer {
  padding-right: 12px;
}
.required {
  color: red;
}
.search-btn {
  background-color: transparent !important;
  color: black !important;

  &.active {
    background-color: #007bff !important;
    color: white !important;
  }
}
</style>
