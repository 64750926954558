<template>
  <div class="d-flex gap-4">
    <div class="form-check">
      <label class="form-check-label" :for="rfaId">
        <input
          class="form-check-input"
          type="radio"
          :name="name"
          :id="rfaId"
          @change="changeValue"
          value="rfa"
          checked
        />
        Upload RFA.
      </label>
    </div>
    <div class="form-check">
      <label class="form-check-label" :for="viewerId">
        <input
          class="form-check-input"
          type="radio"
          :name="name"
          :id="viewerId"
          @change="changeValue"
          value="viewer"
        />
        Positioning on the viewer.
      </label>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, onMounted } from "vue";

const { modelValue, rfaId, viewerId, name } = defineProps({
  modelValue: String,
  rfaId: String,
  viewerId: String,
  name: String
});

const emit = defineEmits(["makeUpdate"]);

const changeValue = (e) => {
  emit("makeUpdate", e.target.value);
};

</script>
