<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-2">
      <h4 class="mb-0">Update company</h4>
    </div>

    <form @submit="onSubmit" id="create-company-form">
      <div class="row">
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Company name<sup class="required"> * </sup>
            <span class="required" v-if="errors.companyName && submitCount"
              >(required)</span
            ></label
          >
          <div class="form-input">
            <input
              v-model="companyName"
              v-bind="companyNameAttrs"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Company phone number<sup class="required"> * </sup>
            <span class="required" v-if="errors.companyPhone && submitCount"
              >(required)</span
            ></label
          >
          <div class="form-input">
            <input
              v-model="companyPhone"
              v-bind="companyPhoneAttrs"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Company Address<sup class="required"> * </sup>
            <span class="required" v-if="errors.companyAddress && submitCount"
              >(required)</span
            ></label
          >
          <div class="form-input">
            <input
              v-model="companyAddress"
              v-bind="companyAddressAttrs"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-md-3 form-group mb-2">
          <label class="col-form-label"
            >Company email<sup class="required"> * </sup>
            <span class="required" v-if="errors.companyEmail && submitCount"
              >(required)</span
            ></label
          >
          <div class="form-input">
            <input
              v-model="companyEmail"
              v-bind="companyEmailAttrs"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end gap-4 my-4">
        <button
          @click="
            () => {
              emit('changeView', 'list');
            }
          "
          class="btn btn-secondary"
        >
          Cancel
        </button>

        <button
          type="submit"
          :disabled="isLoading"
          class="btn btn-primary createBtn"
        >
          <span v-if="!isLoading">Update Company</span>
          <output v-if="isLoading" class="spinner-border spinner-border-sm">
            <span class="visually-hidden">Loading...</span>
          </output>
        </button>
      </div>
    </form>
  </div>
</template>

<script setup>
import { useCompanyStore } from "@/pinia/companyStore";
import { updateCompany } from "@/services/company";
import { useForm } from "vee-validate";
import { ref } from "vue";
import { toast } from "vue3-toastify";

const isLoading = ref(false);

const props = defineProps(["companyData"]);

const emit = defineEmits(["changeView"]);

const companyStore = useCompanyStore();

const { errors, handleSubmit, defineField, submitCount, resetForm } = useForm({
  initialValues: {
    companyName: props.companyData.name,
    companyPhone: props.companyData.phone,
    companyAddress: props.companyData.address,
    companyEmail: props.companyData.email,
  },
  validationSchema: {
    companyName: (value) => (!value ? "field is required" : true),
    companyPhone: (value) => (!value ? "field is required" : true),
    companyAddress: (value) => (!value ? "field is required" : true),
    companyEmail: (value) => (!value ? "field is required" : true),
  },
});

const [companyName, companyNameAttrs] = defineField("companyName");
const [companyPhone, companyPhoneAttrs] = defineField("companyPhone");
const [companyAddress, companyAddressAttrs] = defineField("companyAddress");
const [companyEmail, companyEmailAttrs] = defineField("companyEmail");

const handleCreateCompany = (values) => {
  isLoading.value = true;
  const payload = {
    name: values.companyName,
    phone: values.companyPhone,
    address: values.companyAddress,
    email: values.companyEmail,
  };
  updateCompany(props.companyData.id, payload)
    .then((res) => {
      isLoading.value = false;
      companyStore.fetchCompanies();
      toast.success("Company updated");
      resetForm();
      emit("changeView", "list");
    })
    .catch((err) => {
      isLoading.value = false;
      toast.error("Something went wrong");
    });
};

const onSubmit = handleSubmit(handleCreateCompany);
</script>

<style scoped lang="scss">
.createBtn {
  width: 180px !important;
}
.createBtn:disabled,
.ownerBtn:disabled {
  background-color: #227093 !important;
}
.required {
  color: $error-color;
}
</style>
