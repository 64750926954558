<template>
  <div class="col-md-9 new-part-image">
    <div>
      <button
        @click="fetchAllHubs"
        style="width: 100%"
        :disabled="selectedHub !== ''"
        class="custom-file-upload btn btn-primary connect-to-acc"
      >
        Connect to ACC
      </button>
    </div>

    <div class="card-body">
      <div v-if="allHubs.length > 0" class="row mt-3">
        <div class="col">
          <div class="mb-3">
            <select
              @change="onChangeHub"
              v-model="selectedHub"
              class="form-select digits"
              id="exampleFormControlSelect9"
            >
              <option disabled>Select Hub</option>
              <option :value="allHubs[0]?.id">
                {{ allHubs[0]?.attributes.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div v-if="allProjects.length > 0" class="row">
        <div class="col">
          <div class="mb-3">
            <select
              @change="onChangeProject"
              v-model="selectedProject"
              class="form-select digits"
              id=""
            >
              <option disabled>Select Project</option>
              <option v-for="project in allProjects" :value="project">
                {{ project?.attributes.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div v-if="allFolders.length > 0" class="row">
        <div class="col">
          <div class="mb-3">
            <select
              @change="onChangeFolder"
              v-model="selectedFolder"
              class="form-select digits"
              id=""
            >
              <option disabled>Select Folder</option>
              <option v-for="folder in allFolders" :value="folder">
                {{ folder?.attributes.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div v-if="allSubFolders.length > 0" class="row">
        <div class="col">
          <div class="mb-3">
            <select
              @change="onChangeSubFolder"
              v-model="selectedSubFolder"
              class="form-select digits"
              id=""
            >
              <option disabled>Select Sub Folder</option>
              <option v-for="folder in allSubFolders" :value="folder">
                {{ folder?.attributes.name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div v-if="allDocuments.length > 0" class="row position-relative mb-3">
        <div class="acc-loader" v-if="isLoading">
          <div
            class="spinner-border text-primary spinner-border-sm"
            role="status"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <div class="col">
          <select
            @change="onChangeDocument"
            v-model="selectedDocument"
            class="form-select digits"
            id=""
          >
            <option disabled>Select Document</option>
            <option v-for="document in rfaDocumentsOnly" :value="document">
              {{ document?.attributes.displayName }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue";
//
import {
  getAllProjects,
  getAllHubs,
  getProjectContents,
  translateDocument,
} from "@/services/autodesk";

import { toast } from "vue3-toastify";

import { convertRfaToDwfx } from "@/services/database";

const allHubs = ref([]);
const selectedHub = ref("");

const allProjects = ref([]);
const selectedProject = ref({});

const allFolders = ref([]);
const selectedFolder = ref({});

const allSubFolders = ref([]);
const selectedSubFolder = ref({});

const allDocuments = ref([]);
const selectedDocument = ref({});

const documentURN = ref("");
const translateDocumentURN = ref("");
const isLoading = ref(false);

const emit = defineEmits(["renderSelectedElement"]);

const rfaDocumentsOnly = computed(() => {
  return allDocuments.value.filter((doc) => {
    return doc.attributes.displayName.split(".")[1] === "rfa";
  });
});

const fetchAllHubs = () => {
  getAllHubs()
    .then((res) => {
      allHubs.value = res.data;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const fetchAllDocuments = () => {
  const folderId = selectedProject.value.relationships.rootFolder.data.id;
  getProjectContents(selectedProject.value.id, folderId)
    .then((res) => {
      allFolders.value = res.data;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const onChangeHub = () => {
  fetchAllProjects();
};

const onChangeProject = () => {
  fetchAllDocuments();
};

const fetchAllProjects = () => {
  getAllProjects(selectedHub.value)
    .then((res) => {
      allProjects.value = res.data;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const onChangeSubFolder = () => {
  const folderId = selectedSubFolder.value.id;
  getProjectContents(selectedProject.value.id, folderId)
    .then((res) => {
      allDocuments.value = res.data;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};

const onChangeDocument = () => {
  isLoading.value = true;
  const selectedId = selectedDocument.value.id;
  let formData = new FormData();
  formData.append(
    "RFAUrn",
    "urn:adsk.objects:os.object:wip.dm.prod/a3cb639b-07a4-4880-b23d-559a54dde7e0.rfa"
  );
  convertRfaToDwfx(formData)
    .then((res) => {
      let newElement = {
        index: 1,
        urn: res.translatedDwfxUrn,
        elementName: "clamp",
      };
      isLoading.value = false;
      emit("renderSelectedElement", newElement);
    })
    .catch((err) => {
      console.log(err, "err");
      isLoading.value = false;
      toast.error("Error converting RFA to DWFX");
    });
  console.log("selectedDocument", selectedDocument.value);
};

const onTranslateDocument = () => {
  const payload = {
    input: {
      urn: btoa(documentURN.value),
      compressedUrn: true,
      rootFilename: "A5.iam",
    },
    output: {
      formats: [
        {
          type: "svf",
          views: ["3d"],
        },
      ],
    },
  };

  translateDocument(payload)
    .then((res) => {
      translateDocumentURN.value = res.urn;
      console.log("res ..... ", res);
      //   startViewer();
    })
    .catch((err) => {
      console.error(err);
    });
};

const onChangeFolder = () => {
  const folderId = selectedFolder.value.id;
  getProjectContents(selectedProject.value.id, folderId)
    .then((res) => {
      allSubFolders.value = res.data;
      console.log("res", res);
    })
    .catch((err) => {
      console.error(err);
    });
};
</script>

<style scoped lang="css">
button:disabled {
  cursor: not-allowed;
  background-color: #0d6efd !important;
  border-color: #0d6efd !important;
  opacity: 0.65;
}

.acc-loader {
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  z-index: 2;
}
</style>
