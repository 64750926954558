import axios from "axios"

export const getAllUsers = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: "https://estimating.candrelec.com/api/GetAllUsers"
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getAllProjects = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: "https://estimating.candrelec.com/api/GetAllProjects"
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getProjectTypes = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: "https://estimating.candrelec.com/api/api/app/project-type?Sorting=name&SkipCount=1&MaxResultCount=100"
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getItemStatuses = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: "https://estimating.candrelec.com/api/api/app/items-status?Sorting=name&SkipCount=0&MaxResultCount=100"
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getProjectStatuses = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: "https://estimating.candrelec.com/api/api/app/project-status?Sorting=name&SkipCount=1&MaxResultCount=100"
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getBiddingStatuses = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: "https://estimating.candrelec.com/api/api/app/bidding-package-status?Sorting=name&SkipCount=0&MaxResultCount=100"
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getAllRequestingBusiness = () => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "get",
        url: "https://estimating.candrelec.com/api/api/app/requesting-company"
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const createRequestingBusiness = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      data,
      url: "https://estimating.candrelec.com/api/api/app/requesting-company"
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

  export const createBiddingBusiness = (data) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "post",
        data,
        url: "https://estimating.candrelec.com/api/api/app/bidding-company"
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}


export const createRequestingContact = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      data,
      url: "https://estimating.candrelec.com/api/api/app/requesting-company-contact"
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const createBiddingCompanyContact = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      data,
      url: "https://estimating.candrelec.com/api/api/app/bidding-company-contact"
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getAllRequestingContact = (businessID) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "get",
        url: `https://estimating.candrelec.com/api/api/app/requesting-company-contact?RequestingCompanyId=${businessID}&Sorting=name`
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const getAllBiddingBusiness = () => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "get",
        url: "https://estimating.candrelec.com/api/api/app/bidding-company?Sorting=name"
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const getAllBiddingContact = (biddingBusinessID) => {
    return new Promise((resolve, reject) => {
      axios({
        headers: {
       'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
        },
        method: "get",
        url: `https://estimating.candrelec.com/api/api/app/bidding-company-contact?BiddingCompanyId=${biddingBusinessID}`
      })
      .then((res) => resolve(res.data))
      .catch(err => reject(err))
    })
}

export const saveProjectInfo = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateProject`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const saveBidPackage = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateBiddingPackage`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const saveBreakdowns = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateProjectBreakDown`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const updateBreakdowns = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/UpdateBreakDown`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const saveCombinations = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateUpdateCombination`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getProjectById = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetProjectById?id=${id}`
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getBiddingById = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllBiddingPackages/${id}`
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getAllBiddingBreakDownsById = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllBiddingBreakDowns/${id}`
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getAllBiddingCombinationsById = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetCombination/${id}`
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getAllBiddingCombinationsByIdNew = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetCombinationsByBiddingPackageId/${id}`
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getProjectModelLevelsById = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllModelsByProjectId?projectId=${id}`
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getProjectModelsById = (id) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllModelsByProjectId?projectId=${id}`
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const updateProject = (data, projectId) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/UpdateProject/projectId?projectId=${projectId}`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const updateBiddingPackage = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/UpdateBiddingPackage`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getAllProjectModels = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetAllProjectsModels`
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const createModelFolders = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateModelFolders`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const getAllModelFolders = () => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "get",
      url: `https://estimating.candrelec.com/api/GetModelFolders`,
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const extractTextFromPdf = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/api/app/ocr/extract-text-from-pdf`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const extractTextFromSavedPdf = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/api/app/ocr/extract-text-from-saved-pdf`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const extractAndSavePdfPages = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/api/app/ocr/extract-and-save-pages-from-pdf`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const createProjectModels = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/CreateProjectModelsWithLevels`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const createBiddingContact = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/bidding-company`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const createNewProjectStatus = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/api/app/project-status`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const createNewProjectType = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "post",
      url: `https://estimating.candrelec.com/api/api/app/project-type`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

export const verifyModel = (data) => {
  return new Promise((resolve, reject) => {
    axios({
      headers: {
     'Authorization' : `Bearer ${localStorage.getItem('access_token_api')}`
      },
      method: "put",
      url: `https://estimating.candrelec.com/api/VerifyModel`,
      data
    })
    .then((res) => resolve(res.data))
    .catch(err => reject(err))
  })
}

 