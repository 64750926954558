<template>
  <!-- <div class="d-flex mb-4 gap-2">
    <button :disabled="isDrawingLines" @click="() => $emit('switchLine')">
      Draw line
    </button>
    <button :disabled="isDrawingAngle" @click="() => $emit('switchAngle')">
      Draw angle
    </button>
  </div> -->
  <!-- <div class="row mb-4">
    <div v-if="modelList.length > 0" class="col-md-3">
      <label class="col-form-label">Select a Model</label>
      <div class="form-input">
        <select
          @change="onSelectModelChange"
          v-model="selectedModel"
          class="form-select"
        >
          <option value="" disabled selected hidden>Select</option>
          <option v-for="list in modelList" :value="list.modelID">
            Model {{ list.modelID }} -
            {{ list.isVerified ? "Verified" : "Not Verified" }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-md-2">
      <label class="col-form-label d-block pb-7">Show/Hide</label>
      <div class="custom-switch">
        <span
          @click="handleSwitch(1)"
          :class="selectedShowHideType === 1 ? 'active' : ''"
          >Show</span
        >
        <span
          @click="handleSwitch(2)"
          :class="selectedShowHideType === 2 ? 'active' : ''"
          >Hide</span
        >
      </div>
    </div>
    <div class="col-md-2">
      <label class="col-form-label d-block pb-7">Selectability</label>
      <div class="custom-switch">
        <span
          @click="handleSwitchSelectability(1)"
          :class="selectabilityType === 1 ? 'active' : ''"
          >On</span
        >
        <span
          @click="handleSwitchSelectability(2)"
          :class="selectabilityType === 2 ? 'active' : ''"
          >Off</span
        >
      </div>
    </div>
  </div> -->

  <div class="row mb-4">
    <div v-if="combinationList.length > 0" class="col-md-3">
      <label class="col-form-label">Select a Combination</label>
      <div class="form-input">
        <select
          @change="emitSelectionChange"
          v-model="selectedCombination"
          class="form-select"
        >
          <option
            v-for="(combination, index) in formattedCombinations"
            :key="index"
            :value="index"
          >
            {{ combination.label }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-md-2">
      <!-- Display Status -->
      <div v-if="selectedStatus" class="status-display mt-4 p-3">
        <strong>Status:</strong> {{ selectedStatus }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, computed } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

const props = defineProps({
  isDrawingLines: Boolean,
  isDrawingAngle: Boolean,
  modelList: Array,
  combinationList: Array,
});

const emit = defineEmits(["selectModelChange", "selectCombinationChange"]);

const selectedShowHideType = ref(1);
const selectabilityType = ref(1);
const selectedModel = ref("");
const hideShowLevelType = ref(1);
const selectedCombination = ref("");
const allLevels = ref([]);
const allLevelsFormatted = ref([]);
const formattedCombinations = ref([]);

onMounted(() => {
  setTimeout(() => {
    console.log(Object.values(props.modelList));
    console.log("combinationList", props.combinationList);
    formattedCombinations.value = props.combinationList.map(
      (combination, index) => {
        return {
          label: combination.breakdownNames.join(", "),
          levels: combination.levels.map((level) => level.split(" - ")[0]),
          status: combination.status.name, // Include status
          value: index, // Pass the entire combination object or specific properties
        };
      }
    );
  }, "5000");
});

// Emit the selected levels on change
const emitSelectionChange = () => {
  if (selectedCombination.value !== null) {
    const selected = formattedCombinations.value[selectedCombination.value];
    console.log("selected", selected);
    if (selected.status === "Completed") {
      toast.error(
        "You can't perform action on this combination. Because it is completed"
      );
    }
    emit("selectCombinationChange", {
      levels: selected.levels,
      status: selected.status,
    });
  }
};

const selectedStatus = computed(() => {
  if (selectedCombination.value !== null && selectedCombination.value !== "") {
    return formattedCombinations.value[selectedCombination.value].status;
  }
  return null;
});

const extractLevels = (data) => {
  const result = [];

  // Loop through the main array
  data.forEach((item) => {
    if (item && Array.isArray(item)) {
      item.forEach((subItem) => {
        if (subItem.levelsIds && Array.isArray(subItem.levelsIds)) {
          // Add levelsIds as a sub-array to the result
          result.push([...subItem.levelsIds]); // Use spread operator to create a new array
        }
      });
    }
  });

  return result;
};

const handleSwitch = (type) => {
  console.log("Type", type);
  selectedShowHideType.value = type;
  emit(
    "selectModelChange",
    selectedModel.value,
    selectedShowHideType.value,
    selectabilityType.value
  );
};

const handleSwitchSelectability = (type) => {
  console.log("Type", type);
  selectabilityType.value = type;
  emit(
    "selectModelChange",
    selectedModel.value,
    selectedShowHideType.value,
    selectabilityType.value
  );
};

const onSelectModelChange = () => {
  emit(
    "selectModelChange",
    selectedModel.value,
    selectedShowHideType.value,
    selectabilityType.value
  );
};
</script>

<style lang="css">
button {
  margin-left: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

button:disabled {
  background-color: gray;
}
</style>
