<template>
  <div class="viewer-control z-2" role="group">
    <button
      v-if="activeTools.includes('line')"
      :class="{ active: activeTool === 'line' }"
      @click="onLineHandler"
      type="button"
    >
      Line
    </button>
    <button
      v-if="activeTools.includes('angle')"
      :class="{ active: activeTool === 'angle' }"
      @click="onAngleHandler"
      type="button"
    >
      Angle
    </button>
    <button
      v-if="activeTools.includes('panOrOrbit')"
      @click="
        () => {
          panOrOrbit = onPanOrOrbit(props.viewer, panOrOrbit);
        }
      "
      class="active"
      type="button"
    >
      <i
        class="fa fa-globe"
        aria-hidden="true"
        v-if="!panOrOrbit"
        style="font-size: 24px"
      ></i>

      <i
        class="fa fa-hand-paper-o"
        v-if="panOrOrbit"
        aria-hidden="true"
        style="font-size: 24px"
      >
      </i>
    </button>
    <button
      v-if="activeTools.includes('move')"
      :class="{ active: activeTool === 'move' }"
      @click="onMoveHandler"
      type="button"
    >
      <i class="fa fa-arrows" aria-hidden="true" style="font-size: 24px"></i>
    </button>
    <button
      :class="{ active: activeTool === 'rotate' }"
      @click="onRotationHandler"
      type="button"
    >
      <i class="fa fa-repeat" aria-hidden="true" style="font-size: 24px"></i>
    </button>

    <button
      v-if="activeTools.includes('palleteMenu')"
      :class="{ 'favorit-icon': true, active: palletStore.isMenuOpen }"
      @click="toggleMenu"
    >
      <i
        class="fa fa-tachometer"
        aria-hidden="true"
        style="font-size: 24px; margin-bottom: 1px"
      ></i>
    </button>

    <button
      v-if="activeTools.includes('showHideMenu')"
      :class="{ 'favorit-icon': true, active: props.isShowHideMenuOpen }"
      @click="emit('toggleShowHideMenu')"
    >
      <i
        class="fa fa-crosshairs"
        aria-hidden="true"
        style="font-size: 24px"
      ></i>
    </button>

    <button
      v-if="activeTools.includes('searchMenu')"
      :class="{ 'favorit-icon': true, active: searchStore.isMenuOpen }"
      @click="searchStore.isOpen(!searchStore.isMenuOpen)"
    >
      <i class="fa fa-search" aria-hidden="true" style="font-size: 24px"></i>
    </button>

    <button
      v-if="activeTools.includes('scaleMenu')"
      :class="{ 'favorit-icon': true, active: props.isScaleOpen }"
      @click="emit('toggleScaleMenu')"
    >
      <i
        class="fa fa-search-plus"
        aria-hidden="true"
        style="font-size: 24px"
      ></i>
    </button>
    <button
      v-if="activeTools.includes('save')"
      :disabled="saveStatus != 'available'"
      :class="{ 'favorit-icon': true, loading: saveStatus == 'loading' }"
      @click="emit('placeRFAToRVT')"
    >
      <i class="fa fa-floppy-o" aria-hidden="true" style="font-size: 24px"></i>
    </button>
    <button
      v-if="activeTools.includes('fullScreen')"
      :class="{ 'favorit-icon': true, active: isFullScreen }"
      @click="handleFullScreen"
    >
      <i class="fa fa-expand" aria-hidden="true" style="font-size: 24px"></i>
    </button>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch } from "vue";
import {
  onPanOrOrbit,
  onMove,
  onRotation,
  onAngle,
  onLine,
  deactivateAll,
} from "@/components/database/utils";
import { usePallet } from "@/pinia/pallet";
import { useSearchMenu } from "@/pinia/searchStore";
import { useMoveStore } from "@/pinia/moveStore";

const palletStore = usePallet();
const searchStore = useSearchMenu();
const moveStore = useMoveStore();
const props = defineProps({
  isGroup: Boolean,
  isShowHideMenuOpen: Boolean,
  isSearchOpen: Boolean,
  activeTools: Array,
  viewer: Object,
  saveStatus: Boolean,
  isScaleOpen: Boolean,
});
const emit = defineEmits([
  "toggleShowHideMenu",
  "toggleSearchMenu",
  "placeRFAToRVT",
]);

const panOrOrbit = ref(true);
const isFullScreen = ref(false);

const handleFullScreen = () => {
  const viewerFullScreenWrapper = document.getElementById("fullScreen");
  if (viewerFullScreenWrapper && !isFullScreen.value) {
    viewerFullScreenWrapper.requestFullscreen();
  } else {
    document.exitFullscreen();
  }
};

const fullScreenListener = () => {
  isFullScreen.value = !!document.fullscreenElement;
  const viewerContainer = document.getElementById("forgeViewer");
  if (document.fullscreenElement) {
    viewerContainer.style.height = "calc(100vh - 50px)";
  } else {
    viewerContainer.style.height = "";
  }
  props.viewer?.resize();
};

// full screen event listeners
onMounted(() => {
  if (props.activeTools.includes("fullScreen")) {
    document.addEventListener("fullscreenchange", fullScreenListener);
    document.addEventListener("webkitfullscreenchange", fullScreenListener);
    document.addEventListener("mozfullscreenchange", fullScreenListener);
    document.addEventListener("MSFullscreenChange", fullScreenListener);
  }
});

// remove full screen event listeners
onUnmounted(() => {
  if (props.activeTools.includes("fullScreen")) {
    document.removeEventListener("fullscreenchange", fullScreenListener);
    document.removeEventListener("webkitfullscreenchange", fullScreenListener);
    document.removeEventListener("mozfullscreenchange", fullScreenListener);
    document.removeEventListener("MSFullscreenChange", fullScreenListener);
  }
});

const onLineHandler = () => {
  if (activeTool.value == "line") {
    activeTool.value = "";
    deactivateAll(props.viewer);
  } else {
    onLineClicked();
    panOrOrbit.value = true;
    onLine(props.viewer);
    activeTool.value = "line";
  }
};

const onAngleHandler = () => {
  if (activeTool.value == "angle") {
    activeTool.value = "";
    deactivateAll(props.viewer);
  } else {
    panOrOrbit.value = true;
    onAngle(props.viewer);
    activeTool.value = "angle";
  }
};
const activeTool = ref("");
function selectMultipleModels(viewer, modelIds) {
  const selectionArray = [];
  modelIds.forEach((modelId) => {
    const model = viewer.getAllModels().find((m) => m.id == modelId);
    if (model) {
      const instanceTree = model.getData().instanceTree;
      if (instanceTree) {
        const rootId = instanceTree.getRootId();
        instanceTree.enumNodeChildren(rootId, (childId) => {
          selectionArray.push(childId);
          // Add each child ID to the selection array
        });
      }
    } else {
      console.error(`Model with ID ${modelId} not found.`);
    }
  });
  viewer.select(selectionArray);
}

const toggleMenu = () => {
  palletStore.isOpen(!palletStore.isMenuOpen);
  if (palletStore.isMenuOpen) {
    palletStore.setActiveElement(null);
  }
};

const onLineClicked = () => {
  selectMultipleModels(props.viewer, [1, 2]);
  activeTool.value = "line";
};

const onMoveHandler = () => {
  panOrOrbit.value = true;
  if (activeTool.value == "move") {
    activeTool.value = "";
    props.viewer.toolController?.deactivateTool("Viewing.Transform.Tool");
    props.viewer.toolController?.deactivateTool("Viewing.Transform.PartsTool");
  } else {
    onMove(props.viewer, moveStore.moveMode);
    activeTool.value = "move";
  }
};

const onRotationHandler = () => {
  panOrOrbit.value = true;
  if (activeTool.value == "rotate") {
    activeTool.value = "";
    props.viewer.toolController?.deactivateTool("Viewing.Rotate.Tool");
  } else {
    onRotation(props.viewer, props.isGroup);
    activeTool.value = "rotate";
  }
};

// open move settings menu when move tool is selected
watch(activeTool, (newValue) => {
  if (newValue != "move" && moveStore.isMenuOpen) {
    moveStore.setIsMenuOpen(false);
  } else {
    moveStore.setIsMenuOpen(true);
  }
});
</script>

<style scoped lang="scss">
.viewer-control {
  background-color: rgba(34, 34, 34, 0.94);
  color: #f4f4f4;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  height: 50px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  & button {
    border: 1px solid rgba(34, 34, 34, 0);
    border-radius: 4px;
    background-color: transparent;
    cursor: pointer;
    float: left;
    height: 42px;
    margin: 4px;
    padding: 0px;
    position: relative;
    width: 42px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border: 1px solid #00bfff;
      color: #00bfff;
    }

    &.active {
      border-radius: 3px;
      box-shadow: inset 0 2px 2px 0 #000;
      color: #00bfff;
      outline: none;
    }
  }
}

.favorit-icon {
  border: 1px solid rgba(34, 34, 34, 0);
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  float: left;
  height: 42px;
  margin: 4px;
  padding: 0px;
  position: relative;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover:not(:disabled) {
    border: 1px solid #00bfff;
    color: #00bfff;
  }

  &.active {
    border-radius: 3px;
    box-shadow: inset 0 2px 2px 0 #000;
    color: #00bfff;
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    color: #6b7280;
  }
  &:disabled:hover {
    cursor: not-allowed;
    color: #6b7280;
    background-color: transparent;
    border: none;
  }
  &.loading {
    animation: loading 3s infinite;
  }
}

@keyframes loading {
  0% {
    color: #6b7280;
  }
  50% {
    color: #00bfff;
  }
  100% {
    color: #6b7280;
  }
}
</style>
