<template>
  <div
    class="modal fade"
    id="updateDBModal"
    tabindex="-1"
    aria-labelledby="updateDBModal"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="filterModal">
            Update {{ dbStore.selectedTarget }}
          </h1>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <UpdatePart
            :categoriesData="props.categoriesData"
            :ownerTypeData="props.ownerTypeData"
            :costCodeData="props.costCodeData"
            @onSubmit="
              (data) => {
                console.log('modal');

                emit('onSubmit', data);
              }
            "
            v-if="
              dbStore.selectedTarget == 'part' && props.categoriesData.length
            "
          />
          <UpdateElement
            :categoriesData="props.categoriesData"
            :ownerTypeData="props.ownerTypeData"
            @onSubmit="
              (data) => {
                emit('onSubmit', data);
              }
            "
            v-if="
              dbStore.selectedTarget == 'element' && props.categoriesData.length
            "
          />
          <UpdateBundle
            :categoriesData="props.categoriesData"
            :ownerTypeData="props.ownerTypeData"
            :costCodeData="props.costCodeData"
            @onSubmit="
              (data) => {
                emit('onSubmit', data);
              }
            "
            v-if="
              dbStore.selectedTarget == 'bundle' && props.categoriesData.length
            "
          />
          <UpdateGroup
            :categoriesData="props.categoriesData"
            :ownerTypeData="props.ownerTypeData"
            :costCodeData="props.costCodeData"
            @onSubmit="
              (data) => {
                emit('onSubmit', data);
              }
            "
            v-if="
              dbStore.selectedTarget == 'group' && props.categoriesData.length
            "
          />
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Close
          </button>
          <button
            class="btn btn-primary"
            :form="`create-${dbStore.selectedTarget}-form`"
            @click="updateHandler"
          >
            Update {{ dbStore.selectedTarget }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import UpdatePart from "@/components/database/update/part.vue";
import UpdateElement from "@/components/database/update/element.vue";
import UpdateBundle from "@/components/database/update/bundle.vue";
import UpdateGroup from "@/components/database/update/group.vue";
import { useUpdateDatabaseComponentsStore } from "@/pinia/updateDatabase.js";
const dbStore = useUpdateDatabaseComponentsStore();
const props = defineProps(["categoriesData", "ownerTypeData", "costCodeData"]);
const emit = defineEmits(["onSubmit"]);
const updateHandler = () => {
  // logic here
  console.log(dbStore);
};
</script>
